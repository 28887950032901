import * as R from 'ramda'
import {
  SET_CART_DETAILS,
  SET_QUANTITY_INPUT,
  REMOVE_QUANTITY_INPUT,
  SET_CART_LOADING,
  EDIT_CART_DETAIL,
  REMOVE_CART_DETAIL,
  REMOVE_SAVE_DETAIL,
  SET_ORDER_SUMMARY,
  TOGGLE_CART_POPOVER,
  ADD_TO_CART,
  MERGE_DUPLICATES,
  EDIT_QUANTITY_INPUT,
  SET_SAVE_FOR_LATER,
  CLEAR_CART_DETAILS,
  RESET_TAX,
  RESET_CONVENIENCE_FEE,
  CLEAR_CART_NOTIFICATIONS,
} from '../actions/types'

const INITIAL_STATE = {
  cartDetail: [],
  isPopoverOpen: false,
  cartLoading: false,
  totalItems: 0,
  status: null,
  subtotal: 0,
  shipping: 0,
  tax: undefined,
  convenienceFee: 0,
  total: 0,
  _approvedBy: null,
  _contactId: null,
  quantityInput: [],
  saveForLaterDetail: [],
  notifications: [],
}

export default (state = INITIAL_STATE, { type, payload }) => {
  switch (type) {
    case ADD_TO_CART:
      return { ...state, cartDetail: payload }
    case SET_CART_DETAILS: {
      const { notifications } = payload
      const typedNotifications = R.type(notifications) === 'Array' ? notifications : []
      return { ...state, ...payload, notifications: typedNotifications }
    }
    case SET_SAVE_FOR_LATER:
      return { ...state, saveForLaterDetail: payload }
    case CLEAR_CART_DETAILS:
      return { ...INITIAL_STATE }
    case CLEAR_CART_NOTIFICATIONS:
      return { ...state, notifications: INITIAL_STATE.notifications }
    case SET_QUANTITY_INPUT: {
      return {
        ...state,
        quantityInput: state.cartDetail.map(item => {
          return { id: item.id, quantity: item.quantity }
        }),
      }
    }
    case EDIT_QUANTITY_INPUT:
      return {
        ...state,
        quantityInput: state.quantityInput.map(item =>
          item.id === payload.id ? { ...item, ...payload } : item,
        ),
      }
    case EDIT_CART_DETAIL:
      return {
        ...state,
        cartDetail: state.cartDetail.map(item =>
          item.id === payload.id ? { ...item, ...payload } : item,
        ),
      }
    case MERGE_DUPLICATES: {
      if (payload.unit) {
        const duplicates = R.sortWith([R.ascend(R.prop('id'))])(
          state.cartDetail.filter(
            item => item.unit === payload.unit && item.itemId === payload.itemId,
          ),
        )
        const totalQty = duplicates.reduce((pre, cur) => pre + cur.quantity, 0)
        const newDetail = R.head(duplicates)
        const tailDetail = R.tail(duplicates)
        const mergedQuantities = state.cartDetail.map(item =>
          item.id === newDetail.id ? { ...item, quantity: totalQty } : item,
        )
        const mergedCart = R.differenceWith((x, y) => x.id === y.id, mergedQuantities, tailDetail)
        return { ...state, cartDetail: mergedCart }
      }
      return { ...state }
    }
    case REMOVE_CART_DETAIL:
      return {
        ...state,
        cartDetail: state.cartDetail.filter(item => item.id !== payload),
      }
    case REMOVE_SAVE_DETAIL:
      return {
        ...state,
        saveForLaterDetail: state.saveForLaterDetail.filter(item => item.id != payload),
      }
    case REMOVE_QUANTITY_INPUT:
      return {
        ...state,
        quantityInput: state.quantityInput.filter(item => item.id != payload),
      }
    case SET_ORDER_SUMMARY:
      return { ...state, ...payload }
    ////Possibly the next option is better than the above because it might more directly set whats needed rather than replacing the entire payload
    // case SET_ORDER_SUMMARY: {
    //     ////Default all the payload to the current state if not passed in
    //     const { shipping = state.shipping, status = state.status, subtotal = state.subtotal, tax = state.tax, total = state.total,
    //         _approvedBy = state._approvedBy, _contactId = state._contactId } = payload
    //     return { ...state, shipping, status, subtotal, tax, total, _approvedBy, _contactId }
    // }
    case SET_CART_LOADING:
      return { ...state, cartLoading: payload }
    case TOGGLE_CART_POPOVER:
      return { ...state, isPopoverOpen: !state.isPopoverOpen }
    case RESET_TAX:
      return { ...state, tax: INITIAL_STATE.tax }
    case RESET_CONVENIENCE_FEE:
      return { ...state, convenienceFee: INITIAL_STATE.convenienceFee }
    default:
      return state
  }
}
