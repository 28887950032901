import * as R from 'ramda'
import { resolveReq, axios } from '../utils/api'
import {
  SET_SHOPPING_FILTERS,
  SET_SELECTED_SHOPPING_FILTER,
  SET_SEARCH_TERM,
  SET_CATEGORIES,
  SET_ITEM_LIST,
  SET_SEARCH,
  SELECT_CATEGORY,
  SELECT_SUBCATEGORY,
  RESET_SELECTED_CATEGORY,
  START_LOADER,
  STOP_LOADER,
  SET_PRODUCT,
  RESET_PRODUCT,
  ADD_TO_CART,
  CHANGE_QUANTITY,
  SET_UOM,
  SET_ORDER_SUMMARY,
  SET_QUANTITY_INPUT,
  SET_SELLABLE_CSLS,
  SET_ALTERNATIVE_ITEMS,
  SET_CORRELATED_ITEMS,
  SET_ALLOCATION_MAX, ////This is grouped with the myAccount type
  // AE
  RESET_PRODUCT_MANUFACTURERS,
  ADD_PRODUCT_MANUFACTURER,
  REMOVE_PRODUCT_MANUFACTURER,
  SET_PRODUCT_RX_LEVEL,
  SET_PRODUCT_AVAILABILITY,
  RESET_PRODUCT_AVAILABILITY,
  RESET_PRODUCT_RX_LEVEL,
  SET_PRODUCT_SORT_BY,
  SET_PRODUCT_SORT_BY_FILTER,
  RESET_PRODUCT_SORT_BY_FILTER,

  // End AE
} from './types'

const aa = require('search-insights')

export const setSearchTerm = value => {
  return { type: SET_SEARCH_TERM, payload: value }
}

export const getItemList = ({ search }) => {
  return async dispatch => {
    dispatch({ type: START_LOADER, payload: 'itemListLoading' })
    const { data, error } = await resolveReq(
      axios.get(`/api/shopping/item/getItemList?search=${search}`),
    )
    if (error)
      // eslint-disable-next-line no-console
      return console.error(error)
    dispatch({ type: SET_ITEM_LIST, payload: data })
    dispatch({ type: STOP_LOADER, payload: 'itemListLoading' })
  }
}

export const getShoppingFilters = queryObj => {
  return async dispatch => {
    const { data, error } = await resolveReq(axios.post('/api/shopping/item/filters', queryObj))
    if (error)
      // eslint-disable-next-line no-console
      return console.error(error)
    dispatch({ type: SET_SHOPPING_FILTERS, payload: data })
  }
}

export const setShoppingFilter = payload => {
  return { type: SET_SELECTED_SHOPPING_FILTER, payload }
}

// AE
export const resetProductManufacturers = () => {
  return { type: RESET_PRODUCT_MANUFACTURERS }
}

export const addProductManufacturer = manufacturer => {
  return {
    type: ADD_PRODUCT_MANUFACTURER,
    payload: { manufacturer: manufacturer },
  }
}

export const removeProductManufacturer = manufacturer => {
  return {
    type: REMOVE_PRODUCT_MANUFACTURER,
    payload: { manufacturer: manufacturer },
  }
}

export const setProductRxLevel = payload => {
  return { type: SET_PRODUCT_RX_LEVEL, payload: payload }
}

export const resetProductRxLevel = rxLevel => {
  return {
    type: RESET_PRODUCT_RX_LEVEL,
    payload: { rxLevel: rxLevel },
  }
}

export const setProductAvailability = payload => {
  return {
    type: SET_PRODUCT_AVAILABILITY,
    payload: payload,
  }
}

export const resetProductAvailability = availability => {
  return {
    type: RESET_PRODUCT_AVAILABILITY,
    payload: { availability: availability },
  }
}

export const setProductSortBy = sortBy => {
  return {
    type: SET_PRODUCT_SORT_BY,
    payload: { sortBy: sortBy },
  }
}

export const setProductSortByFilter = payload => {
  return {
    type: SET_PRODUCT_SORT_BY_FILTER,
    payload: payload,
  }
}

export const resetProductSortByFilter = sortByFilter => {
  return {
    type: RESET_PRODUCT_SORT_BY_FILTER,
    payload: { sortByFilter: sortByFilter },
  }
}
// End AE

export const setCategories = () => {
  return async dispatch => {
    const response = await resolveReq(axios.get('/api/shopping/category/getCategories'))
    dispatch({ type: SET_CATEGORIES, payload: response.data })
  }
}

export const setCategoriesBySubCategoryUrl = urlComponent => {
  return async dispatch => {
    const { data, error } = await resolveReq(
      axios.get(`/api/shopping/category/getCategoryBySubCategoryUrl?urlComponent=${urlComponent}`),
    )
    if (error)
      // eslint-disable-next-line no-console
      return console.log(error)
    dispatch({
      type: SELECT_CATEGORY,
      payload: { category: data, subCategories: data.children },
    })

    const isUrlId = !isNaN(Number(urlComponent))
    const findParams = isUrlId
      ? R.propEq(Number(urlComponent), 'id')
      : R.propEq(urlComponent, 'urlComponent')
    const selectedSubcategory = R.find(findParams)(R.pathOr([], ['children'], data))
    dispatch({ type: SELECT_SUBCATEGORY, payload: selectedSubcategory })
    return selectedSubcategory
  }
}

export const setCategoriesByParentCategoryUrl = urlComponent => {
  return async dispatch => {
    const { data, error } = await resolveReq(
      axios.get(
        `/api/shopping/category/getCategoryByParentCategoryUrl?parentCategoryUrl=${urlComponent}`,
      ),
    )
    if (error)
      // eslint-disable-next-line no-console
      return console.log(error)
    dispatch({
      type: SELECT_CATEGORY,
      payload: { category: data, subCategories: data.children },
    })
    const isUrlId = !isNaN(Number(urlComponent))
    const findParams = isUrlId
      ? R.propEq(Number(urlComponent), 'id')
      : R.propEq(urlComponent, 'urlComponent')
    ////Leaving this in although I don't see how this will ever be defined
    const selectedSubcategory = R.find(findParams)(R.pathOr([], ['children'], data))
    dispatch({ type: SELECT_SUBCATEGORY, payload: selectedSubcategory })
  }
}

export const selectCategory = category => {
  return {
    type: SELECT_CATEGORY,
    payload: { category: category, subCategories: category.children },
  }
}

export const selectSubCategory = subcat => {
  return { type: SELECT_SUBCATEGORY, payload: subcat }
}
export const resetSelectedCategory = () => {
  return { type: RESET_SELECTED_CATEGORY }
}

export const setSearch = payload => {
  return { type: SET_SEARCH, payload }
}

export const setProductWithLogin = (id, startLoader, isLoggedIn, clickEventObject) => {
  return async dispatch => {
    ////Always reset allocation back to null before loading new product
    dispatch({ type: START_LOADER, payload: 'retrievingAllocation' })
    dispatch({ type: SET_ALLOCATION_MAX, payload: null })
    if (startLoader) dispatch({ type: START_LOADER, payload: 'ekgLoading' })

    const { data, error } = await resolveReq(axios.get(`/api/shopping/item/item?id=${id}`))

    if (error)
      // eslint-disable-next-line no-console
      return console.error(error)
    dispatch({ type: SET_PRODUCT, payload: data })
    dispatch({ type: STOP_LOADER, payload: 'ekgLoading' })

    const { children = [] } = data
    const product = R.isEmpty(children) ? data : R.find(R.propEq(Number(id), 'id'), children)

    ////Need to get the id here and not use the id passed in the function because that id is really the url component
    const itemId = R.pathOr('', ['id'], product)
    const allocationPeriod = R.pathOr(false, ['allocationPeriod'], product)

    if (allocationPeriod && itemId && isLoggedIn) {
      const { data: d, error: err } = await resolveReq(
        axios.post('/api/myaccount/allocation/getItemAllocation', { itemId }),
      )
      if (err) {
        // eslint-disable-next-line no-console
        console.error(err)
        return dispatch({ type: STOP_LOADER, payload: 'retrievingAllocation' })
      }
      dispatch({ type: SET_ALLOCATION_MAX, payload: d.allocationMax })
    }
    dispatch({ type: STOP_LOADER, payload: 'retrievingAllocation' })

    const passesValidation =
      !R.isEmpty(clickEventObject) &&
      R.isEmpty(R.filter(n => !n || R.isNil(n) || R.isEmpty(n), clickEventObject))
    if (passesValidation) {
      try {
        aa('clickedObjectIDsAfterSearch', clickEventObject)
        console.log('clickevent', clickEventObject)
      } catch (e) {
        console.log(e)
      }
    }
  }
}

// export const setProduct = (id, startLoader = false, isLoggedIn = false) => setProductWithLogin(id, startLoader, isLoggedIn)

export const setAlternativeItems = (id, startLoader) => {
  return async dispatch => {
    // if (startLoader)
    //     dispatch({ type: START_LOADER, payload: 'ekgLoading' })
    const { data, error } = await resolveReq(
      axios.get(`/api/shopping/item/alternativeItem?id=${id}`),
    )
    if (error)
      // eslint-disable-next-line no-console
      return console.error(error)
    dispatch({ type: SET_ALTERNATIVE_ITEMS, payload: data })
    // dispatch({ type: STOP_LOADER, payload: 'ekgLoading' })
  }
}

export const setCorrelatedItems = id => {
  return async dispatch => {
    // if (startLoader)
    //     dispatch({ type: START_LOADER, payload: 'ekgLoading' })
    const { data, error } = await resolveReq(
      axios.get(`/api/shopping/item/correlatedItems?id=${id}`),
    )
    if (error) return console.error(error)

    dispatch({ type: SET_CORRELATED_ITEMS, payload: data })
    return data.map(item => item.correlatedItem)
    // dispatch({ type: STOP_LOADER, payload: 'ekgLoading' })
  }
}
export const resetProduct = () => {
  return { type: RESET_PRODUCT }
}

export const addToCart = (product, quantity, uom, searchTerm, queryID) => {
  let postedObj = R.pickBy(val => val, {
    itemId: product.id,
    unit: uom,
    quantity: quantity,
    searchTerm: searchTerm,
    queryID: queryID,
  })

  return async dispatch => {
    dispatch({ type: START_LOADER, payload: 'addToCartIsLoading' })

    const { data, error } = await resolveReq(axios.post('/api/cart/cart/addItem', postedObj))

    if (error) {
      // eslint-disable-next-line no-console
      dispatch({ type: START_LOADER, payload: 'addToCartIsLoading' })

      return console.error(error)
    }

    ////This indicates whether or not the add to cart amount had to be adjusted or cancelled because of allocation
    ////false means there was no adjustment and the full amount was added to cart
    const { addToCartAdjustment = false } = data

    dispatch({
      type: SET_ORDER_SUMMARY,
      payload: {
        subtotal: data.subtotal,
        shipping: data.shipping,
        tax: data.tax,
        total: data.total,
      },
    })
    dispatch({ type: ADD_TO_CART, payload: data.cartDetail })
    dispatch({ type: SET_QUANTITY_INPUT })
    dispatch({ type: STOP_LOADER, payload: 'addToCartIsLoading' })
    return { addToCartAdjustment }
  }
  //return { type: ADD_TO_CART, payload: item }
}

export const changeQuantity = quantity => {
  return { type: CHANGE_QUANTITY, payload: quantity }
}

export const setUom = uom => {
  return { type: SET_UOM, payload: uom }
}

export const getSellableControlledSubstances = () => {
  return async dispatch => {
    const { data } = await resolveReq(
      axios.get('/api/shopping/item/getSellableControlledSubstances'),
    )

    dispatch({ type: SET_SELLABLE_CSLS, payload: data })
  }
}

export const getTopItems = async () => {
  const response = await axios.get('api/shopping/item/topItems')
  return response.data
}
