import React from 'react'
import { Link } from 'react-router-dom'

const MainNavigationLink = ({ path, text, onClick }) => {
  const handleClick = () => {
    onClick()
  }

  return (
    <Link to={path} className="dealmed-main-navigation__link" onClick={handleClick}>
      {text}
    </Link>
  )
}

export default MainNavigationLink
