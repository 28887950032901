import React, { useEffect, useRef } from 'react'
import { Link } from 'react-router-dom'
import { pathOr, isEmpty, isNil, either } from 'ramda'
import CartHOC from '../Reusable/CartHOC'
import DealmedPopover from '../../shared/components/DealmedPopover'
import ShoppingCartPopoverProduct from './ShoppingCartPopoverProduct'
import { TabletAndSmaller, Desktop } from '../../shared/responsive-breakpoints'
import Spinner from '../../shared/components/Spinner'
import ShoppingBagSVG from '../../shared/icons/ShoppingBagSVG'
import mixpanel from 'mixpanel-browser'
import { connect } from 'react-redux'
import { mixpanelLogEvent } from '../../utils/mixpanelLogEvent'

const ShoppingCartPopover = props => {
  const {
    isOpen,
    openPopover,
    closePopover,
    className,
    cartLoading,
    totalItems,
    cartDetail,
    isPriceVisible,
    orderSummaryDisplay,
    isPunchout,
  } = props

  const triggerRef = useRef(null)
  const popoverWrapperRef = useRef(null)
  const popoverMatchWidthRef = useRef(null)

  const handleClick = () => {
    if (isOpen) {
      closePopover()
    } else {
      openPopover()
    }
  }

  const logClickEvent = ({ eventType }) => {
    mixpanelLogEvent({
      eventType,
      userDetails: props?.auth?.user,
      metaData: {
        cartDetails: props.cartDetail || [],
        currentPage: props.location?.pathname,
      },
    })
  }

  const isNullOrEmpty = either(isNil, isEmpty)

  const mobileShoppingCartPopover = (
    <Link to="/cart" className="dealmed-header__cart-link">
      <span className="visually-hidden">Shopping Cart</span>
      <span className="dealmed-header__icon-wrapper">
        <ShoppingBagSVG className="dealmed-header__cart-icon" />
        {totalItems !== 0 ? <span className="dealmed-header__cart-items">{totalItems}</span> : null}
      </span>
    </Link>
  )

  const desktopShoppingCartPopover = (
    <>
      <button
        type="button"
        ref={triggerRef}
        className="dealmed-header__cart-toggle-btn"
        onClick={handleClick}
      >
        <span className="visually-hidden">Shopping Cart</span>
        <span className="dealmed-header__icon-wrapper">
          <ShoppingBagSVG className="dealmed-header__cart-icon" />
          {totalItems !== 0 ? (
            <span className="dealmed-header__cart-items">{totalItems}</span>
          ) : null}
        </span>
      </button>

      {isOpen && popoverMatchWidthRef.current && (
        <DealmedPopover
          innerRef={popoverWrapperRef}
          targetRef={triggerRef}
          closePopover={closePopover}
          position={popoverMatchWidthRef.current}
          isOpen={isOpen}
          className={className}
          popoverType="shoppingCart"
        >
          {cartLoading || isNullOrEmpty(cartDetail) ? (
            cartLoading ? (
              <Spinner />
            ) : (
              <div className="dealmed-header__cart-header">
                <p className="dealmed-header__cart-empty">Your cart is currently empty.</p>

                <input
                  type="button"
                  value="X"
                  className="dealmed-header__cart-header-btn-close"
                  onClick={() => closePopover()}
                />
              </div>
            )
          ) : (
            <>
              <div className="dealmed-header__cart-header">
                <p>Cart Preview</p>
                <input
                  type="button"
                  value="X"
                  className="dealmed-header__cart-header-btn-close"
                  onClick={() => closePopover()}
                />
              </div>
              <ul className="dealmed-header__cart-list">
                {cartDetail.map((product, index) => {
                  return (
                    <li key={index} className="dealmed-header__cart-item">
                      <ShoppingCartPopoverProduct
                        product={product}
                        isPriceVisible={isPriceVisible}
                      />
                    </li>
                  )
                })}
              </ul>
              <div className="dealmed-header__cart-container">
                <div className="dealmed-header__cart-totals">
                  <span className="dealmed-header__cart-product-count">{`${totalItems} Products`}</span>
                  <span className="dealmed-header__cart-subtotal">
                    Subtotal:{' '}
                    <span className="dealmed-header__cart-subtotal-price">
                      {pathOr('', ['subtotal'], orderSummaryDisplay())}
                    </span>
                  </span>
                </div>
                <div className="dealmed-header__cart-links">
                  <Link
                    to="/cart"
                    className="dealmed-header__cart-view"
                    onClick={() => {
                      logClickEvent({ eventType: 'ShoppingCartPopover_View_Cart_button_click' })
                      closePopover()
                    }}
                  >
                    View Cart
                  </Link>
                  {isPunchout || (
                    <Link
                      to="/checkout"
                      className="dealmed-header__cart-checkout"
                      onClick={() => {
                        logClickEvent({ eventType: 'ShoppingCartPopover_Checkout_button_click' })
                        closePopover()
                      }}
                    >
                      Checkout
                    </Link>
                  )}
                </div>
              </div>
            </>
          )}
        </DealmedPopover>
      )}
    </>
  )

  useEffect(() => {
    import('@reach/popover').then(module => {
      popoverMatchWidthRef.current = module.positionMatchWidth
    })
  }, [])

  return (
    <React.Suspense fallback={<Spinner />}>
      <TabletAndSmaller>{mobileShoppingCartPopover}</TabletAndSmaller>
      <Desktop>{desktopShoppingCartPopover}</Desktop>
    </React.Suspense>
  )
}

const mapStateToProps = state => {
  return {
    auth: state.auth,
    categories: state.shopping.categories,
  }
}

export default connect(mapStateToProps)(CartHOC(ShoppingCartPopover))
