import React from 'react'
import classnames from 'classnames'

const NotificationBellSVG = ({ className }) => {
  return (
    <svg
      viewBox="0 0 32 32"
      className={classnames('dealmed-icon', className)}
      aria-hidden={true}
      focusable={false}
    >
      <g
        id="surface1"
        strokeLinecap="round"
        strokeLinejoin="round"
        stroke="#384349"
        strokeWidth="0.5"
        transform="translate(5, 5)"
      >
        <path d="M 20.492188 17.984375 C 18.15625 16.925781 18.066406 12.394531 18.066406 12.28125 L 18.066406 9.242188 C 18.066406 6.3125 16.277344 3.796875 13.738281 2.726562 C 13.730469 1.222656 12.503906 0 11 0 C 9.496094 0 8.269531 1.222656 8.265625 2.726562 C 5.722656 3.796875 3.933594 6.3125 3.933594 9.242188 L 3.933594 12.28125 C 3.933594 12.394531 3.84375 16.925781 1.507812 17.984375 C 1.238281 18.101562 1.09375 18.394531 1.15625 18.679688 C 1.214844 18.964844 1.46875 19.167969 1.761719 19.167969 L 7.6875 19.167969 C 7.808594 19.835938 8.125 20.457031 8.609375 20.960938 C 9.25 21.632812 10.101562 22 11 22 C 11.898438 22 12.75 21.632812 13.390625 20.960938 C 13.875 20.457031 14.191406 19.835938 14.3125 19.167969 L 20.238281 19.167969 C 20.53125 19.167969 20.785156 18.964844 20.84375 18.679688 C 20.90625 18.394531 20.761719 18.101562 20.492188 17.984375 Z M 17.414062 15.8125 C 17.660156 16.558594 18.007812 17.304688 18.5 17.929688 L 3.5 17.929688 C 3.992188 17.304688 4.339844 16.558594 4.585938 15.8125 Z M 11 1.242188 C 11.679688 1.242188 12.257812 1.699219 12.4375 2.320312 C 11.972656 2.226562 11.492188 2.175781 11 2.175781 C 10.507812 2.175781 10.027344 2.226562 9.5625 2.320312 C 9.742188 1.699219 10.320312 1.242188 11 1.242188 Z M 5.175781 12.285156 L 5.175781 9.242188 C 5.175781 6.027344 7.789062 3.414062 11 3.414062 C 14.210938 3.414062 16.824219 6.027344 16.824219 9.242188 L 16.824219 12.289062 C 16.824219 12.382812 16.839844 13.363281 17.085938 14.570312 L 4.914062 14.570312 C 5.160156 13.363281 5.175781 12.378906 5.175781 12.285156 Z M 11 20.757812 C 10.054688 20.757812 9.226562 20.078125 8.960938 19.167969 L 13.039062 19.167969 C 12.773438 20.078125 11.945312 20.757812 11 20.757812 Z M 11 20.757812 " />
      </g>
    </svg>
  )
}

export default NotificationBellSVG
