import React, { useContext } from 'react'
import { ProductDetailsContext } from './ProductDetailsContext'
import { pathOr } from 'ramda'
import AddToCart from './AddToCart'

const ProductDetailsAddToCart = ({
  allocationMax,
  product,
  units,
  setShowAfterAddPage,
  logClickEvent,
}) => {
  const productDetailsProps = useContext(ProductDetailsContext)

  const isRestricted = pathOr(false, ['data', 'restricted'], productDetailsProps.product)

  const productPermissions = productDetailsProps.validateRx(product)

  if (!productPermissions.isValidRxLevel) {
    return (
      <span className="product-details__add-to-cart-message">
        This item requires a medical license to purchase.
      </span>
    )
  } else if (isRestricted) {
    return (
      <span className="product-details__add-to-cart-message">
        This item is currently restricted. Please call an administrator at{' '}
        <a href="tel:+1-800-569-0570" className="product-details__add-to-cart-number">
          1-800-569-0570
        </a>
      </span>
    )
  } else {
    return (
      <AddToCart
        allocationMax={allocationMax}
        product={product}
        uom={units}
        quantity={productDetailsProps.quantitySelected}
        setShowAfterAddPage={setShowAfterAddPage}
        logClickEvent={logClickEvent}
        flyToCart
      />
    )
  }
}

export default ProductDetailsAddToCart
