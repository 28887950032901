/* eslint-disable import/no-anonymous-default-export */
import {
  SET_SHOPPING_FILTERS,
  SET_SELECTED_SHOPPING_FILTER,
  SET_SEARCH_TERM,
  SET_SEARCH,
  SET_ITEM_LIST,
  SET_CATEGORIES,
  SELECT_CATEGORY,
  SELECT_SUBCATEGORY,
  RESET_SELECTED_CATEGORY,
  SET_PRODUCT,
  RESET_PRODUCT,
  CHANGE_QUANTITY,
  SET_SELLABLE_CSLS,
  SET_UOM,
  SET_ALTERNATIVE_ITEMS,
  SET_CORRELATED_ITEMS,
  SET_ALLOCATION_MAX,
  // AE
  RESET_PRODUCT_MANUFACTURERS,
  ADD_PRODUCT_MANUFACTURER,
  REMOVE_PRODUCT_MANUFACTURER,
  SET_PRODUCT_RX_LEVEL,
  RESET_PRODUCT_RX_LEVEL,
  SET_PRODUCT_AVAILABILITY,
  RESET_PRODUCT_AVAILABILITY,
  SET_PRODUCT_SORT_BY,
  SET_PRODUCT_SORT_BY_FILTER,
  RESET_PRODUCT_SORT_BY_FILTER,

  // End AE
} from '../actions/types'

const initialProduct = {
  data: {
    id: '',
    itemName: '',
    children: [],
    description: '',
    displayName: '',
    siteDisplayName: '',
    manufacturer: '',
    mpn: '',
    pricelevelprice: '',
    quantityAvailable: '',
    rxLevel: null,
    specialOrder: false,
    thumbnailImage: '',
    itemImage: [],
    _parentId: null,
    unit: {},
    attributes: {},
    allocationPeriod: null,
    metaTag: '',
    titleTag: '',
    h1Tag: '',
  },
}

const INITIAL_STATE = {
  // AE
  manufacturers: [],
  rxLevel: [],
  availability: [],
  sortByFilter: [],
  sortBy: '',
  // End AE
  filters: {},
  search: '',
  searchTerm: '',
  product: initialProduct,
  categories: [],
  selectedCategory: {},
  subCategories: [],
  selectedSubCategory: {},
  sellableCSLs: {},
  itemList: [],
  quantitySelected: 1,
  uom: {},
  alternativeItems: [],
  correlatedItems: [],
  allocationMax: null,
}

export default (state = INITIAL_STATE, { type, payload }) => {
  switch (type) {
    case SET_SHOPPING_FILTERS:
      return { ...state, filters: payload }
    case SET_SELECTED_SHOPPING_FILTER: {
      const { header, type, checked, value } = payload
      return {
        ...state,
        filters: {
          ...state.filters,
          [type]: {
            ...state.filters[type],
            [header]: state.filters[type][header].map((filter, i) => {
              if (filter.value === value)
                return { value: filter.value, label: filter.label, checked }
              return filter
            }),
          },
        },
      }
    }
    // AE
    case RESET_PRODUCT_MANUFACTURERS:
      return {
        ...state,
        manufacturers: [],
      }

    case ADD_PRODUCT_MANUFACTURER:
      return {
        ...state,
        manufacturers: [...state.manufacturers, payload.manufacturer],
      }

    case REMOVE_PRODUCT_MANUFACTURER:
      return {
        ...state,
        manufacturers: state.manufacturers.filter(
          manufacturer => manufacturer !== payload.manufacturer,
        ),
      }

    case SET_PRODUCT_RX_LEVEL:
      if (typeof state.rxLevel === 'string' && state.rxLevel !== payload.value) {
        state.filters.field.rxLevel = state.filters.field.rxLevel?.map(rxl => {
          rxl.checked = false
          return rxl
        })
      }

      return {
        ...state,
        rxLevel: payload.value,
      }

    case RESET_PRODUCT_RX_LEVEL:
      state.filters.field.rxLevel = state.filters.field.rxLevel.map(rxl => {
        rxl.checked = false
        return rxl
      })
      return {
        ...state,
        rxLevel: payload.value,
      }

    case SET_PRODUCT_AVAILABILITY:
      if (typeof state.availability === 'string' && state.availability !== payload.value) {
        state.filters.field.availability = state.filters.field.availability?.map(avl => {
          avl.checked = false
          return avl
        })
      }
      return {
        ...state,
        availability: payload.value,
      }

    case RESET_PRODUCT_AVAILABILITY:
      state.filters.field.availability = state.filters.field.availability.map(avl => {
        avl.checked = false
        return avl
      })
      return {
        ...state,
        availability: payload.value,
      }

    case SET_PRODUCT_SORT_BY:
      return {
        ...state,
        sortBy: payload.sortBy,
      }

    case SET_PRODUCT_SORT_BY_FILTER:
      if (typeof state.sortByFilter === 'string' && state.sortByFilter !== payload.value) {
        state.filters.field.sortByFilter = state.filters.field.sortByFilter?.map(sortb => {
          sortb.checked = false
          return sortb
        })
      }
      return {
        ...state,
        sortByFilter: payload.value,
      }

    case RESET_PRODUCT_SORT_BY_FILTER:
      state.filters.field.sortByFilter = state.filters.field.sortByFilter.map(sortb => {
        sortb.checked = false
        return sortb
      })
      return {
        ...state,
        sortByFilter: payload.value,
      }

    // End AE
    case SET_ITEM_LIST:
      return { ...state, itemList: payload }
    case SET_SEARCH_TERM:
      return { ...state, searchTerm: payload }
    case SET_SEARCH:
      return { ...state, search: payload }
    case SET_PRODUCT:
      return { ...state, product: { data: payload } }
    case SET_ALTERNATIVE_ITEMS:
      return { ...state, alternativeItems: payload }
    case SET_CORRELATED_ITEMS:
      return { ...state, correlatedItems: payload }
    case SET_ALLOCATION_MAX:
      return { ...state, allocationMax: payload }
    case RESET_PRODUCT:
      return {
        ...state,
        product: initialProduct,
        alternativeItems: INITIAL_STATE.alternativeItems,
        allocationMax: INITIAL_STATE.allocationMax,
      }
    case CHANGE_QUANTITY:
      return { ...state, quantitySelected: payload }
    case SET_UOM:
      return { ...state, uom: payload }
    case SET_CATEGORIES:
      return { ...state, categories: payload }
    case SELECT_CATEGORY:
      state.filters.field = {}
      state.availability = ''
      state.rxLevel = ''
      state.sortByFilter = ''
      return {
        ...state,
        selectedCategory: payload.category,
        subCategories: payload.subCategories,
      }
    case SELECT_SUBCATEGORY:
      return {
        ...state,
        selectedSubCategory: payload,
        searchTerm: INITIAL_STATE.searchTerm,
      }
    case RESET_SELECTED_CATEGORY:
      return {
        ...state,
        selectedCategory: INITIAL_STATE.selectedCategory,
        selectedSubCategory: INITIAL_STATE.selectedSubCategory,
        subCategories: INITIAL_STATE.subCategories,
      }
    case SET_SELLABLE_CSLS:
      return { ...state, sellableCSLs: payload }

    default:
      return state
  }
}
