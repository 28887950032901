import React, { useState, useContext } from 'react'
import { ProductDetailsContext } from './ProductDetailsContext'
import DealmedDialog from '../../shared/components/DealmedDialog'
import CloseSVG from '../../shared/icons/CloseSVG'
import PhoneSVG from '../../shared/icons/PhoneSVG'

const ProductDetailsQuestionsDialog = React.memo(({ logClickEvent }) => {
  const [showDialog, setShowDialog] = useState(false)

  const productDetailsProps = useContext(ProductDetailsContext)

  const history = productDetailsProps.history

  const openDialog = () => {
    logClickEvent({ eventType: 'PDP_Open_Questions_Dialog' })
    setShowDialog(true)
  }

  const closeDialog = () => {
    setShowDialog(false)
  }

  return (
    <>
      <button
        className="product-details__action-open"
        onClick={openDialog}
        aria-label="Open Questions Dialog"
      >
        {/* <PhoneSVG className="product-details__action-icon product-details__action-icon--red" /> */}
        {/* <img src={`${process.env.PUBLIC_URL}/images/question.png`} alt="" loading="lazy"/> */}
        <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24">
          <title>help</title>
          <path d="M10,19H13V22H10V19M12,2C17.35,2.22 19.68,7.62 16.5,11.67C15.67,12.67 14.33,13.33 13.67,14.17C13,15 13,16 13,17H10C10,15.33 10,13.92 10.67,12.92C11.33,11.92 12.67,11.33 13.5,10.67C15.92,8.43 15.32,5.26 12,5A3,3 0 0,0 9,8H6A6,6 0 0,1 12,2Z" />
        </svg>
        {/* <div className="product-details__action-tooltip">
					<span className="product-details__action-tooltip-text">Ask Questions</span>
				</div> */}
      </button>
      <DealmedDialog
        isOpen={showDialog}
        onDismiss={closeDialog}
        className="product-details__action-dialog"
        label="Ask a Question Dialog"
      >
        <div className="product-details__action-content">
          <button
            className="product-details__action-close"
            onClick={closeDialog}
            aria-label="Close Questions Dialog"
          >
            <CloseSVG className="product-details__action-icon product-details__action-icon--close" />
          </button>
          <h2 className="product-details__action-header">Have Questions?</h2>
          <a href="tel:+1-800-569-0570" className="product-details__action-phone">
            <span className="product-details__action-icon-wrapper">
              <PhoneSVG className="product-details__action-icon" />
            </span>
            1-800-569-0570
          </a>
          <button
            className="product-details__action-contact"
            onClick={() => history.push('/contactus')}
          >
            Contact Us
          </button>
        </div>
      </DealmedDialog>
    </>
  )
})

export default ProductDetailsQuestionsDialog
