import React from 'react'
import { Listbox, ListboxOption } from '@reach/listbox'
import classnames from 'classnames'
import '@reach/listbox/styles.css'

const DealmedListbox = ({ options, className, label, value, onChange, ...rest }) => {
  return (
    <>
      {/* <p>{label}</p> */}
      <Listbox
        className={classnames('dealmed-listbox', className)}
        value={value}
        aria-label={label}
        onChange={onChange}
        {...rest}
      >
        {options.map((option, index) => {
          return (
            <ListboxOption
              key={index}
              value={option.value || 'N/A'}
              className={classnames('dealmed-listbox__option', className)}
              disabled={option.disabled}
            >
              <span className="dealmed-listbox__text">{option.text}</span>
            </ListboxOption>
          )
        })}
      </Listbox>
    </>
  )
}

export default DealmedListbox
