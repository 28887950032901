import React from 'react'
import { isEmpty } from 'ramda'
import ProductDetailsTabsNavigationItem from './ProductDetailsTabsNavigationItem'

const ProductDetailsTabsNavigation = React.memo(
  ({ downloads, relatedProducts, alternativeProducts }) => {
    return (
      <div className="product-details__tabs">
        <ul className="product-details__tabs-list">
          {!isEmpty(downloads) ? (
            <ProductDetailsTabsNavigationItem path="#product-downloads" text="Downloads" />
          ) : null}

          {relatedProducts.length > 0 ? (
            <ProductDetailsTabsNavigationItem path="#related-products" text="Related Products" />
          ) : null}

          {alternativeProducts.length > 0 ? (
            <ProductDetailsTabsNavigationItem
              path="#alternative-products"
              text="Alternative Products"
            />
          ) : null}
        </ul>
      </div>
    )
  },
)

export default ProductDetailsTabsNavigation
