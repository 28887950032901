import React from 'react'
import ProductDetailsAddToListDialog from './ProductDetailsAddToListDialog'
import ProductDetailsQuestionsDialog from './ProductDetailsQuestionsDialog'
import ProductDetailsShareDialog from './ProductDetailsShareDialog'

const ProductDetailsActions = ({ product, defaultUnit }) => {
  return (
    <ul className="product-details__actions">
      <li className="product-details__action">
        <ProductDetailsAddToListDialog product={product} defaultUnit={defaultUnit} />
      </li>
      <li className="product-details__action">
        <ProductDetailsQuestionsDialog />
      </li>
      <li className="product-details__action">
        <ProductDetailsShareDialog />
      </li>
    </ul>
  )
}

export default ProductDetailsActions
