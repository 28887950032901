import { START_LOADER, STOP_LOADER } from '../actions/types'

const INITIAL_STATE = {
  userLoading: false,
  accountLoading: false, ///This may be the same as userLoading but I didn't want to affect other places where is was being used
  itemListLoading: false,
  ekgLoading: false,
  fetchingItemListDetails: false,
  addToCartMultiLoading: false,
  addToCartIsLoading: false,
  creditCardUpdating: false,
  calculateTaxLoading: false,
  calculateConvenienceFeeLoading: false,
  submitIssueLoading: false,
  orderApprovalListLoading: false,
  retrievingInvoices: false,
  retrievingAllocation: false,
  retrievingRelatedAccounts: false,
  ////This is different than retrieving the products the first time , because this is a loader for the bottom of the screen and this is a loader for infinite scroll
  isLoadingMoreProducts: false,
  retrievingBusinessTypesandContactRoles: false,
}

export default (state = INITIAL_STATE, { type, payload }) => {
  switch (type) {
    case START_LOADER:
      return { ...state, [payload]: true }
    case STOP_LOADER:
      return { ...state, [payload]: false }
    default:
      return state
  }
}
