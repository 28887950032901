import { createClient } from 'contentful'

const defaultConfig = {
  CTF_SPACE_ID: process.env.REACT_APP_CONTENTFUL_APP_SPACE_ID,
  CTF_CDA_TOKEN: process.env.REACT_APP_CONTENTFUL_APP_ACCESS_TOKEN,
  CTF_CPA_TOKEN: process.env.REACT_APP_CONTENTFUL_APP_PREVIEW_ACCESS_TOKEN,
  CTF_ENVIRONMENT_ID: process.env.REACT_APP_CONTENTFUL_ENVIRONMENT_ID,
}

export const createContentfulClient = (config = defaultConfig) => {
  const options = {
    host: 'preview.contentful.com',
    space: config.CTF_SPACE_ID,
    accessToken: config.CTF_CPA_TOKEN,
    environment: config.CTF_ENVIRONMENT_ID,
  }

  if (process.env.REACT_APP_CONTENTFUL_PREVIEW === 'false') {
    options.host = 'cdn.contentful.com'
    options.accessToken = config.CTF_CDA_TOKEN
  }
  return createClient(options)
}
