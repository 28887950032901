import React from 'react'
import classnames from 'classnames'

const HamburgerMenuSVG = ({ className }) => {
  return (
    <svg
      viewBox="0 0 20 20"
      className={classnames('dealmed-icon', className)}
      aria-hidden={true}
      focusable={false}
    >
      <path d="M0 3h20v2h-20v-2zM0 9h20v2h-20v-2zM0 15h20v2h-20v-2z"></path>
    </svg>
  )
}

export default HamburgerMenuSVG
