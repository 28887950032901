//import * as R from 'ramda'
import {
  // SET_SELECTED_SHIPPING_ADDRESS,
  // SET_SELECTED_BILLING_ADDRESS,
  SET_TERM_DETAILS,
  SET_SUBMISSION_DETAILS,
  SET_CHECKOUT_ERROR_MESSAGES,
  SET_STATUSES,
} from '../actions/types'

const INITIAL_STATE = {
  invoiceDetails: {
    hasTerms: false,
    terms: '',
    availableBalance: 0,
  },
  submissionDetails: {},
  errorMessages: [],
  statuses: {},
}

export default (state = INITIAL_STATE, { type, payload }) => {
  switch (type) {
    // case SET_SELECTED_SHIPPING_ADDRESS:
    //     return { ...state, selectedShippingAddress: payload }
    // case SET_SELECTED_BILLING_ADDRESS:
    //     return { ...state, selectedBillingAddress: payload }
    // case SET_CHECKOUT_BILLING_OPTION:
    //     return { ...state, checkoutBillingOption: payload }
    case SET_TERM_DETAILS:
      return { ...state, invoiceDetails: payload }
    case SET_SUBMISSION_DETAILS:
      return { ...state, submissionDetails: payload }
    case SET_CHECKOUT_ERROR_MESSAGES:
      return { ...state, errorMessages: payload }
    case SET_STATUSES:
      return { ...state, statuses: payload }
    default:
      return state
  }
}
