import { resolveReq, axios } from '../utils/api'
import { SET_CONTACT_PROPS, START_LOADER, STOP_LOADER, SET_USER_AUTH } from './types'
import { setPaginationFields } from './paginate'
import { mergeRight } from 'ramda'

export const submitIssue = form => {
  return async dispatch => {
    dispatch({ type: START_LOADER, payload: 'submitIssueLoading' })

    const { error } = await resolveReq(axios.post('/api/myaccount/contact/submitIssue', form))
    if (error) return 'Could not submit issue'
    dispatch({ type: STOP_LOADER, payload: 'submitIssueLoading' })

    return 'Issue successfully submitted'
  }
}

export const getContact = ({ id }) => {
  return async dispatch => {
    dispatch({ type: SET_CONTACT_PROPS, payload: { isLoading: true } })
    const { data, error } = await resolveReq(axios.get('/api/contacts/getContact/' + id))

    if (error) {
      dispatch({ type: SET_CONTACT_PROPS, payload: { isLoading: false, message: error.message } })
      return console.log(error)
    }

    dispatch({ type: SET_CONTACT_PROPS, payload: { isLoading: false, user: data } })
  }
}

export const updateProfile = contact => {
  return async dispatch => {
    dispatch({ type: START_LOADER, payload: 'updatingProfile' })
    const { error } = await resolveReq(
      axios.patch('/api/myaccount/contact/updateCurrentContact', contact),
    )

    if (error) return console.log(error)

    const { data } = await resolveReq(axios.get('/api/auth/currentUser'))
    dispatch({ type: STOP_LOADER, payload: 'updatingProfile' })
    dispatch({ type: SET_USER_AUTH, payload: data })
  }
}

/* 
    The method "updateProfile" is soon to be deprecated.
    The method below "setUser" is used to reset the user whenever changes to the profile are made.
    Updating profile is now handled with local state but the user credentials is still shared globally across app.
*/
export const resetUser = () => {
  return async dispatch => {
    const { data } = await resolveReq(axios.get('/api/auth/currentUser'))

    dispatch({ type: SET_USER_AUTH, payload: data })
  }
}
// export const getContacts = (query) => {
//     return async dispatch => {
//         const str = qs.stringify(query)

//         if (query.page)
//             setPaginationFields({ item: 'contacts', data: { page: query.page, isLoading: true }, dispatch })

//         const { data, error } = await resolveReq(axios.get('/api/contacts/paginate?' + str))

//         if (error)
//             return console.log(error)

//         setPaginationFields({ item: 'contacts', data: merge(data, { isLoading: false }), dispatch })
//     }
// }

// export const createContact = (contact) => {
//     return async dispatch => {

//         dispatch({ type: SET_CONTACT_PROPS, payload: { isLoading: true } })
//         const { data, error } = await resolveReq(axios.post('/api/contacts/createContact', contact))

//         if (error) {
//             dispatch({ type: SET_CONTACT_PROPS, payload: { isLoading: false, message: error.message } })
//             return console.log(error)
//         }

//         dispatch({ type: SET_CONTACT_PROPS, payload: { isLoading: false, modal: false, message: '' } })
//     }
// }

// export const editContact = (contact) => {
//     return async dispatch => {

//         dispatch({ type: SET_CONTACT_PROPS, payload: { isLoading: true } })
//         const { data, error } = await resolveReq(axios.post('/api/contacts/editContact', contact))

//         if (error) {
//             dispatch({ type: SET_CONTACT_PROPS, payload: { isLoading: false, message: error.message } })
//             return console.log(error)
//         }

//         dispatch({ type: SET_CONTACT_PROPS, payload: { isLoading: false, modal: false, message: '' } })
//     }
// }

// export const deleteContact = (id) => {
//     return async dispatch => {

//         dispatch({ type: SET_CONTACT_PROPS, payload: { isLoading: true } })
//         const { error } = await resolveReq(axios.post('/api/contacts/delete', { id: id }))
//         if (error) {
//             dispatch({ type: SET_CONTACT_PROPS, payload: { isLoading: false, message: error.message } })
//             return console.log(error)
//         }

//         dispatch({ type: SET_CONTACT_PROPS, payload: { isLoading: false, modal: false, message: '' } })
//     }
// }

export const setContactProps = payload => {
  return { type: SET_CONTACT_PROPS, payload }
}
