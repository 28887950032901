import React from 'react'
import { documentToReactComponents } from '@contentful/rich-text-react-renderer'
import './Home.scss'

const HomeAlertBanner = ({ props }) => {
  const { text } = props
  const options = {
    renderText: text => {
      return text.split('\n').reduce((children, textSegment, index) => {
        return [...children, index > 0 && <br key={index} />, textSegment]
      }, [])
    },
  }

  return (
    <div className="alert-banner-wrapper">
      {/* <p className="alert-banner-text">{text}</p> */}
      <div className="alert-banner-text">{documentToReactComponents(text, options)}</div>
    </div>
  )
}

export default HomeAlertBanner
