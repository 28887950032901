import React from 'react'
import classnames from 'classnames'

const DownloadSVG = ({ className }) => {
  return (
    <svg
      viewBox="0 0 26 32"
      className={classnames('dealmed-icon', className)}
      aria-hidden={true}
      focusable={false}
    >
      <path d="M20.788 31.97c0-1.062 0-2.112 0-3.181 0.525 0 1.047 0 1.579 0 0-5.87 0-11.718 0-17.596-0.068 0-0.139 0-0.21 0-2.048 0-4.096 0.004-6.144 0-0.799 0-1.437-0.521-1.59-1.29-0.030-0.146-0.030-0.296-0.030-0.446 0-1.999 0-3.999 0-5.994 0-0.075 0-0.15 0-0.24-3.732 0-7.45 0-11.182 0 0 8.507 0 17.022 0 25.556 0.521 0 1.043 0 1.575 0 0 1.073 0 2.127 0 3.173-0.015 0.011-0.026 0.023-0.037 0.023-0.754-0.008-1.515 0.090-2.258-0.079-1.489-0.338-2.483-1.598-2.487-3.147-0.007-2.018-0.004-4.044-0.004-6.065 0-6.463 0-12.926 0-19.393 0-1.474 0.851-2.701 2.202-3.121 0.334-0.105 0.698-0.161 1.047-0.161 3.781-0.011 7.558-0.007 11.336-0.007 0.934 0 1.729 0.326 2.389 0.987 2.532 2.536 5.068 5.071 7.603 7.603 0.664 0.664 1.002 1.455 1.002 2.401-0.004 5.9-0.011 11.804 0.004 17.705 0.004 1.534-0.998 2.791-2.314 3.155-0.214 0.060-0.439 0.109-0.66 0.113-0.6 0.019-1.197 0.008-1.819 0.008zM12.784 31.917c2.101-2.101 4.216-4.216 6.335-6.335-1.56 0-3.136 0-4.734 0 0-3.736 0-7.453 0-11.178-1.073 0-2.123 0-3.196 0 0 3.729 0 7.446 0 11.178-1.594 0-3.166 0-4.741 0 2.127 2.127 4.246 4.242 6.335 6.335z"></path>
    </svg>
  )
}

export default DownloadSVG
