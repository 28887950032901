import { resolveReq, axios } from '../utils/index'
import { START_LOADER, STOP_LOADER, SET_ADDRESS_BOOK_PROP } from './types'

// eslint-disable-next-line no-console
const logIfError = e => e && console.log(e.message)

export const getAddressBook = () => {
  return async dispatch => {
    dispatch({ type: START_LOADER, payload: 'gettingAddresses' })

    const addressPromise = resolveReq(axios.get('/api/myaccount/address/getAll'))

    const { data, error } = await addressPromise

    logIfError(error)

    if (data.mappedResults)
      dispatch({
        type: SET_ADDRESS_BOOK_PROP,
        payload: { prop: 'addresses', data: data.mappedResults },
      })
    if (data.dddShippingCutoffTime)
      dispatch({
        type: SET_ADDRESS_BOOK_PROP,
        payload: { prop: 'dddShippingCutoffTime', data: data.dddShippingCutoffTime },
      })

    dispatch({ type: STOP_LOADER, payload: 'gettingAddresses' })

    return data.mappedResults
  }
}

export const upsertAddressBook = address => {
  return async dispatch => {
    dispatch({ type: START_LOADER, payload: 'addressBookUpdating' })
    const { error, data } = await resolveReq(axios.post('/api/myaccount/address/upsert', address))
    logIfError(error)

    if (data) dispatch({ type: SET_ADDRESS_BOOK_PROP, payload: { prop: 'modal', data: false } })

    dispatch({ type: STOP_LOADER, payload: 'addressBookUpdating' })
  }
}

export const toggleAddressBookModal = open => ({
  type: SET_ADDRESS_BOOK_PROP,
  payload: { prop: 'modal', data: Boolean(open) },
})

export const setAddressBookProp = (prop, data) => ({
  type: SET_ADDRESS_BOOK_PROP,
  payload: { prop, data },
})

export const setAddressBook = data => ({
  type: SET_ADDRESS_BOOK_PROP,
  payload: { prop: 'address', data },
})

export const deleteAddressBook = id => {
  return async dispatch => {
    dispatch({ type: START_LOADER, payload: 'addressBookUpdating' })
    const { error, data } = await resolveReq(axios.post('/api/myaccount/address/delete', { id }))
    logIfError(error)

    if (data) dispatch({ type: SET_ADDRESS_BOOK_PROP, payload: { prop: 'modal', data: false } })

    dispatch({ type: STOP_LOADER, payload: 'addressBookUpdating' })
  }
}
