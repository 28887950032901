import React, { useContext, useReducer, useEffect, useRef, useState } from 'react'
import { connect } from 'react-redux'

import { useMediaQuery } from 'react-responsive'
import { selectCategory } from '../../actions'
import classnames from 'classnames'
import MainNavigationHeader from './MainNavigationHeader'

import MainNavigationLink from './MainNavigationLinkMobile'
import Paginate from '../Reusable/Paginate'
import HamburgerMenuSVG from '../../shared/icons/HamburgerMenuSVG'
import { ContentfulContext } from '../../shared/ContentfulContext'

import { ShoppingContext } from '../../shared/ShoppingContext'
import { mixpanelLogEvent } from '../../utils/mixpanelLogEvent'
import MainNavigationMenuMobile from './MainNavigationMenuMobile'
import MainNavigationTitleMobile from './MainNavigationTitleMobile'
import Collapsible from 'react-collapsible'

const initialState = {
  mainNavigationIsOpen: false,
  activeMenus: [],
}

const mainNavigationReducer = (state, action) => {
  switch (action.type) {
    case 'OPEN_MAIN_NAVIGATION':
      return {
        ...state,
        mainNavigationIsOpen: true,
      }

    case 'CLOSE_MAIN_NAVIGATION':
      return {
        ...initialState,
      }

    case 'UPDATE_ACTIVE_MENUS':
      // If the menuId of the menu we just clicked on is already in the state,
      // we want to remove it and any menus at a higher depth than it
      if (state.activeMenus.some(menu => menu.menuId === action.payload.menuId)) {
        return {
          ...state,
          activeMenus: state.activeMenus.filter(
            menu => menu.menuId !== action.payload.menuId && menu.depth < action.payload.depth,
          ),
        }
      }

      // If there exists a menu with the same depth as an active menu, we want
      // to remove the menu with the same depth and add the new one in
      if (state.activeMenus.some(menu => menu.depth === action.payload.depth)) {
        const filteredActiveMenus = state.activeMenus.filter(
          menu => menu.depth < action.payload.depth,
        )

        return {
          ...state,
          activeMenus: [
            ...filteredActiveMenus,
            {
              menuId: action.payload.menuId,
              depth: action.payload.depth,
              menuElement: action.payload.menuElement,
            },
          ],
        }
      }

      // Otherwise, just simply add the menu to the state array
      return {
        ...state,
        activeMenus: [
          ...state.activeMenus,
          {
            menuId: action.payload.menuId,
            depth: action.payload.depth,
            menuElement: action.payload.menuElement,
          },
        ],
      }

    default:
      return state
  }
}
const MainNavigation = props => {
  const {
    categories = [],
    selectCategory,
    onSubCategoryClick,
    executeSearchAndRedirect,
    history,
  } = props

  const { setFacetsForFiltering } = useContext(ShoppingContext)

  const getMainNavData = contentfulContext =>
    contentfulContext
      .filter(x => x.sys.contentType.sys.id === 'navBar')[0]
      ?.fields.navBarSections.filter(x => x.sys.contentType.sys.id === 'mainNavigation')[0]?.fields
      .navigationSections || []

  const mainNavData = getMainNavData(useContext(ContentfulContext))

  const [{ mainNavigationIsOpen, activeMenus }, dispatch] = useReducer(
    mainNavigationReducer,
    initialState,
  )
  const mainNavigationRef = useRef(null)
  const mainNavigationToggleBtn = useRef(null)
  const [openMenuId, setOpenMenuId] = useState(null)
  const currentlyActiveMenu =
    activeMenus.length > 0 ? activeMenus[activeMenus.length - 1].menuId : undefined

  const logClickEvent = ({ eventType, metaData }) => {
    mixpanelLogEvent({
      eventType,
      userDetails: props.auth.user,
      metaData,
    })
  }

  const MainNavigationItems = ({ data, index }) => {
    return (
      <>
        {data.sys.contentType.sys.id === 'mainNavigationDropdown' ? (
          <li className="mobile-dealmed-main-navigation__item" key={data.sys.id}>
            <MainNavigationMenuMobile
              menuId={data.sys.id}
              isExpanded={activeMenus.some(menu => menu.menuId == data.sys.id)}
              isCurrentlyActive={
                currentlyActiveMenu !== undefined ? currentlyActiveMenu === data.sys.id : false
              }
              text={data.fields.title}
              depth={0}
              parentMenu="Main Menu"
              activeMenus={activeMenus}
              dispatch={dispatch}
            >
              {data.fields.pages.map((page, index) => (
                <li className="mobile-dealmed-main-navigation__item" key={index}>
                  <MainNavigationLink
                    path={page.fields.path}
                    text={page.fields.title}
                    onClick={() => {
                      setOpenMenuId(null)
                      dispatch({ type: 'CLOSE_MAIN_NAVIGATION' })
                    }}
                  />
                </li>
              ))}
            </MainNavigationMenuMobile>
          </li>
        ) : (
          <li className="mobile-dealmed-main-navigation__item" key={index}>
            <MainNavigationLink
              path={data.fields.path}
              text={data.fields.title}
              onClick={() => {
                setOpenMenuId(null)
                dispatch({ type: 'CLOSE_MAIN_NAVIGATION' })
              }}
            />
          </li>
        )}
      </>
    )
  }

  const MainNavLinks = mainNavData.map((item, index) => (
    <MainNavigationItems key={index} data={item} index={index + 1} />
  ))

  const handleOutsideClick = e => {
    if (!mainNavigationRef?.current?.contains(e.target)) {
      dispatch({ type: 'CLOSE_MAIN_NAVIGATION' })
    }
  }

  const handleBlur = e => {
    if (e.relatedTarget === null) {
      return
    }

    if (!e.currentTarget.contains(e.relatedTarget)) {
      dispatch({ type: 'CLOSE_MAIN_NAVIGATION' })

      if (!resize.matches) {
        mainNavigationToggleBtn.current?.focus()
      }
    }
  }

  const handleChange = () => {
    dispatch({ type: 'CLOSE_MAIN_NAVIGATION' })
  }

  const resize = useMediaQuery({ minWidth: 767 }, undefined, handleChange)

  const mainNavigationWrapperClasses = classnames('mobile-dealmed-main-navigation__wrapper', {
    'mobile-dealmed-main-navigation__wrapper--expanded': mainNavigationIsOpen,
  })

  useEffect(() => {
    document.addEventListener('click', handleOutsideClick, { capture: true })
    return () => document.removeEventListener('click', handleOutsideClick)
  }, [])

  //this way prevents memory leaks than ^^

  return (
    <>
      <nav ref={mainNavigationRef} className="mobile-dealmed-main-navigation" id="main-navigation">
        <button
          ref={mainNavigationToggleBtn}
          type="button"
          className="mobile-dealmed-main-navigation__toggle-btn"
          aria-expanded={mainNavigationIsOpen}
          onClick={() => dispatch({ type: 'OPEN_MAIN_NAVIGATION' })}
        >
          <span className="visually-hidden">Open Main Navigation</span>
          <HamburgerMenuSVG className="mobile-dealmed-main-navigation__toggle-btn-icon" />
        </button>
        {mainNavigationIsOpen && (
          <div
            className="mobile-dealmed-main-navigation__overlay"
            onClick={() => {
              setOpenMenuId(null)
              dispatch({ type: 'CLOSE_MAIN_NAVIGATION' })
            }}
          ></div>
        )}
        <div className={mainNavigationWrapperClasses} onBlur={handleBlur}>
          <MainNavigationHeader setOpenMenuId={setOpenMenuId} dispatch={dispatch} />
          <div className="mobile-dealmed-main-navigation__body">
            <ul className="mobile-dealmed-main-navigation__list">
              {categories?.map((category, index) => {
                const isCurrentlyActive =
                  currentlyActiveMenu !== undefined ? currentlyActiveMenu === category.id : false

                return (
                  <li
                    key={category.id}
                    className={`mobile-dealmed-main-navigation__item${
                      isCurrentlyActive ? ' isSelected' : ''
                    }`}
                  >
                    <Collapsible
                      trigger={
                        <MainNavigationTitleMobile
                          menuId={category.id}
                          isExpanded={activeMenus.some(menu => menu.menuId === category.id)}
                          isCurrentlyActive={isCurrentlyActive}
                          text={category.name}
                          depth={0}
                          parentMenu="Main Menu"
                          activeMenus={activeMenus}
                          dispatch={dispatch}
                          openMenuId={openMenuId}
                          setOpenMenuId={setOpenMenuId}
                        />
                      }
                      open={activeMenus.some(menu => menu.menuId === category.id)}
                    >
                      {category.children.map(category => {
                        const isCurrentlyActive =
                          currentlyActiveMenu !== undefined
                            ? currentlyActiveMenu === category.id
                            : false

                        return (
                          <li key={category.id} className="mobile-dealmed-main-navigation__item">
                            <MainNavigationMenuMobile
                              menuId={category.id}
                              categoryPath={category.urlComponent}
                              isExpanded={activeMenus.some(menu => menu.menuId === category.id)}
                              isCurrentlyActive={isCurrentlyActive}
                              text={category.name}
                              depth={1}
                              parentMenu={category.isParent ? 'Products' : category.name}
                              activeMenus={activeMenus}
                              dispatch={dispatch}
                              currentlyActiveMenu={currentlyActiveMenu}
                            >
                              {category?.children.map(subCategory => {
                                return (
                                  <li
                                    key={subCategory.id}
                                    className="mobile-dealmed-main-navigation__item"
                                  >
                                    <MainNavigationLink
                                      text={subCategory.name}
                                      menuId={category.id}
                                      depth={1}
                                      path={() => {
                                        const queryParam = subCategory.urlComponent
                                        return (
                                          '/products/' +
                                          (queryParam && queryParam !== '' ? queryParam : '')
                                        )
                                      }}
                                      dispatch={dispatch}
                                      onClick={async () => {
                                        setOpenMenuId(null)
                                        dispatch({ type: 'CLOSE_MAIN_NAVIGATION' })
                                        logClickEvent({
                                          eventType: `NavBar_Subcategory_Click`,
                                          metaData: { subcategory: subCategory.name },
                                        })
                                        selectCategory(category)
                                        setFacetsForFiltering({})
                                        await onSubCategoryClick({ subCategory })
                                        executeSearchAndRedirect()
                                      }}
                                    />
                                  </li>
                                )
                              })}
                            </MainNavigationMenuMobile>
                          </li>
                        )
                      })}
                    </Collapsible>
                  </li>
                )
              })}
              {MainNavLinks}
            </ul>
          </div>
        </div>
      </nav>
    </>
  )
}
const mapStateToProps = state => {
  return {
    auth: state.auth,
    categories: state.shopping.categories,
  }
}

export default connect(mapStateToProps, { selectCategory })(Paginate(MainNavigation))
