import React from 'react'
import './EKGLoader.scss'

const EKGLoader = () => {
  return (
    <div className="ekg-loader-wrapper">
      <div className="heart-container">
        <span className="mdi mdi-heart-outline heart" />
      </div>
    </div>
  )
}

export default EKGLoader
