import React, { useContext, useState } from 'react'
import { ProductDetailsContext } from './ProductDetailsContext'
import classnames from 'classnames'
import { connect } from 'react-redux'
import { pathOr } from 'ramda'
import { flyToCart } from '../../utils'
import ShoppingCartSVG from '../../shared/icons/ShoppingCartSVG'
import Spinner from '../../shared/components/Spinner'
import Paginate from '../Reusable/Paginate'
import { Tooltip } from 'reactstrap'
const aa = require('search-insights')

const AddToCart = props => {
  const productDetailsProps = useContext(ProductDetailsContext)
  const [tooltipOpen, setTooltipOpen] = useState(false)
  const { addToCartIsLoading } = props
  const isOnAllocation = Boolean(pathOr(false, ['allocationPeriod'], productDetailsProps.product))
  const isLoadingAllocation =
    isOnAllocation && pathOr(false, ['retrievingAllocation'], productDetailsProps)

  const toggleTooltip = () => setTooltipOpen(!tooltipOpen)

  const handleClick = async () => {
    props.logClickEvent({ eventType: 'PDP_Add_Item_To_Cart' })
    const didAddToCart = await addItemToCart()
    if (process.env.NODE_ENV === 'production') {
      if (props?.search) {
        aa('addedToCartObjectIDsAfterSearch', {
          eventName: 'PDP Added to Cart After Search',
          index: 'itemsIndex',
          objectIDs: [String(props?.product?.id)],
          queryID: props?.products?.queryID,
        })
      } else {
        aa('addedToCartObjectIDs', {
          eventName: 'PDP Added to Cart',
          index: 'itemsIndex',
          objectIDs: [String(props?.product?.id)],
        })
      }
    }
    props.setShowAfterAddPage(didAddToCart)
  }

  const addItemToCart = async () => {
    const didAddToCart = await productDetailsProps.validateRxAndAddToCart(
      props.product,
      props.quantity,
      pathOr(null, ['value'], props.uom),
      props?.search,
      props?.search && props?.products?.queryID,
    )
    flyToCart()
    const itemclick = localStorage.getItem('itemclick_' + props.product.id)

    if (typeof itemclick !== 'undefined' && itemclick !== null) {
      const clickObjectEvent = JSON.parse(itemclick)
      if (clickObjectEvent && clickObjectEvent.userToken && clickObjectEvent.queryID) {
        // Adding Algolia Add to cart Conversion
        const objectID = "'" + props.product.id + "'"
        const addToCartEventObject = {
          userToken: clickObjectEvent.userToken ? String(clickObjectEvent.userToken) : '',
          eventName: 'Product Added to Cart',
          index: 'itemsIndex',
          queryID: clickObjectEvent.queryID,
          objectIDs: objectID ? [objectID] : [],
        }
        if (productDetailsProps.auth.user) {
          try {
            aa('convertedObjectIDsAfterSearch', addToCartEventObject)
            console.log('Product Added to Cart', addToCartEventObject)
          } catch (e) {
            console.log(e)
          }
        }
      }
    }
    return didAddToCart
  }

  return (
    <>
      <button
        type="button"
        id="pdpAddToCartButton"
        disabled={isLoadingAllocation || addToCartIsLoading || props.allocationMax === 0}
        className="dealmed-product__add-to-cart"
        onClick={handleClick}
        style={{
          width: '220px',
          height: '56px',
          backgroundColor: `${props.allocationMax === 0 ? 'rgb(92, 105, 105)' : null}`,
        }}
      >
        {addToCartIsLoading ? (
          <Spinner />
        ) : (
          <div
            style={{ display: 'flex', justifyContent: 'center', alignItems: 'center', gap: '10px' }}
          >
            <span
              className="dealmed-product__add-to-cart-text"
              style={{ fontSize: '16px', fontWeight: '600' }}
            >
              Add to Cart
            </span>
          </div>
        )}
      </button>
      {isLoadingAllocation ||
        addToCartIsLoading ||
        (props.allocationMax === 0 && (
          <Tooltip
            placement="right"
            isOpen={tooltipOpen}
            target="pdpAddToCartButton"
            toggle={toggleTooltip}
          >
            <p style={{ textAlign: 'left' }}>
              Please contact your sales rep for information on allocation.
            </p>
          </Tooltip>
        ))}
    </>
  )
}

const mapStateToProps = state => ({
  addToCartIsLoading: state.loader.addToCartIsLoading,
  auth: state.auth,
  search: state.shopping.searchTerm,
})

export default Paginate(connect(mapStateToProps, null)(AddToCart))
