/* eslint-disable import/no-anonymous-default-export */
import { mergeRight, pathOr } from 'ramda'
import { RESET_PAGINATE, SET_PAGINATE_FIELD, CONCAT_TO_PRODUCTS } from '../actions/types'

const INITIAL_PAGINATE = {
  limit: 10,
  page: 1,
  pages: null,
  total: null,
  results: null,
  isLoading: true,
  numPaginateSection: 1,
}

const INITIAL_STATE = {
  contacts: { ...INITIAL_PAGINATE, limit: 100 },
  products: { ...INITIAL_PAGINATE },
  reorders: { ...INITIAL_PAGINATE, limit: 100 },
  salesorders: { ...INITIAL_PAGINATE },
  estimates: { ...INITIAL_PAGINATE },
  payments: { ...INITIAL_PAGINATE },
  invoices: { ...INITIAL_PAGINATE },
}

export default (state = INITIAL_STATE, { type, payload }) => {
  switch (type) {
    case RESET_PAGINATE:
      return { ...state, [payload]: INITIAL_PAGINATE }

    case SET_PAGINATE_FIELD:
      return {
        ...state,
        [payload.reducerProp]: mergeRight(state[payload.reducerProp], payload.data),
      }
    case CONCAT_TO_PRODUCTS: {
      const paginatedItems = pathOr([], ['products', 'paginatedItems'], state)
      const newProducts = pathOr([], ['paginatedItems'], payload)
      return {
        ...state,
        products: {
          ...state.products,
          page: payload.page,
          paginatedItems: paginatedItems.concat(newProducts),
        },
      }
    }
    default:
      return state
  }
}
