import { useMediaQuery } from 'react-responsive'

export const Mobile = ({ children }) => {
  const isMobile = useMediaQuery({ maxWidth: 767 })
  return isMobile ? children : null
}

export const Tablet = ({ children }) => {
  const isTablet = useMediaQuery({ minWidth: 768, maxWidth: 991 })
  return isTablet ? children : null
}

export const TabletAndSmaller = ({ children }) => {
  const isTabletAndSmaller = useMediaQuery({ maxWidth: 991 })
  return isTabletAndSmaller ? children : null
}

export const TabletAndLarger = ({ children }) => {
  const isTablet = useMediaQuery({ minWidth: 768 })
  return isTablet ? children : null
}

export const Desktop = ({ children }) => {
  const isDesktop = useMediaQuery({ minWidth: 992 })
  return isDesktop ? children : null
}

export const DesktopAndSmaller = ({ children }) => {
  const isDesktopAndSmaller = useMediaQuery({ maxWidth: 1439 })
  return isDesktopAndSmaller ? children : null
}

export const LargeDesktop = ({ children }) => {
  const isLargeDesktop = useMediaQuery({ minWidth: 1440 })
  return isLargeDesktop ? children : null
}
