import React, { useState, useEffect, useCallback } from 'react'
import { connect } from 'react-redux'
import { withRouter } from 'react-router-dom'
import * as R from 'ramda'
import { isNil } from 'ramda'
import { Helmet } from 'react-helmet'
import TransactionHOC from '../Reusable/TransactionHOC'
import ProductHOC from '../Reusable/ProductHOC'
import { changeQuantity, setUom, setAlternativeItems, setCorrelatedItems } from '../../actions'
import { getUrlComponent } from '../../utils'
import ProductSchemaMarkup from './ProductSchemaMarkup'

import './ProductDetails.scss'

// AE-ADDED COMPONENTS
import ProductDetailsImages from './ProductDetailsImages'
import ProductDetailsBox from './ProductDetailsBox'
import ProductDetailsHeader from './ProductDetailsHeader'
import ProductDetailsInfoBar from './ProductDetailsInfoBar'
import ProductDetailsQuantityTypeDropdown from './ProductDetailsQuantityTypeDropdown'
import ProductDetailsQuantityInput from './ProductDetailsQuantityInput'
import ProductDetailsLoginButton from './ProductDetailsLoginButton'
import ProductDetailsAddToCart from './ProductDetailsAddToCart'
import ProductDetailsActions from './ProductDetailsActions'
import ProductDetailsMatrixDropdown from './ProductDetailsMatrixDropdown'
import ProductDetailsDescription from './ProductDetailsDescription'
import ProductDetailsTabsNavigation from './ProductDetailsTabsNavigation'
import ProductDetailsDownloads from './ProductDetailsDownloads'
import ProductDetailsRelatedProducts from './ProductDetailsRelatedProducts'
import ProductDetailsAlternativeProducts from './ProductDetailsAlternativeProducts'
import { ProductDetailsContext } from './ProductDetailsContext'
import ProductDetailsBreadcrumbs from './ProductDetailsBreadcrumbs'

const { REACT_APP_STORAGE_URL, REACT_APP_CONT_PRODUCT_IMG, PUBLIC_URL } = process.env

const IMAGE_URL = REACT_APP_STORAGE_URL + REACT_APP_CONT_PRODUCT_IMG

const formatter = new Intl.NumberFormat('en-US', {
  style: 'currency',
  currency: 'USD',
})

const ProductDetails = props => {
  const [state, setState] = useState({
    bigImageSrc: null,
    conversionRate: 1,
    active: null,
  })

  const {
    location,
    match,
    setUom,
    history,
    setProduct,
    setAlternativeItems,
    setCorrelatedItems,
    resetProduct,
    changeQuantity,
  } = props

  useEffect(() => {
    const func = async () => {
      window.scrollTo(0, 0)
      changeQuantity(1)
      setUom({})
      const id = match.params.id

      const { hash } = location
      const activeNum = hash == '#pd' ? 0 : hash == '#rp' ? 1 : hash == '#ap' ? 2 : null
      setState({ ...state, active: activeNum })

      if (!id) return history.replace('/products')

      await setProduct(id, true)
      setCorrelatedItems(id)
      setAlternativeItems(id)
    }

    func()

    return () => {
      resetProduct()
    }
  }, [])

  const resetState = () => {
    setState({
      ...state,
      bigImageSrc: null,
      conversionRate: 1,
      active: null,
    })
  }

  useEffect(() => {
    const func = async () => {
      const currentId = match.params.id
      resetState()
      changeQuantity(1)
      setUom({})
      await setProduct(currentId)
      await setAlternativeItems(currentId)
      await setCorrelatedItems(currentId)

      if (!props.product.data.children.length > 0) {
        window.scrollTo(0, 0)
      }
    }

    func()
  }, [match.params.id])

  const selectItem = (children, id) =>
    R.find(f => id == f.urlComponent || id == f.id)(children) || children[0]

  const onUomChange = (uom, units) => {
    if (!units) {
      return
    }

    let foundUnit = units.find(f => f.value === uom.value)
    let newState = { ...state }
    newState.conversionRate = foundUnit.conversionRate || 1
    setState(newState)
    setUom(uom)
  }

  let id = match.params.id

  const product =
    props.product.data.children && props.product.data.children.length > 0
      ? R.mergeDeepRight(
          props.product.data,
          R.omit(['description'], selectItem(props.product.data.children, id)),
        )
      : props.product.data

  const quantityAvailable = R.pathOr(0, ['quantityAvailable'], product)
  let description = R.pathOr(false, ['description'], product)
  if (description) {
    description = description.replace(/<br>/g, '')
  }

  let units = R.map(unit => {
    return {
      value: unit.id,
      label: unit.unitName,
      conversionRate: unit.conversionRate,
      baseUnit: unit.baseUnit,
    }
  }, R.pathOr([], ['unit', 'details'], product))

  const search = window.location.search
  const params = new URLSearchParams(search)
  const uom = params.get('uom')
  const defaultUnit =
    (uom ? units.find(unit => unit.value == uom) : R.find(unit => unit.baseUnit, units)) || {}

  const setProductImage = useCallback(
    imageSrc => {
      setState({ ...state, bigImageSrc: imageSrc })
    },
    [state.bigImageSrc],
  )

  if (product.id) {
    const urlComp = getUrlComponent(product)

    const isAuthenticated = Boolean(R.pathOr(false, ['user', 'id'], props.auth))

    const isProductAvailable = () => {
      if (quantityAvailable > 0) {
        return true
      }
      return false
    }

    const thumbnailImage = product.children
      ? product.children[R.findIndex(R.propEq(product.id, 'id'))(product.children)]?.thumbnailImage
      : null
    const parentThumbnailImage = R.pathOr('', ['product', 'data', 'thumbnailImage'], props)

    const metaDescriptionTag = R.pathOr('', ['metaTag'], product)
    const titleTag = R.pathOr('', ['titleTag'], product)

    const productImageSrc = state.bigImageSrc
      ? state.bigImageSrc
      : thumbnailImage
      ? IMAGE_URL + thumbnailImage
      : R.pathOr([], ['itemImage'], product).length > 0
      ? product.itemImage[0].largeUrl
      : parentThumbnailImage
      ? IMAGE_URL + parentThumbnailImage
      : `${PUBLIC_URL}/images/imageComingSoon.png`

    const selectedUnit = Object.keys(props.uom).length === 0 ? defaultUnit : props.uom
    let productPrice = formatter.format(product.price * props.quantitySelected)
    if (selectedUnit.conversionRate) {
      productPrice = formatter.format(
        product.price * selectedUnit.conversionRate * props.quantitySelected,
      )
    }

    const downloads = R.pathOr([], ['itemFile'], product)
    const relatedProducts = R.pathOr([], ['correlatedItems'], props)
    const alternativeProducts = R.pathOr([], ['alternativeItems'], props)
    const matrixItems = R.pathOr(false, ['children'], product)
    const matrixItemDetailString = product.matrixDetails ? Object.values(product.matrixDetails) : ''

    let displayTabNavigation = false

    if (
      (!R.isEmpty(downloads) && relatedProducts.length > 0) ||
      (!R.isEmpty(downloads) && alternativeProducts.length > 0) ||
      (relatedProducts.length > 0 && alternativeProducts.length > 0)
    ) {
      displayTabNavigation = true
    }

    return (
      <>
        <ProductDetailsContext.Provider value={props}>
          <Helmet>
            <meta
              name="keywords"
              content={`${product.manufacturer},${product.mpn},${product.searchKeywords},best`}
            />
            {/* <meta name="description" content={metaDescriptionTag} */}
            <meta
              name="description"
              content={`Get the best prices on ${matrixItemDetailString} ${
                product.siteDisplayName || product.displayName
              }'s from Dealmed. We offer a wide selection of top-notch products!`}
            />
            <meta name="robots" content="index,follow" />
            <title>
              {titleTag ||
                (product.siteDisplayName || product.displayName) +
                  (product.matrixDetails ? ' - ' + Object.values(product.matrixDetails) : '')}{' '}
              | Dealmed Medical Supplies
            </title>
            <link rel="canonical" href={`https://dealmed.com/product/${urlComp}`} />
          </Helmet>
          <div className="product-details__container">
            <div className="product-details__main">
              <ProductDetailsBreadcrumbs props={props} />

              <div className="product-details__product">
                <ProductDetailsBox>
                  <ProductDetailsHeader product={product} availability={isProductAvailable()} />

                  <ProductDetailsInfoBar>
                    {isAuthenticated ? (
                      <div className="product-details__info-wrapper">
                        <div className="product-details__info-content">
                          {units.length > 0 && (
                            <ProductDetailsQuantityTypeDropdown
                              id="product-quantity-type-dropdown"
                              units={units}
                              updateUnits={selectedUnit => onUomChange(selectedUnit, units)}
                              value={selectedUnit}
                            />
                          )}

                          {matrixItems.length > 0 ? (
                            <ProductDetailsMatrixDropdown
                              id="matrix-products-dropdown"
                              matrixItems={matrixItems}
                              product={product}
                              uom={selectedUnit}
                            />
                          ) : null}

                          <ProductDetailsQuantityInput
                            id="product-quantity-input"
                            name="product-quantity-input"
                          />
                          {(!isNil(product.isAddableToCart) && !product.isAddableToCart) ||
                          (isNil(product.isAddableToCart) && product.price === 'N/A') ? (
                            <p className="product-details__info-invalid">
                              This product is currently unavailable. Please check back later!
                            </p>
                          ) : (
                            <>
                              <p
                                className="product-details__info-price"
                                style={isNaN(product.price) ? { visibility: 'hidden' } : null}
                              >
                                {productPrice}
                              </p>
                              <div className="product-details__info-add">
                                <ProductDetailsAddToCart
                                  product={product}
                                  allocationMax={props.allocationMax}
                                  units={!R.isEmpty(props.uom) ? props.uom : defaultUnit}
                                />
                              </div>
                            </>
                          )}
                        </div>
                      </div>
                    ) : (
                      <div className="product-details__info-wrapper">
                        <ProductDetailsLoginButton />
                      </div>
                    )}
                    {props.allocationMax === 0 && (
                      <p style={{ width: '100%', textAlign: 'center' }}>
                        You have no more allocation on this product.
                      </p>
                    )}
                  </ProductDetailsInfoBar>

                  <ProductDetailsActions product={product} defaultUnit={defaultUnit} />
                </ProductDetailsBox>
              </div>
              <div className="product-details__assets">
                <ProductDetailsImages
                  productImageSrc={productImageSrc}
                  setProductImage={setProductImage}
                />
              </div>

              {description ? (
                <div className="product-details__description">
                  <ProductDetailsDescription productDescription={description} />
                </div>
              ) : null}
            </div>
            {!R.isEmpty(downloads) ||
            relatedProducts.length > 0 ||
            alternativeProducts.length > 0 ? (
              <div className="product-details__bottom">
                {displayTabNavigation ? (
                  <ProductDetailsTabsNavigation
                    downloads={downloads}
                    relatedProducts={relatedProducts}
                    alternativeProducts={alternativeProducts}
                  />
                ) : null}

                {!R.isEmpty(downloads) ? (
                  <ProductDetailsDownloads productDetailsProps={props} />
                ) : null}

                {relatedProducts.length > 0 ? (
                  <ProductDetailsRelatedProducts productDetailsProps={props} />
                ) : null}

                {alternativeProducts.length > 0 ? (
                  <ProductDetailsAlternativeProducts productDetailsProps={props} />
                ) : null}
              </div>
            ) : null}
            <ProductSchemaMarkup productDetailsProps={props} productImgUrl={IMAGE_URL} />
          </div>
        </ProductDetailsContext.Provider>
      </>
    )
  }
  return <div />
}

const mapStateToProps = state => {
  return {
    auth: state.auth,
    product: state.shopping.product,
    quantitySelected: state.shopping.quantitySelected,
    sellableCSLs: state.shopping.sellableCSLs,
    uom: state.shopping.uom,
    alternativeItems: state.shopping.alternativeItems,
    correlatedItems: state.shopping.correlatedItems,
    allocationMax: state.shopping.allocationMax,
    category: state.shopping.product.data.category?.parent,
    subcategory: state.shopping.product.data.category,
    retrievingAllocation: state.loader.retrievingAllocation,
    accountLoading: state.loader.accountLoading,
  }
}

export default withRouter(
  connect(mapStateToProps, { setCorrelatedItems, setAlternativeItems, changeQuantity, setUom })(
    ProductHOC(TransactionHOC(ProductDetails)),
  ),
)
