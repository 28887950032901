import React, { Component } from 'react'
import { Link } from 'react-router-dom'
import CartHOC from '../Reusable/CartHOC'
import NavCartPopover from './NavCartPopover'
import './Navbar.scss'

class NavCart extends Component {
  toggleCartPopover = () => this.props.toggleCartPopover()

  render() {
    const {
      totalItems,
      isPopoverOpen,
      cartLoading,
      cartDetail,
      isPriceVisible,
      orderSummaryDisplay,
      targetId,
      noPopover,
    } = this.props
    return (
      <div id={targetId} className="nav-cart-wrapper float-right">
        <Link to="/cart" onClick={this.toggleCartPopover} className="nav-cart-link">
          <span className="mdi mdi-shopping-outline" />
          {totalItems > 0 && <span className="nav-cart-number">{totalItems}</span>}
        </Link>
        {!noPopover && (
          <NavCartPopover
            targetId={targetId}
            isOpen={isPopoverOpen}
            toggleCartPopover={this.toggleCartPopover}
            cartLoading={cartLoading}
            cartDetail={cartDetail}
            totalItems={totalItems}
            isPriceVisible={isPriceVisible}
            orderSummaryDisplay={orderSummaryDisplay()}
          />
        )}
      </div>
    )
  }
}

export default CartHOC(NavCart)
