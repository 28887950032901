import React from 'react'
import { Link } from 'react-router-dom'
import './Breadcrumbs.scss'

//Crumbs prop is an array of objects each with label and path, except the last one which is not a link so it only has a label
const Breadcrumbs = props => {
  const { crumbs } = props
  const { isPDP } = props
  return (
    <div className="breadcrumbs">
      {crumbs.map((crumb, i, array) =>
        array.length - 1 !== i ? (
          <span key={i}>
            <Link to={{ pathname: crumb.path }} className="breadcrumbs">
              {crumb.label}
            </Link>
            <span className="mdi mdi-chevron-right crumb-divider" />
          </span>
        ) : isPDP ? (
          <span key={i} className="breadcrumbs">
            <a href={crumb.path} className="breadcrumbs">
              {crumb.label}
            </a>
          </span>
        ) : (
          <span key={i} className="current-crumb">
            {crumb.label}
          </span>
        ),
      )}
    </div>
  )
}

export default Breadcrumbs
