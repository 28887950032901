export const createNotificationLink = notification => {
  switch (notification?.notificationType) {
    case 'Fulfillment Delivered':
      return notification?.metaData?.workwaveId
        ? `/tracking/${notification?.metaData?.workwaveId}`
        : null
    case 'Backorder Ready':
      return `/myaccount/menu/salesorders`
    case 'Item Back in Stock':
      return `/product/${notification?.metaData?.item?.urlComponent}`
    default:
      return '/shop'
  }
}
