import {
  SET_SALES_ORDER,
  SET_SALES_ORDERS_FILTERS,
  SET_SALES_ORDERS_ADDRESS_LIST,
  SET_INVOICE_FILTERS,
  SET_INVOICE,
  SET_SHIPMENT,
  SET_REORDER_DATE_OPTION,
  CLEAR_ALL,
  SET_SELECTED_HISTORY_INVOICE,
  SET_SELECTED_HISTORY_PAYMENT,
  SET_PAYMENT_FILTERS,
  SET_STATUS_LIST,
  SET_ESTIMATE,
  SET_ESTIMATE_FILTERS,
  SET_REORDER_FROM_OPTION,
} from '../actions/types'

const filters = {
  toDate: null,
  fromDate: null,
  search: '',
  address: '',
  status: null,
}

const INITIAL_STATE = {
  invoiceHistory: {
    invoice: {},
    invoiceFilters: filters,
  },
  paymentHistory: {
    payment: {},
    filters,
  },
  selectedSalesOrder: {},
  selectedEstimate: {},
  selectedInvoice: {},
  selectedShipment: {},
  viewer: null,
  salesOrdersFilters: filters,
  estimateFilters: filters,
  salesOrdersAddressList: [],
  reorderDateOptions: [
    { value: 15, label: 'Show last 15 days' },
    { value: 30, label: 'Show last 30 days' },
    { value: 60, label: 'Show last 60 days' },
    { value: 90, label: 'Show last 90 days' },
    { value: 180, label: 'Show last 180 days' },
  ],
  reorderFromOptions: [
    { value: 'salesOrder', label: 'Order History' },
    { value: 'estimate', label: 'Estimates' },
  ],
  reorderFromOption: { value: 'salesOrder', label: 'Order History' }, ///Setting the default
  reorderDateOption: { value: 90, label: 'Show last 90 days' }, ///Setting the default
  reorderSearchInput: '',
  reorderSearch: '',
  statusList: [],
}

export default (state = INITIAL_STATE, { type, payload }) => {
  switch (type) {
    case SET_SALES_ORDER:
      return { ...state, selectedSalesOrder: payload }
    case SET_ESTIMATE:
      return { ...state, selectedEstimate: payload }
    case SET_SALES_ORDERS_FILTERS:
      return { ...state, salesOrdersFilters: { ...state.salesOrdersFilters, ...payload } }
    case SET_ESTIMATE_FILTERS:
      return { ...state, salesOrdersFilters: { ...state.salesOrdersFilters, ...payload } }
    case SET_SALES_ORDERS_ADDRESS_LIST:
      return { ...state, salesOrdersAddressList: payload }
    case SET_INVOICE:
      return { ...state, selectedInvoice: payload, viewer: 'invoice' }
    case SET_SHIPMENT:
      return { ...state, selectedShipment: payload, viewer: 'shipment' }
    case SET_REORDER_DATE_OPTION:
      return { ...state, reorderDateOption: payload }
    case SET_REORDER_FROM_OPTION:
      return { ...state, reorderFromOption: payload }
    case SET_INVOICE_FILTERS:
      return {
        ...state,
        invoiceHistory: {
          ...state.invoiceHistory,
          invoiceFilters: { ...state.invoiceHistory.invoiceFilters, ...payload },
        },
      }
    case SET_PAYMENT_FILTERS:
      return {
        ...state,
        paymentHistory: {
          ...state.paymentHistory,
          filters: { ...state.paymentHistory.filters, ...payload },
        },
      }
    case SET_SELECTED_HISTORY_INVOICE:
      return { ...state, invoiceHistory: { ...state.invoiceHistory, invoice: payload } }
    case SET_SELECTED_HISTORY_PAYMENT:
      return { ...state, paymentHistory: { ...state.paymentHistory, payment: payload } }
    case CLEAR_ALL:
      return {
        ...state,
        selectedInvoice: {},
        selectedShipment: {},
        selectedSalesOrder: {},
        viewer: null,
      }
    case SET_STATUS_LIST:
      return { ...state, statusList: payload }
    default:
      return state
  }
}
