import React, { useContext, useRef, useEffect } from 'react'
import { Link } from 'react-router-dom'
import DealmedPopover from '../../shared/components/DealmedPopover'
import Spinner from '../../shared/components/Spinner'
import DownChevronSVG from '../../shared/icons/DownChevronSVG'
import { ShoppingContext } from '../../shared/ShoppingContext'
import { mixpanelLogEvent } from '../../utils/mixpanelLogEvent'
import { positionMatchWidth } from '@reach/popover'

const ContentPopover = ({ data, isOpen, togglePopover, className }) => {
  const triggerRef = useRef(null)
  const popoverWrapperRef = useRef(null)
  const { userInfo } = useContext(ShoppingContext)

  const logClickEvent = ({ eventType }) => {
    mixpanelLogEvent({
      eventType,
      userDetails: userInfo,
    })
  }

  // Close popover when clicking outside
  useEffect(() => {
    const handleClickOutside = event => {
      if (
        popoverWrapperRef.current &&
        !popoverWrapperRef.current.contains(event.target) &&
        !triggerRef.current.contains(event.target)
      ) {
        togglePopover()
      }
    }
    if (isOpen) document.addEventListener('mousedown', handleClickOutside)
    return () => document.removeEventListener('mousedown', handleClickOutside)
  }, [isOpen, togglePopover])

  return (
    <React.Suspense fallback={<Spinner />}>
      <button
        type="button"
        ref={triggerRef}
        className="dealmed-header__support-toggle-btn"
        onClick={() => {
          logClickEvent({ eventType: `${data.fields.title}_Popover_Clicked` })
          togglePopover()
        }}
      >
        {data.fields.title}
        <span className="dealmed-header__icon-wrapper">
          <DownChevronSVG />
        </span>
      </button>

      {isOpen && (
        <DealmedPopover
          innerRef={popoverWrapperRef}
          targetRef={triggerRef}
          closePopover={togglePopover}
          position={positionMatchWidth}
          className={className}
          isContentPopover={true}
        >
          <ul className="dealmed-header__support-list">
            {data.fields.pages.map((page, index) => (
              <Link to={page.fields.path} className="dealmed-header__support-phone">
                <li className="dealmed-header__support-item" key={index} onClick={togglePopover}>
                  {page.fields.title}
                </li>
              </Link>
            ))}
          </ul>
        </DealmedPopover>
      )}
    </React.Suspense>
  )
}

export default ContentPopover
