import React from 'react'
import ProductDetailsDownloadsSlider from './ProductDetailsDownloadsSlider'

const ProductDetailsDownloads = React.memo(({ productDetailsProps }) => {
  return (
    <div className="product-details__downloads">
      <h2 className="product-details__tab-title" id="product-downloads">
        Downloads
      </h2>

      <ProductDetailsDownloadsSlider
        productDetailsProps={productDetailsProps}
        sliderInstanceId="downloads-slider"
      />

      <div className="product-details__downloads-controls">
        <button className="product-details__downloads-control product-details__downloads-control--prev">
          <span className="visually-hidden">Previous Slide</span>
        </button>
        <button className="product-details__downloads-control product-details__downloads-control--next">
          <span className="visually-hidden">Next Slide</span>
        </button>
      </div>
    </div>
  )
})

export default ProductDetailsDownloads
