import React from 'react'
import CheckmarkSVG from '../../shared/icons/CheckmarkSVG'
import ErrorSVG from '../../shared/icons/ErrorSVG'
import PrescriptionSVG from '../../shared/icons/PrescriptionSVG'

const ProductDetailsHeader = React.memo(({ product, availability }) => {
  const productName = product.siteDisplayName || product.displayName
  const productManufacturer = product.manufacturer
  const productId = product.itemName
  const productMPN = product.mpn
  const requiresMedicalLicense = product.rxLevel

  return (
    <div className="product-details__header">
      <h1 className="product-details__product-name">{productName}</h1>
      <div className="product-details__header-info">
        <p className="product-details__product-manufacturer">{productManufacturer}</p>
        <p className="product-details__product-id">{'Item #: ' + productId}</p>
        <p className="product-details__product-mpn">{'MPN: ' + productMPN}</p>

        {availability ? (
          <div className="product-details__availability">
            <span className="product-details__availability-icon-wrapper">
              <CheckmarkSVG className="product-details__availability-icon product-details__availability-icon--available" />
            </span>
            <p className="product-details__availability-text product-details__availability-text--available">
              In Stock
            </p>
          </div>
        ) : (
          <div className="product-details__availability">
            <span className="product-details__availability-icon-wrapper">
              <ErrorSVG className="product-details__availability-icon product-details__availability-icon--unavailable" />
            </span>
            <p className="product-details__availability-text product-details__availability-text--unavailable">
              Out of Stock
            </p>
          </div>
        )}

        {requiresMedicalLicense ? <PrescriptionSVG className="product-details__rx-icon" /> : null}
      </div>
    </div>
  )
})

export default ProductDetailsHeader
