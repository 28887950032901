import React, { Component, Fragment } from 'react'
import { Link } from 'react-router-dom'
import { pathOr, isEmpty } from 'ramda'
import { connect } from 'react-redux'
import Menu from 'react-burger-menu/lib/menus/slide'
import { SlideDown } from 'react-slidedown'
import { Row, Col } from 'reactstrap'
import { logout, selectSubCategory } from '../../actions'
import Paginate from '../Reusable/Paginate'
import RelatedAccountsDropdown from '../Reusable/RelatedAccountsDropdown'
import 'react-slidedown/lib/slidedown.css'
import './Sidebar.scss'

class Sidebar extends Component {
  state = { selectedParentCategory: null, menuOpen: false }

  setSelectedParentCategory = category => {
    const { selectedParentCategory } = this.state
    this.setState({ selectedParentCategory: selectedParentCategory === category ? '' : category })
  }

  onClickSubCategory = async subCategory => {
    await this.props.onSubCategoryClick({ subCategory })
    this.props.executeSearchAndRedirect()
    this.toggleSidebar(false)
  }

  toggleSidebar = toggle => this.setState({ menuOpen: toggle })

  render() {
    const categories = this.props.categories || []
    const sidebarOptions = categories.map((i, index) => {
      const subcategoriesList = i.children.map((subCat, index) => {
        return (
          <li key={index} onClick={() => this.onClickSubCategory(subCat)}>
            <div className="sidebar-subcategory-item">{subCat.name}</div>
          </li>
        )
      })

      return (
        <Fragment key={index}>
          <li
            className="row sidebar-nav-tabs-wrapper"
            aria-expanded={this.state.selectedParentCategory === index}
            onClick={() => this.setSelectedParentCategory(index)}
          >
            <div className="col-12 sidebar-mobile-nav-tabs">
              <p className="sidebar-services-icon-text-mobile text-left sidebar-custom-main-menu-item">
                {i.name}
              </p>
              <span className="sidebar-icon-carousel-right-arrow">
                <span className="mdi mdi-chevron-right" />
              </span>
            </div>
          </li>
          {/* <!-- Subcategories List --> */}
          <SlideDown
            closed={this.state.selectedParentCategory !== index}
            className="sidebar-x-scroll-none"
          >
            <div className="row">
              <ul className="sidebar-unbulleted-list">{subcategoriesList}</ul>
            </div>
          </SlideDown>
        </Fragment>
      )
    })

    const user = pathOr('', ['user', 'firstName'], this.props).substring(0, 11)
    const accountName = pathOr('', ['user', 'account', 'accountName'], this.props).substring(0, 20)
    const currentPath = pathOr('', ['location', 'pathname'], window).substr(1)
    const currentQuery = pathOr('', ['location', 'search'], window)
    const hasRelatedAccounts = !isEmpty(this.props.relatedAccounts)
    return (
      <Menu
        crossButtonClassName="d-none"
        onStateChange={state => this.setState({ menuOpen: state.isOpen })}
        isOpen={this.state.menuOpen}
        burgerButtonClassName="sidebar-custom-burger-button"
        menuClassName="sidebar-custom-bm-menu"
      >
        <div className="main-menu-item-header-custom text-center">
          {user && (
            <>
              <Row className="sidebar-logged-in">
                <Col className="pl-0">
                  <Link
                    to="/myaccount"
                    onClick={() => this.toggleSidebar(false)}
                    className="d-flex flex-column"
                  >
                    <span className="mdi mdi-account-group mdi-24px text-muted" />
                    <span className="fs12px sidebar-account-text text-muted">Account</span>
                  </Link>
                </Col>
                <Col className="d-flex">
                  <span className="fs18px m-auto sidebar-text-gray font-weight-bold">{user}</span>
                </Col>
                <Col className="d-flex flex-column pr-0" onClick={() => this.props.logout()}>
                  <span className="mdi mdi-account-arrow-right mdi-24px text-muted" />
                  <span className="fs12px sidebar-account-text text-muted">Logout</span>
                </Col>
              </Row>
              <Col className="mt-1 mb-2" xs={12}>
                {this.props.retrievingRelatedAccounts && (
                  <>
                    Retrieving account info...
                    <span className="mdi mdi-loading mdi-24px mdi-spin" />
                  </>
                )}
                {!this.props.retrievingRelatedAccounts && hasRelatedAccounts && (
                  <RelatedAccountsDropdown />
                )}
                {!this.props.retrievingRelatedAccounts && !hasRelatedAccounts && (
                  <h6 className="fs15px sidebar-text-gray">{accountName}</h6>
                )}
              </Col>
            </>
          )}
          {!user && (
            <div>
              <Link
                // to={{ pathname: '/login/' + currentPath, search: currentQuery }}
                // to={{ pathname: '/login', search: currentQuery }}
                to={{ pathname: '/login' }}
                className="d-flex flex-column align-items-center"
                onClick={() => this.toggleSidebar(false)}
              >
                <span className="mdi mdi-36px mdi-account-circle-outline text-muted" />
                <span className="text-muted sidebar-login-text">Login</span>
              </Link>

              <Link
                className="create-account mdi mdi-36px"
                to="/register"
                onClick={() => this.toggleSidebar(false)}
              >
                Register for an account
              </Link>
            </div>
          )}
        </div>
        <div className={hasRelatedAccounts && 'mt-5'}>{sidebarOptions}</div>
      </Menu>
    )
  }
}

const ms2p = ({
  shopping: { categories },
  auth: { user, relatedAccounts },
  loader: { retrievingRelatedAccounts },
}) => {
  return { categories, user, relatedAccounts, retrievingRelatedAccounts }
}

export default connect(ms2p, { logout, selectSubCategory })(Paginate(Sidebar))
