import * as R from 'ramda'
import { Statsig, useGate } from 'statsig-react'
import { resolveReq, axios } from '../utils'
import {
  START_LOADER,
  STOP_LOADER,
  SET_CART_DETAILS,
  SET_QUANTITY_INPUT,
  EDIT_QUANTITY_INPUT,
  REMOVE_QUANTITY_INPUT,
  EDIT_CART_DETAIL,
  REMOVE_CART_DETAIL,
  SET_ORDER_SUMMARY,
  MERGE_DUPLICATES,
  SET_CART_LOADING,
  TOGGLE_CART_POPOVER,
  SET_SAVE_FOR_LATER,
  REMOVE_SAVE_DETAIL,
  CLEAR_CART_DETAILS,
  RESET_TAX,
  RESET_CONVENIENCE_FEE,
  CLEAR_CART_NOTIFICATIONS,
  HIDE_FOOTER,
  SHOW_FOOTER,
} from './types'

export const setCartDetails = () => {
  return async dispatch => {
    dispatch({ type: SET_CART_LOADING, payload: true })
    const { data, error } = await resolveReq(axios.get('/api/cart/cart/getCart'))
    // const allocationAdjustments = R.pathOr([], ['allocationAdjustments'], data)
    if (error) {
      dispatch({ type: SET_CART_LOADING, payload: false }) // eslint-disable-next-line no-console
      return console.error(error)
    }
    dispatch({ type: SET_CART_DETAILS, payload: data })
    dispatch({ type: SET_QUANTITY_INPUT })
    dispatch({ type: SET_CART_LOADING, payload: false })
    // if (!R.isEmpty(allocationAdjustments))
    //     return allocationAdjustments
  }
}

export const emptyCart = () => {
  return async dispatch => {
    // dispatch({ type: SET_CART_LOADING, payload: true })

    dispatch({ type: CLEAR_CART_DETAILS })
    const { error } = await resolveReq(axios.get('/api/cart/cart/emptyCart'))

    // if (error) {
    //   dispatch({ type: SET_CART_LOADING, payload: false }) // eslint-disable-next-line no-console
    //   return console.error(error)
    // }
  }
}

export const setSaveForLater = () => {
  return async dispatch => {
    dispatch({ type: SET_CART_LOADING, payload: true })
    const { data, error } = await resolveReq(axios.get('/api/cart/cart/getSaveForLater'))
    if (error) {
      dispatch({ type: SET_CART_LOADING, payload: false }) // eslint-disable-next-line no-console
      return console.error(error)
    }
    dispatch({ type: SET_SAVE_FOR_LATER, payload: data })
    dispatch({ type: SET_CART_LOADING, payload: false })
  }
}

export const clearCartDetails = () => {
  return { type: CLEAR_CART_DETAILS }
}

export const editQuantityInput = params => {
  return { type: EDIT_QUANTITY_INPUT, payload: params }
}

export const editCartDetail = params => {
  const { quantity, unit, id } = params
  return async dispatch => {
    dispatch({ type: SET_CART_LOADING, payload: true })
    const { data, error } = await resolveReq(
      axios.post('/api/cart/cart/editItem', { quantity, unit, detailId: id }),
    )
    if (error) {
      dispatch({ type: SET_CART_LOADING, payload: false }) // eslint-disable-next-line no-console
      return console.error(error)
    }
    await dispatch({ type: EDIT_CART_DETAIL, payload: params })
    if (unit) await dispatch({ type: MERGE_DUPLICATES, payload: params })
    dispatch({ type: SET_QUANTITY_INPUT })
    dispatch({ type: SET_ORDER_SUMMARY, payload: data })
    dispatch({ type: SET_CART_LOADING, payload: false })
  }
}

export const removeCartDetail = id => {
  return async dispatch => {
    dispatch({ type: SET_CART_LOADING, payload: true })
    const { data, error } = await resolveReq(
      axios.post('/api/cart/cart/removeItem', { detailId: id }),
    )
    if (error) {
      // eslint-disable-next-line no-console
      return console.error(error)
    }
    dispatch({ type: REMOVE_CART_DETAIL, payload: id })
    dispatch({ type: REMOVE_QUANTITY_INPUT, payload: id })
    dispatch({ type: SET_ORDER_SUMMARY, payload: data })
    dispatch({ type: SET_CART_LOADING, payload: false })
  }
}
export const removeSaveForLaterDetail = id => {
  return async dispatch => {
    const { error } = await resolveReq(axios.post('/api/cart/cart/deleteSaveForLater', { id }))
    if (error)
      // eslint-disable-next-line no-console
      return console.error(error)
    dispatch({ type: REMOVE_SAVE_DETAIL, payload: id })
  }
}

export const toggleCartPopover = () => {
  return { type: TOGGLE_CART_POPOVER }
}

export const resetCartTax = () => {
  return { type: RESET_TAX }
}

export const resetConvenienceFee = () => {
  return { type: RESET_CONVENIENCE_FEE }
}

export const moveToSaveForLater = item => {
  return async dispatch => {
    dispatch({ type: SET_CART_LOADING, payload: true })
    const { data, error } = await resolveReq(axios.post('/api/cart/cart/saveForLater', { item }))
    if (error) {
      // eslint-disable-next-line no-console
      return console.error(error)
    }
    const saveForLater = await resolveReq(axios.get('/api/cart/cart/getSaveForLater'))
    if (saveForLater.error) {
      dispatch({ type: SET_CART_LOADING, payload: false }) // eslint-disable-next-line no-console
      return console.error(error)
    }
    dispatch({ type: SET_SAVE_FOR_LATER, payload: saveForLater.data })
    dispatch({ type: SET_ORDER_SUMMARY, payload: data })
    dispatch({ type: REMOVE_QUANTITY_INPUT, payload: item.id })
    dispatch({ type: REMOVE_CART_DETAIL, payload: item.id })
    dispatch({ type: SET_CART_LOADING, payload: false })
  }
}

export const moveToCart = item => {
  const postedObj = R.pickBy(val => val, {
    id: item.id,
    itemId: item.itemId,
    unit: item.unit,
    quantity: item.quantity,
  })
  return async dispatch => {
    dispatch({ type: SET_CART_LOADING, payload: true })
    const { data, error } = await resolveReq(axios.post('/api/cart/cart/moveItemToCart', postedObj))
    if (error) {
      // eslint-disable-next-line no-console
      return console.error(error)
    }

    ////This indicates whether or not the add to cart amount had to be adjusted or cancelled because of allocation
    ////false means there was no adjustment and the full amount was added to cart
    const { addToCartAdjustment = false } = data

    dispatch({ type: REMOVE_SAVE_DETAIL, payload: item.id })
    dispatch({ type: SET_ORDER_SUMMARY, payload: data })
    dispatch({ type: SET_QUANTITY_INPUT })
    dispatch({ type: SET_CART_LOADING, payload: false })

    return { addToCartAdjustment }
  }
}

export const resetQuantityInput = () => {
  return { type: SET_QUANTITY_INPUT }
}

// export const clearCartNotifications = async () => {

// }

// export const clearCartNotifications = () => {
//     resolveReq(axios.post('/api/cart/cart/clearNotifications'))
//     return ({ type: CLEAR_CART_NOTIFICATIONS })
// }
export const clearCartNotifications = () => {
  return async dispatch => {
    dispatch({ type: CLEAR_CART_NOTIFICATIONS })
    const { error } = await resolveReq(axios.post('/api/cart/cart/clearNotifications'))
    if (error) {
      // eslint-disable-next-line no-console
      console.error(error)
    }
  }
}

export const hideFooterTop = () => {
  return { type: HIDE_FOOTER }
}

export const showFooterTop = () => {
  return { type: SHOW_FOOTER }
}

export const calculateTax = ({ addressId, items, shippingCost }) => {
  //items need to have the amount and the unitConversion
  return async dispatch => {
    dispatch({ type: START_LOADER, payload: 'calculateTaxLoading' })
    const { totalTax, taxRate, error } = await getTax({
      addressId,
      items,
      shippingCost,
    })
    if (error) {
      // eslint-disable-next-line no-console
      console.error(error)
      dispatch({ type: SET_CART_DETAILS, payload: { tax: 'error' } })
    } else dispatch({ type: SET_CART_DETAILS, payload: { tax: totalTax, taxRate } })

    dispatch({ type: STOP_LOADER, payload: 'calculateTaxLoading' })
  }
}

export const calculateConvenienceFee = ({
  applyConvenienceFee,
  subtotal,
  shippingCost,
  totalTax,
}) => {
  const total = applyConvenienceFee
    ? parseFloat(subtotal + (isNaN(shippingCost) ? 0 : shippingCost) + (totalTax || 0)).toFixed(2)
    : 0

  return async dispatch => {
    dispatch({ type: START_LOADER, payload: 'calculateConvenienceFeeLoading' })
    const { convenienceFee } = await getConvenienceFee({ total })
    if (typeof convenienceFee !== 'number') {
      dispatch({ type: SET_CART_DETAILS, payload: { convenienceFee: 'error' } })
    } else {
      dispatch({ type: SET_CART_DETAILS, payload: { convenienceFee: convenienceFee } })
      dispatch({ type: STOP_LOADER, payload: 'calculateConvenienceFeeLoading' })
    }
  }
}

export const getTax = async ({ addressId, items, shippingCost }) => {
  const itemIds = R.map(R.prop('itemId'), items)
  const { data, error } = await resolveReq(
    axios.post('/api/cart/cart/getTaxRate', { addressId, itemIds }),
  )

  const taxableItemsTotal = R.compose(
    R.sum,
    R.map(R.prop('total')),
    R.map(ti => {
      const unitConversion = Number(
        R.pathOr(1, ['conversionRate'], R.find(R.propEq(ti.unit, 'id'), ti.units || [])),
      )
      const total = Number(ti.price) * unitConversion * Number(ti.quantity)
      return { ...ti, total }
    }),
    R.filter(f => {
      return R.find(R.propEq(f.itemId, 'itemId'), data.items)
    }),
  )(items)

  const totalTax = (
    (Number(data.taxRate) / 100) *
    (taxableItemsTotal + (data.shippingTaxable ? shippingCost : 0))
  ).toFixed(2)

  // return { taxRate: data.taxRate, totalTax, convenienceFeeRate: data.convenienceFeeRate, error }
  return { taxRate: data.taxRate, totalTax }
}

export const getInvoiceConvenienceFee = async ({ applyConvenienceFee, invoiceTotal }) => {
  const total = applyConvenienceFee ? invoiceTotal : 0
  const { convenienceFee } = await getConvenienceFee({ total })
  return convenienceFee
}

export const getConvenienceFee = async ({ total }) => {
  const { data, error } = await resolveReq(
    axios.post('/api/cart/cart/getConvenienceFee', { total }),
  )

  return { convenienceFee: data.convenienceFeeTotal }
}

export const getConvenienceFeeRate = async () => {
  const { data, error } = await resolveReq(axios.get('/api/cart/cart/getConvenienceFeeRate'))

  return { convenienceFeeRate: data.convenienceFeeRate }
}

export const convFeeExempt = async () => {
  const { data, error } = await resolveReq(axios.get('/api/cart/cart/getConvFeeExempt'))
  return { convFeeExempt: data.convFeeExempt }
}
