import React, { Fragment } from 'react'
import { Row, Col } from 'reactstrap'
import { addDefaultSrc } from '../../utils'

export default props => {
  const { REACT_APP_STORAGE_URL, REACT_APP_CONT_PRODUCT_IMG, PUBLIC_URL } = process.env
  const IMAGE_URL = REACT_APP_STORAGE_URL + REACT_APP_CONT_PRODUCT_IMG

  const screenWidth = window.innerWidth

  const renderImage = () => {
    if (screenWidth > 450)
      return (
        <Col xs="2" className="searchbar-result-image-col">
          <img
            onError={addDefaultSrc}
            src={
              props.thumbnailImage
                ? `${IMAGE_URL}${props.thumbnailImage}`
                : `${PUBLIC_URL}/images/imageComingSoon.png`
            }
            alt="thumbnail"
            loading="lazy"
          />
        </Col>
      )
  }

  return (
    <Fragment>
      <Row className="text-image-row">
        {renderImage()}
        <Col xs="10" className="suggestion-text">
          {props.itemName}
        </Col>
      </Row>
    </Fragment>
  )
}
