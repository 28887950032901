import React, { useEffect, useRef, useState, useContext } from 'react'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { Link } from 'react-router-dom'
import { Swiper, SwiperSlide } from 'swiper/react'
import QuickOrderItemCard from '../QuickOrder/QuickOrderItemCard'
import { Pagination, Navigation } from 'swiper'
import '../Content/content.scss'
import 'swiper/swiper-bundle.min.css'
import Slider from 'react-slick'
import 'slick-carousel/slick/slick.css'
import 'slick-carousel/slick/slick-theme.css'
import { faArrowRight, faArrowLeft } from '@fortawesome/fontawesome-free-solid'
import { ShoppingContext } from '../../shared/ShoppingContext'
import { mixpanelLogEvent } from '../../utils/mixpanelLogEvent'

const CategoryDisplay = ({ key, category }) => {
  const { userInfo } = useContext(ShoppingContext)

  return (
    <Link to={`/category/${category.urlComponent}`} style={{ color: 'black' }}>
      <div
        className="category-main-item"
        key={key}
        style={{
          display: 'flex',
          flexDirection: 'column',
          justifyContent: 'center',
          alignItems: 'center',
          position: 'relative',
        }}
        onClick={() =>
          mixpanelLogEvent({
            eventType: `Homepage_Category_Click`,
            userDetails: userInfo,
            metaData: { category: category.name },
          })
        }
      >
        <img
          src={category.imageUrl}
          style={{ width: '100%', borderRadius: '24px', height: '100%', objectFit: 'cover' }}
        />
        <div
          style={{
            display: 'flex',
            position: 'absolute',
            bottom: '16px',
            left: '16px',
            width: 'calc(100% - 32px)',
            justifyContent: 'space-between',
            alignItems: 'center',
          }}
        >
          <p style={{ textAlign: 'left', fontSize: '16px' }}>{category.name}</p>
          <FontAwesomeIcon icon={faArrowRight} style={{ fontSize: '12px', display: 'none' }} />
        </div>
      </div>
    </Link>
  )
}

function EnhancedHomepageSlider({
  items = [],
  enhancedOrdering,
  categories = [],
  isAuthenticated,
  sectionTitle,
  isModal = false,
}) {
  function CustomNextArrow(props) {
    const { onClick } = props
    return (
      <div className="custom-slick-next" onClick={onClick}>
        <FontAwesomeIcon icon={faArrowRight} className="slider-arrow" />
      </div>
    )
  }

  function CustomPrevArrow(props) {
    const { onClick } = props
    return (
      <div className="custom-slick-prev" onClick={onClick}>
        <FontAwesomeIcon icon={faArrowLeft} className="slider-arrow" />
      </div>
    )
  }

  const isCategoryCarousel = categories?.length > 0

  const settings = {
    infinite: false,
    speed: 500,
    slidesToShow: isModal ? 3 : isCategoryCarousel ? 8 : 8,
    slidesToScroll: 1,
    initialSlide: 0,
    nextArrow: <CustomNextArrow />,
    prevArrow: <CustomPrevArrow />,
    responsive: [
      {
        breakpoint: 2500,
        settings: { slidesToShow: isModal ? 3 : isCategoryCarousel ? 8 : 8 },
      },
      {
        breakpoint: 2000,
        settings: { slidesToShow: isModal ? 3 : isCategoryCarousel ? 7 : 7 },
      },
      {
        breakpoint: 1600,
        settings: { slidesToShow: isModal ? 3 : isCategoryCarousel ? 6 : 6 },
      },
      {
        breakpoint: 1440,
        settings: { slidesToShow: isModal ? 3 : isCategoryCarousel ? 5 : 5 },
      },
      {
        breakpoint: 1080,
        settings: { slidesToShow: isModal ? 3 : isCategoryCarousel ? 4 : 4 },
      },
      {
        breakpoint: 900,
        settings: { slidesToShow: isCategoryCarousel ? 3 : 3 },
      },
      {
        breakpoint: 767,
        settings: { slidesToShow: isCategoryCarousel ? 4 : 4 },
      },
      {
        breakpoint: 650,
        settings: { slidesToShow: isCategoryCarousel ? 3 : 3 },
      },
      {
        breakpoint: 500,
        settings: { slidesToShow: isCategoryCarousel ? 2 : 2 },
      },
      {
        breakpoint: 320,
        settings: { slidesToShow: isCategoryCarousel ? 2 : 2 },
      },
    ],
  }

  return (
    <div
      style={
        isModal ? null : { display: 'flex', justifyContent: 'flex-start', alignItems: 'center' }
      }
    >
      <div
        className={isModal ? 'dealmed-popover-upsell-modal-wrapper' : 'moe__right'}
        style={{ width: '100%' }}
      >
        <div className={isModal ? '' : 'moe__slider'}>
          <Slider {...settings}>
            {isCategoryCarousel
              ? categories.map((category, index) => (
                  <div key={index}>
                    <CategoryDisplay category={category} />
                  </div>
                ))
              : items.map((item, index) => (
                  <div
                    key={item?.displayName || index}
                    style={isModal ? { maxWidth: '278px' } : null}
                  >
                    <QuickOrderItemCard
                      product={item}
                      enhancedOrdering={enhancedOrdering}
                      isAuthenticated={isAuthenticated}
                      sectionTitle={sectionTitle}
                      isModal={isModal}
                    />
                  </div>
                ))}
          </Slider>
        </div>
      </div>
    </div>
  )
}

export default EnhancedHomepageSlider
