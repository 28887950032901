import React, { Component } from 'react'
import './Navbar.scss'
import * as R from 'ramda'
import { Link } from 'react-router-dom'
import { connect } from 'react-redux'
import { Row, Col, Popover } from 'reactstrap'
import RelatedAccountsDropdown from '../Reusable/RelatedAccountsDropdown'
import MyAccountHOC from '../Reusable/MyAccountHOC'
import InitializeHOC from '../Reusable/InitializeHOC'
import { logout, switchAccount } from '../../actions'

const dropdownList = [
  {
    header: 'PURCHASES',
    items: [
      { name: 'SALES ORDERS', link: '/salesorders', permission: 'isPriceVisible' },
      { name: 'QUICK ORDER', link: '/reorder' },
      { name: 'APPROVE ORDERS', link: '/approvals', permission: 'isAdmin' },
      { name: 'MY LISTS', link: '/itemlists' },

      // { name: 'QUOTES', link: '/quotes' }
    ],
  },
  {
    header: 'BILLING',
    items: [
      /*{ name: 'ACCOUNT BALANCE', link: '/accountbalance' }, */ {
        name: 'MAKE PAYMENT',
        link: '/payment/create',
        permission: 'isPriceVisible',
      },
      { name: 'INVOICES', link: '/invoices', permission: 'isPriceVisible' },
      { name: 'PAYMENT HISTORY', link: '/paymenthistory' },
      { name: 'PAYMENT METHODS', link: '/paymentmethod' },
    ],
  },
  // {
  //     header: 'SETTINGS',
  //     items: [
  //         // { name: 'PROFILE INFO', link: '/' },
  //         // { name: 'EMAIL PREFERENCES', link: '/emailprefs' },
  //         { name: 'SECURITY', link: '/security' }]
  //     //   { name: 'UPDATE PASSWORD', link: '/' }]
  // },
  {
    header: 'COMPANY',
    items: [
      { name: 'PROFILE', link: '/profile' },
      { name: 'DEALMED DELIVERY DAY', link: '/dealmeddeliveryday' },
      { name: 'ADDRESSES', link: '/addresses' },
      { permission: 'isAdmin', name: 'CONTACTS', link: '/contacts' },
      { permission: 'isAdmin', name: 'SETTINGS', link: '/accountsettings' },
    ],
  },
]

class LoginButton extends Component {
  state = {
    viewIsOpen: false,
  }

  componentDidMount() {
    document.addEventListener('click', this.handleClickOutside, true)
  }

  handleButtonClick = () => {
    this.props.auth.user && this.showDropdown()
  }

  showDropdown = () => this.setState({ viewIsOpen: true })

  hideDropdown = () => this.setState({ viewIsOpen: false })

  logOut = () => {
    this.props.logout()
    this.props.cleanupOnLogout()
    this.hideDropdown()
  }

  handleClickOutside = e => {
    if (this.node && !this.node.contains(e.target)) {
      this.hideDropdown()
    }
  }

  render() {
    ///using he window location pathname and search rather than react-router because this is on the navbar
    ///and the navbar is on all the components so prop won't get updated
    const currentPath = R.pathOr('', ['location', 'pathname'], window)
      .substr(1)
      .replace('login/', '')
    const currentQuery = R.pathOr('', ['location', 'search'], window)

    const loggedinDropdownColumns = dropdownList.map((c, i) => {
      let items = c.items
        .filter(i => !i.permission || this.props.hasPermission(i.permission))
        .map((item, index) => (
          <Link
            to={`/myaccount/menu${item.link}`}
            key={`items${index}`}
            className="no-underline"
            onClick={() => this.hideDropdown()}
          >
            <div className="login-dropdown-sub-item">{item.name}</div>
          </Link>
        ))

      return (
        <div className="col" key={`loggedin${i}`}>
          <header className="login-dropdown-header">{c.header}</header>
          {items}
        </div>
      )
    })

    const user = this.props.auth.user
    const { retrievingRelatedAccounts = false } = this.props
    const hasRelatedAccounts = R.pathOr([], ['auth', 'relatedAccounts'], this.props).length > 1
    return (
      <>
        {/* // <button type="button" className='btn btn-primary mr-1' onClick={() => this.handleButtonClick()}>{this.props.auth.user.firstName || "My Account"}</button> */}
        <Link
          style={{ textDecoration: 'none' }}
          id="myaccount-btn"
          className="tab"
          to="/myaccount"
          onFocus={() => {}}
          onMouseOver={() => this.handleButtonClick()}
        >
          <div className="main-nav-dropdown">
            {user ? (
              <>
                Dealmed Runtime&#8482; Account <i className="mdi mdi-chevron-down" />
              </>
            ) : (
              'Login/Register'
            )}
          </div>
        </Link>

        {/* //ref={node => this.node = node}
                // className={classnames('nav-dropdown-list container', {
                //     'show-dropdown': this.state.viewIsOpen
                // })} */}

        <Popover
          className="d-none d-lg-block nav-popover"
          target="myaccount-btn"
          isOpen={this.state.viewIsOpen}
          toggle={this.hideDropdown}
          trigger="hover"
          placement="bottom"
          delay={150}
          style={{ width: '340px', paddingBottom: '82px' }}
        >
          <Row className="mt-3 d-flex">
            <Col sm="8">
              {/* <div className='row login-dropdown-top-row'> */}
              <Link
                className="login-dropdown-top-row-item ml-1"
                to="/myaccount"
                onClick={() => this.hideDropdown()}
              >
                Dealmed Runtime&#8482; Account
              </Link>
            </Col>
            <Col sm="4">
              <div
                className="login-dropdown-sign-out text-muted text-right"
                onClick={() => this.logOut()}
              >
                Sign out
              </div>
            </Col>
          </Row>
          {retrievingRelatedAccounts && (
            <Row className="mt-3">
              <Col sm="12">
                Retrieving account info...
                <span className="mdi mdi-loading mdi-24px mdi-spin" />
              </Col>
            </Row>
          )}
          {!retrievingRelatedAccounts && hasRelatedAccounts && (
            <Row className="mt-3">
              <Col sm="12">
                <RelatedAccountsDropdown isSearchable />
              </Col>
            </Row>
          )}

          <Row className="mt-4">
            <Col>{loggedinDropdownColumns}</Col>
          </Row>
        </Popover>
      </>
    )
  }
}

const mapStateToProps = state => {
  return {
    auth: state.auth,
    retrievingRelatedAccounts: R.pathOr(false, ['loader', 'retrievingRelatedAccounts'], state),
  }
}

export default connect(mapStateToProps, { logout, switchAccount })(
  MyAccountHOC(InitializeHOC(LoginButton)),
)
