import React, { Component } from 'react'
import { connect } from 'react-redux'
import { pathOr, omit } from 'ramda'
import {
  setSalesOrder,
  setSalesordersFilters,
  setEstimate,
  setEstimatesFilters,
  setSalesordersAddressList,
  setInvoice,
  setShipment,
  generateSalesOrderPDF,
  addToCart,
  setReorderDateOption,
  clearAll,
  setInvoiceFilters,
  setSelectedHistoryInvoice,
  setStatusList,
  getSetInvoiceById,
  setSelectedHistoryPayment,
  setPaymentFilters,
  getSetPaymentById,
  setReorderFromOption,
  upsertSubscription,
  deleteSubscription,
  getSubscriptionAddressPaymentDetails,
} from '../../actions'

import Toastify from './Toastify'

const TheTransactionHOC = ComposedComponent => {
  // eslint-disable-next-line react/prefer-stateless-function
  class TransactionHOC extends Component {
    validateRxAndAddToCart = async (
      productObj,
      quantity,
      uomValue,
      searchTerm = '',
      queryID = '',
    ) => {
      const inactive = pathOr(false, ['inactive'], productObj)
      const restricted = pathOr(false, ['restricted'], productObj)

      const id = pathOr('', ['id'], productObj)

      if (inactive || restricted)
        // eslint-disable-next-line no-console
        return console.error(`Item ${id} is not currently active`)

      const validatedRxObj = this.validateRx(productObj)
      if (!validatedRxObj.isValidRxLevel || !validatedRxObj.isSellableCSL) {
        // eslint-disable-next-line no-console
        return console.error('Invalid Rx License for this item. Cannot add to cart')
      }

      // if (isNaN(Number(productObj.price))) {
      //     return console.error('Invalid price. Cannot add to cart')
      // }

      const validQuantity = quantity >= 1 ? Math.floor(Number(quantity)) : 1
      const addToCartResponse = await this.props.addToCart(
        productObj,
        validQuantity,
        uomValue,
        searchTerm,
        queryID,
      )
      const addToCartAdjustment = pathOr(false, ['addToCartAdjustment'], addToCartResponse)

      if (addToCartAdjustment) {
        this.props.toastify({
          message: 'Item quantites are limited and has been adjusted in your cart',
          type: 'warning',
        })
        return false
      }

      return true
    }

    validateRx = productObj => {
      const rxLevels = pathOr({}, ['auth', 'user', 'account', 'rxLevels'], this.props)

      ////returning an object with boolean values as opposed to returning an actual boolean
      ////to make it reusable for wheather or not display add to cart button or call for cls levels
      return {
        isValidRxLevel: productObj.rxLevel ? Boolean(rxLevels[productObj.rxLevel]) : true,
        isSellableCSL: productObj.rxLevel
          ? Boolean(this.props.sellableCSLs[productObj.rxLevel])
          : true,
      }
    }

    render() {
      const props = {
        validateRxAndAddToCart: this.validateRxAndAddToCart,
        validateRx: this.validateRx,
      }

      ///Returning all the props, omitting addToCart because only validateRxAndAddToCart
      //should be used by the front end except for in the above function validateRxAndAddToCart
      return <ComposedComponent {...{ ...omit(['addToCart'], this.props), ...props }} />
    }
  }

  const mapStateToProps = state => {
    return {
      auth: state.auth,
      sellableCSLs: state.shopping.sellableCSLs,
      ...state.transaction,
      so: state.transaction.selectedSalesOrder,
      estimate: state.transaction.selectedEstimate,
      salesOrdersFilters: state.transaction.salesOrdersFilters,
      estimateFilters: state.transaction.estimateFilters,
      invoiceHistory: state.transaction.invoiceHistory,
      salesOrdersAddressList: state.transaction.salesOrdersAddressList,
      invoice: state.transaction.selectedInvoice,
      shipment: state.transaction.selectedShipment,
      viewer: state.transaction.viewer,
      statusList: state.transaction.statusList,
      statusFilter: state.transaction.salesOrdersFilters.status,
    }
  }

  return connect(mapStateToProps, {
    setSalesOrder,
    setSalesordersFilters,
    setEstimate,
    setEstimatesFilters,
    setReorderDateOption,
    setSalesordersAddressList,
    setInvoice,
    setShipment,
    generateSalesOrderPDF,
    addToCart,
    clearAll,
    setInvoiceFilters,
    setStatusList,
    setSelectedHistoryInvoice,
    getSetInvoiceById,
    setSelectedHistoryPayment,
    setPaymentFilters,
    getSetPaymentById,
    setReorderFromOption,
    upsertSubscription,
    deleteSubscription,
    getSubscriptionAddressPaymentDetails,
  })(Toastify(TransactionHOC))
}

export default TheTransactionHOC
