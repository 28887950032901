import React, { Component } from 'react'
import { Link, NavLink } from 'react-router-dom'
import './Navbar.scss'

class Dropdown extends Component {
  state = { show: false }
  render() {
    let showList = () => {
      this.setState({ show: true })
    }

    let hideList = () => {
      this.setState({ show: false })
    }

    ////Prevent the click on white space padding of the dropdown
    const ignoreClick = e => {
      e.preventDefault()
    }

    return (
      <div
        className="border-left flex-fill tab"
        onMouseEnter={() => showList()}
        onMouseLeave={() => hideList()}
      >
        <span>
          {this.props.navItemName} <i className="mdi mdi-chevron-down" />
        </span>
        <div
          className={`drop-down ${this.state.show ? 'show' : ''}`}
          id="header-drop-down-box"
          onClick={ignoreClick}
        >
          {this.props.content.map(c => {
            return (
              <Link key={c.text} to={`/${c.route}`} className="link">
                <div className="drop-down-link">{c.text}</div>
              </Link>
            )
          })}
        </div>
      </div>
    )
  }
}

export default Dropdown
