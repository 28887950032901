import React from 'react'
import { connect } from 'react-redux'
import { withRouter } from 'react-router-dom'
import ReactSelect from 'react-select'
import { pathOr, find, propEq } from 'ramda'
import { switchAccount } from '../../actions'
import Toastify from '../Reusable/Toastify'

const RelatedAccountsDropdown = props => {
  // Saving which is the selectedRelated account in redux is no longer needed since the functionality changed and the logged
  // in account actually changes in redux when changing to a different subaccount. It is only used here.
  // Nevertheless it is still being used here instead of just looking at the logged in account in case any changes are wanted it will be easier to implement
  const selectedRelatedAccount = pathOr({}, ['auth', 'selectedRelatedAccount'], props)
  const { id = '' } = selectedRelatedAccount

  const relatedAccounts = pathOr([], ['auth', 'relatedAccounts'], props)
  const relatedAccountsOptions = relatedAccounts.map(a => {
    var label = a.id == a.parentId ? `Parent Account: ${a.accountName}` : a.accountName || ''
    return { value: a.id || '', label }
  })
  // const relatedAccountsOptionsParent = relatedAccountsOptions.map(account => account.id == account.parentId ? `Parent Account: ${account.accountName}` )
  const selectedOption = find(propEq(id, 'value'))(relatedAccountsOptions)

  const selectAccount = async val => {
    if (val === selectedOption.value) return

    // const selectedAccount = find(propEq(val,'id'))(relatedAccounts)
    const { error, success } = await props.switchAccount(val)
    if (error)
      return props.toastify({ message: 'There was a problem changing accounts', type: 'error' })
    if (success) window.location.href = '/myaccount'
  }

  return (
    <>
      <label>Change Account:</label>
      <ReactSelect
        options={relatedAccountsOptions}
        isSearchable={Boolean(props.isSearchable) || false}
        onChange={e => {
          selectAccount(e.value)
        }}
        value={selectedOption}
        defaultValue={selectedOption}
      />
    </>
  )
}

const mapStateToProps = ({ auth, loader }) => {
  return { auth, loader }
}

export default withRouter(
  connect(mapStateToProps, { switchAccount })(Toastify(RelatedAccountsDropdown)),
)
