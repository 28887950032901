import React, { useState, useEffect } from 'react'
import { Modal, ModalBody, ModalHeader, Row, Col } from 'reactstrap'
import moment from 'moment'
import { isIE, isIOS } from 'react-device-detect'

const { PUBLIC_URL } = process.env

const BrowserSupport = () => {
  const [modalOpen, setmodalOpen] = useState(false)

  useEffect(() => {
    const exp = localStorage.getItem('showBrowserSupport') || 1

    if (isIE && exp < moment().unix()) setmodalOpen(true)
  }, [])

  const toggle = () => {
    localStorage.setItem('showBrowserSupport', moment().add('3', 'days').unix())
    setmodalOpen(!modalOpen)
  }

  return (
    <Modal
      size="xl"
      isOpen={modalOpen}
      className="colored-header-primary"
      toggle={toggle}
      zIndex={6001}
    >
      <ModalHeader toggle={toggle}>
        <p className="text-center">Supported Browsers</p>
      </ModalHeader>
      <ModalBody className="p-3 text-center">
        <p>We do our best to support all browsers.</p>
        <p>
          <strong>For the best expierience, please use one of the recommended browsers</strong>
        </p>
        <Row className="mt-5 d-flex justify-content-center">
          <Col sm="3">
            <a href="https://www.google.com/chrome/">
              {' '}
              <img
                src={`${PUBLIC_URL}/images/chrome.png`}
                alt="na"
                style={{ maxHeight: '50px' }}
                loading="lazy"
              />
            </a>
            <br />
            <a href="https://www.google.com/chrome/">Chrome (Recommended)</a>
          </Col>
          <Col sm="3">
            <a href="https://www.mozilla.org/en-US/firefox/?v=a">
              {' '}
              <img
                src={`${PUBLIC_URL}/images/firefox.png`}
                alt="na"
                style={{ maxHeight: '50px' }}
                loading="lazy"
              />
            </a>
            <br />
            <a href="https://www.mozilla.org/en-US/firefox/?v=a">Firefox</a>
          </Col>
          <Col sm="3">
            <a href="https://www.microsoft.com/en-us/windows/microsoft-edge">
              {' '}
              <img
                src={`${PUBLIC_URL}/images/edge.png`}
                alt="na"
                style={{ maxHeight: '50px' }}
                loading="lazy"
              />
            </a>
            <br />
            <a href="https://www.microsoft.com/en-us/windows/microsoft-edge">Edge</a>
          </Col>
          {isIOS && (
            <Col sm="3">
              <a href="https://support.apple.com/downloads/safari">
                {' '}
                <img
                  src={`${PUBLIC_URL}/images/safari.png`}
                  alt="na"
                  style={{ maxHeight: '50px' }}
                  loading="lazy"
                />
              </a>
              <br />
              <a href="https://support.apple.com/downloads/safari">Safari</a>
            </Col>
          )}
        </Row>
      </ModalBody>
    </Modal>
  )
}

export default BrowserSupport
