import React from 'react'
import * as R from 'ramda'
import { withRouter } from 'react-router-dom'
import { connect } from 'react-redux'
import Downshift from 'downshift'
import { isNil, pathOr } from 'ramda'
import { getUrlComponent } from '../../utils'
import SearchBarResult from './SearchBarResult'
import ProductHOC from '../Reusable/ProductHOC'
import { resolveReq, axios } from '../../utils/api'
const aa = require('search-insights')

const SearchBar = (props, userId, selectedSortOption, queryID, objectID, algoliaIndex) => {
  const getClickEventObject = id => {
    const option = props.options.filter(o => o.objectID == id)[0]
    return {
      userToken: props.auth.user ? String(props.auth.user.id) : '',
      eventName: 'Product Clicked',
      index: 'itemsIndex',
      queryID: option.queryID,
      objectIDs: option.objectID ? [option.objectID] : [],
      positions: [1],
    }
  }

  const redirectToItem = ({ id, urlComponent }) => {
    const clickEventObject = getClickEventObject(id)
    props.resetProduct()
    props.onChange('')
    props.history.push(`/product/${getUrlComponent({ id, urlComponent })}`)
    props.setProduct(id, false)
    const newRedirect = `/product/${getUrlComponent({ id, urlComponent })}`
    const passesValidation =
      !R.isEmpty(clickEventObject) && props.auth.user && newRedirect == props.location.pathname
    if (passesValidation) {
      try {
        aa('clickedObjectIDsAfterSearch', clickEventObject)
        console.log('clickevent', clickEventObject)
      } catch (e) {
        console.log(e)
      }
      //Remove Algolia Tracking Local Storage Variables
      var arr = []
      for (var i = 0; i < localStorage.length; i++) {
        if (localStorage.key(i).substring(0, 9) == 'itemclick') {
          arr.push(localStorage.key(i))
        }
      }
      for (var i = 0; i < arr.length; i++) {
        localStorage.removeItem(arr[i])
      }

      localStorage.setItem('itemclick_' + id, JSON.stringify(clickEventObject))
    }
  }

  const renderMenuItem = item => (
    <div onClick={() => redirectToItem(item)}>
      <SearchBarResult
        thumbnailImage={item.thumbnailImage}
        itemName={item.itemName + ', ' + (item.siteDisplayName || item.displayName)}
      />
    </div>
  )

  const executeSearch = async closeMenu => {
    props.onClick()
    if (closeMenu && typeof closeMenu === 'function') closeMenu()
  }

  const { placeholder, options, typeAheadResultsLimit = 5, onChange, value } = props

  return (
    <>
      <Downshift
        onSelect={redirectToItem}
        inputValue={value}
        itemToString={item => (item ? item.value : '')}
        onInputValueChange={inputValue => inputValue !== undefined && onChange(inputValue)}
      >
        {({
          getInputProps,
          getItemProps,
          getMenuProps,
          isOpen,
          highlightedIndex,
          setHighlightedIndex,
          selectedItem,
          closeMenu,
        }) => (
          <div>
            <div style={{ position: 'relative' }}>
              <input
                {...getInputProps({
                  placeholder,
                  className: 'searchbar-input',
                  onKeyDown: e => {
                    if (e.key === 'Enter' && isNil(highlightedIndex)) executeSearch(closeMenu)
                  },
                })}
              />
              <span onClick={() => executeSearch(closeMenu)} className="search-icon">
                <span className="mdi mdi-magnify mdi-24px" />
              </span>
            </div>
            {isOpen && options.length ? (
              <ul
                className="rbt-menu"
                {...getMenuProps()}
                {...getInputProps({ onMouseLeave: () => setHighlightedIndex(null) })}
              >
                {options
                  .map((item, index) => (
                    <li
                      key={index}
                      className="cursor-hover"
                      {...getItemProps({
                        index,
                        item,
                        style: {
                          backgroundColor: highlightedIndex === index ? '#F0F0F0' : 'white',
                          fontWeight: selectedItem === item ? 'bold' : 'normal',
                        },
                      })}
                    >
                      {renderMenuItem(item)}
                    </li>
                  ))
                  .concat(
                    options.length === typeAheadResultsLimit
                      ? [
                          <li
                            key={options.length}
                            style={{
                              backgroundColor: 'white',
                            }}
                          >
                            <div
                              onClick={() => {
                                executeSearch(closeMenu)
                              }}
                            >
                              <div className="searchbar-all-results">See All Results</div>
                            </div>
                          </li>,
                        ]
                      : [],
                  )}
              </ul>
            ) : null}
          </div>
        )}
      </Downshift>
    </>
  )
}
const mapStateToProps = state => {
  return {
    auth: state.auth,
  }
}

export default withRouter(connect(mapStateToProps, null)(ProductHOC(SearchBar)))
