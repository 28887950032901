import { curry } from 'ramda'
import { resolveReq, axios } from '../utils'

export const getRecord = ({ id }, { route, type }) => {
  return async dispatch => {
    dispatch({ type, payload: { isLoading: true } })
    const { data, error } = await resolveReq(axios.get(`/api${route}/` + id))

    if (error) {
      dispatch({ type, payload: { isLoading: false, message: error.message } })
      return console.log(error)
    }

    dispatch({ type, payload: { isLoading: false, record: data } })
  }
}

export const createRecord = (data, { route, type }) => {
  return async dispatch => {
    dispatch({ type, payload: { isLoading: true } })
    const { error } = await resolveReq(axios.post(`/api/${route}/create`, data))

    if (error) {
      dispatch({ type, payload: { isLoading: false, message: error.message } })
      return console.log(error)
    }

    dispatch({ type, payload: { isLoading: false, modal: false, message: '' } })
  }
}

export const editRecord = (data, { route, type }) => {
  return async dispatch => {
    dispatch({ type, payload: { isLoading: true } })
    const { error } = await resolveReq(axios.patch(`/api/${route}/edit`, data))

    if (error) {
      dispatch({ type, payload: { isLoading: false, message: error.message } })
      return console.log(error)
    }

    dispatch({ type, payload: { isLoading: false, modal: false, message: '' } })
  }
}

export const deleteRecord = (id, { route, type }) => {
  return async dispatch => {
    dispatch({ type, payload: { isLoading: true } })

    const { error } = await resolveReq(axios.post(`/api/${route}/delete`, { id }))

    if (error) {
      dispatch({ type, payload: { isLoading: false, message: error.message } })
      return console.log(error)
    }

    dispatch({ type, payload: { isLoading: false, modal: false, message: '' } })
  }
}

export const setRecord = (record, { type }) => ({ type, payload: { record } })

export const setCRUDProps = curry((type, payload) => {
  return { type, payload }
})
