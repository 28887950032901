import React, { useEffect, useMemo, useState, Suspense } from 'react'
import { BrowserRouter } from 'react-router-dom'
import { Container } from 'reactstrap'
import Router from './Router'
import BrowserSupport from '../BrowserSupport/BrowserSupport'
import InitHOC from '../Reusable/InitializeHOC'
import Navbar from '../Navbar/Navbar'
import Header from '../Navbar/Header'
import CheckoutNavbar from '../Navbar/CheckoutNavbar'
// import Footer from '../Footer/Footer';
import OverlayLoaders from '../Widgets/OverlayLoaders/OverlayLoaders'
import SiteBanner from '../SiteBanner/SiteBanner'
import { getAPIStatus } from '../../utils'
import '../../assets/CSS/_globals.scss'
//import './LiveChatInit';
import '../../styles/app.scss'
import { useContentful } from '../../shared/contentful/useContentful'
import { ContentfulContext } from '../../shared/ContentfulContext'
import { StatsigProvider } from 'statsig-react'
import * as Sentry from '@sentry/react'
import ShoppingContextProvider from '../../shared/ShoppingContextProvider'
import { initializeAlgoliaInsights } from '../../utils/initializeAlgoliaInsights'

const aa = require('search-insights')
const LazyFooter = React.lazy(() => import('../Footer/Footer'))

const App = props => {
  const { getWebsiteData } = useContentful()
  const [websiteData, setWebsiteData] = useState([])

  const fetchWebsiteData = async () => {
    const data = await getWebsiteData()
    setWebsiteData(data.items[0].fields.websiteContentData)
  }
  const footerData = websiteData.filter(x => x.sys.contentType.sys.id == 'footer')[0]?.fields

  useEffect(() => {
    if (props.isValidToken) props.setCurrentUser()
    else props.setAccountLoadingFalse()
    //Check if API is working
    //Redirect to error page if broken
    getAPIStatus().then(() => {})
    fetchWebsiteData()
    initializeAlgoliaInsights()
  }, [])

  useEffect(() => {
    const { isAuthenticated, initAuthData, initNonAuthData } = props
    const { REACT_APP_ALGOLIA_API_KEY } = process.env

    if (isAuthenticated) initAuthData()
    initNonAuthData()
  })

  if (websiteData.length === 0) {
    return <div></div>
  }

  return (
    <StatsigProvider
      sdkKey={process.env.REACT_APP_STATSIG_CLIENT_KEY}
      user={{
        userID: props.isAuthenticated,
        email: props.userEmail,
      }}
      waitForInitialization={true}
    >
      <ShoppingContextProvider>
        <BrowserRouter>
          <>
            <div id="content-wrap">
              <BrowserSupport />
              <OverlayLoaders />

              {/* <SiteBanner /> */}
              <ContentfulContext.Provider value={websiteData}>
                <Container fluid id="container-wrap">
                  {!props.hideNavbar ? <Header /> : <CheckoutNavbar />}
                  <main className="site-main" id="main-content" style={{ minHeight: '100vh' }}>
                    <Router />
                  </main>
                  <Suspense fallback={<div>Loading Footer...</div>}>
                    <LazyFooter footerData={footerData} showTop={false} />
                  </Suspense>
                </Container>
              </ContentfulContext.Provider>
            </div>
          </>
        </BrowserRouter>
      </ShoppingContextProvider>
    </StatsigProvider>
  )
}

export default Sentry.withProfiler(InitHOC(App))
