import React from 'react'
import classnames from 'classnames'

const EnvelopeSVG = ({ className }) => {
  return (
    <svg
      viewBox="0 0 32 32"
      className={classnames('dealmed-icon dealmed-envelope-icon', className)}
      aria-hidden={true}
      focusable={false}
    >
      <path d="M22.933 9.6h-13.867c-0.884 0-1.6 0.716-1.6 1.6v9.6c0 0.884 0.716 1.6 1.6 1.6h13.867c0.884 0 1.6-0.716 1.6-1.6v-9.6c0-0.884-0.716-1.6-1.6-1.6zM22.933 11.2v1.36c-0.747 0.609-1.939 1.555-4.486 3.55-0.561 0.442-1.673 1.502-2.447 1.49-0.774 0.012-1.886-1.049-2.447-1.49-2.547-1.994-3.739-2.941-4.486-3.55v-1.36h13.867zM9.067 20.8v-6.187c0.764 0.608 1.847 1.462 3.498 2.755 0.729 0.573 2.004 1.84 3.435 1.832 1.424 0.008 2.684-1.24 3.435-1.832 1.651-1.293 2.734-2.147 3.498-2.755v6.187h-13.867z"></path>
    </svg>
  )
}

export default EnvelopeSVG
