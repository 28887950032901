import React from 'react'
import DealmedListbox from '../../shared/components/DealmedListbox'

const ProductDetailsQuantityTypeDropdown = ({ id, units, updateUnits, value }) => {
  const defaultUnit = units[0].value.toString()

  const handleChange = newValue => {
    units.forEach(unit => {
      if (unit.value === parseInt(newValue)) {
        updateUnits(unit)
      }
    })
  }

  const listboxOptions = units.map(units => {
    return {
      text: units.label,
      value: units.value.toString(),
      disabled: false,
    }
  })
  return (
    <div className="product-details__qty-type">
      <span id={id} className="visually-hidden">
        Select Your Quantity Type
      </span>
      <DealmedListbox
        arrow={false}
        options={listboxOptions}
        value={value && value.value ? value.value.toString() : defaultUnit}
        // value={value && value.value ? value.value.toString() : defaultUnit}
        aria-labelledby={id}
        className="product-details__qty-listbox"
        onChange={handleChange}
      />
    </div>
  )
}

{
  /* <div className="prdi__inputs">
                <div className="prdi__inputs-block">
                  <span className="prdi__inputs-label">Size</span>
                  <select className="nice-select prdi__inputs-select">
                    <option>Each</option>
                    <option>2</option>
                    <option>3</option>
                  </select>
                </div> */
}

export default ProductDetailsQuantityTypeDropdown
