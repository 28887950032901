import React from 'react'
import { documentToReactComponents } from '@contentful/rich-text-react-renderer'

function AlertBanner({ alertBannerData }) {
  const { text } = alertBannerData
  const options = {
    renderText: text => {
      return text.split('\n').reduce((children, textSegment, index) => {
        return [...children, index > 0 && <br key={index} />, textSegment]
      }, [])
    },
  }

  return (
    <div className="dealmed-header__info-alertbanner">
      <span>{documentToReactComponents(text, options)}</span>
      {/* <span className="dealmed-header__info-alertbanner-link">Prebook Now</span> */}
    </div>
  )
}

export default AlertBanner
