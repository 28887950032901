import React, { Component } from 'react'
import { isEmpty } from 'ramda'
import InputFloat from 'react-floating-input'
import classnames from 'classnames'
import { Row, Col, Dropdown, DropdownToggle, DropdownMenu } from 'reactstrap'
import { SlideDown } from 'react-slidedown'
import ItemListHOC from '../Reusable/ItemListHOC'
import './ItemList.scss'
import styles from '../../assets/CSS/_global_variables.scss'

class AddToItemListDropdown extends Component {
  state = { dropdownOpen: true, createNewList: false, newListName: '', newListLoading: false }

  async componentDidMount() {
    this.setItemListCheckboxes()
  }

  componentWillUnmount() {
    this.props.toggleShowSharedItemLists(false)
  }

  componentWillReceiveProps(next) {
    const { unit, itemId } = this.props

    if (next.unit !== unit) this.props.getItemListsByItemId({ itemId, unit: next.unit })
  }

  setItemListCheckboxes = async () => {
    const { unit, itemId } = this.props

    if (itemId) {
      if (isEmpty(this.props.itemLists)) await this.props.getItemLists()

      this.props.getItemListsByItemId({ itemId, unit })
    }
  }

  toggle = () => {
    this.setState(prevState => ({ dropdownOpen: !prevState.dropdownOpen }))
  }

  onCreateItemList = () => {
    this.props.setItemList({})
    this.props.toggleItemListModal(true)
  }

  onDeleteItemList = id => {
    this.props.deleteItemList(id)
  }

  createNewList = async () => {
    const { newListName, showSharedItemLists } = this.state
    this.setState({ newListLoading: true })
    await this.props.addNewItemList({ name: newListName })
    await this.props.getItemLists(showSharedItemLists)
    this.setItemListCheckboxes()
    this.setState({ newListLoading: false, createNewList: false, newListName: '' })
  }

  myDivRef = React.createRef()

  renderItemLists = () => {
    const {
      itemLists,
      itemId,
      addItemListDetail,
      deleteItemListDetailByItemListId,
      quantity,
      unit,
      showSharedItemLists,
      toggleShowSharedItemLists,
    } = this.props

    return (
      <div>
        <Row>
          <Col style={{ paddingLeft: '30px' }}>
            <div className="pretty p-switch p-slim mt-1">
              <input
                type="checkbox"
                checked={showSharedItemLists}
                onChange={async ({ target: { checked } }) => {
                  await toggleShowSharedItemLists(checked)

                  this.setItemListCheckboxes()
                }}
              />
              <div className="state ild-pretty-slide">
                <label className="fs15px text-muted">Show Shared</label>
              </div>
            </div>
          </Col>
        </Row>
        {!isEmpty(itemLists) &&
          itemLists.map((l, i) => (
            <Row
              key={i}
              className="mt-1"
              style={{ display: 'flex', flexDirection: 'row', alignItems: 'center' }}
            >
              <Col xs="3" style={{ paddingLeft: '30px' }}>
                <div className="pretty p-icon p-plain p-jelly">
                  <input
                    type="checkbox"
                    checked={l[itemId] === true || l[itemId] === 'loading'}
                    disabled={l[itemId] === 'loading'}
                    onChange={({ target: { checked } }) => {
                      if (checked) addItemListDetail({ quantity, itemListId: l.id, itemId, unit })
                      else deleteItemListDetailByItemListId({ itemListId: l.id, itemId, unit })
                    }}
                  />
                  <div className="state text-danger">
                    <i
                      className={classnames('icon mdi ild-checkbox-icon-jelly', {
                        'mdi-check': l[itemId] === true,
                        'mdi-spin mdi-loading': l[itemId] === 'loading',
                      })}
                    />
                    <label />
                  </div>
                </div>
              </Col>
              <Col xs="9" style={{ paddingLeft: '15px', color: '#384349' }}>
                <span className="fs15px">{l.name.substring(0, 23)}</span>
              </Col>
            </Row>
          ))}
      </div>
    )
  }

  render() {
    const { createNewList, newListName, newListLoading } = this.state
    return (
      <div style={{ maxHeight: '180px' }} className="scroll-200" ref={this.myDivRef}>
        <div>{this.renderItemLists()}</div>

        <div>
          {!createNewList && (
            <a
              href="#/"
              onClick={() => {
                this.setState({ createNewList: true }, () => {
                  const divElement = this.myDivRef.current
                  divElement.scrollTop = divElement.scrollHeight
                })
              }}
              className="fs15px  mt-1 anc"
            >
              Create new list
            </a>
          )}
        </div>
        {createNewList && (
          <InputFloat
            onChange={({ target: { value } }) => this.setState({ newListName: value })}
            onKeyPress={async ({ key }) => key === 'Enter' && this.createNewList()}
            disabled={newListLoading}
            value={newListName}
            activeColor={styles.colorPrimary}
            placeholder="New List Name"
          />
        )}
      </div>
    )
  }
}

export default ItemListHOC(AddToItemListDropdown)
