import React from 'react'
import classnames from 'classnames'

const MinusSVG = ({ className }) => {
  return (
    <svg
      viewBox="0 0 20 20"
      className={classnames('dealmed-icon', className)}
      aria-hidden={true}
      focusable={false}
    >
      <path d="M16 10c0 0.553-0.048 1-0.601 1h-10.798c-0.552 0-0.601-0.447-0.601-1s0.049-1 0.601-1h10.799c0.552 0 0.6 0.447 0.6 1z"></path>
    </svg>
  )
}

export default MinusSVG
