import React, { useEffect, useState } from 'react'
import { Link } from 'react-router-dom'
import { find, isNil, map, pathOr } from 'ramda'
import classnames from 'classnames'
import { getUrlComponent } from '../../utils'
import TransactionHOC from '../Reusable/TransactionHOC'
import NumberInput from '../../shared/components/NumberInput'
import DealmedListbox from '../../shared/components/DealmedListbox'
import Spinner from '../../shared/components/Spinner'
import DownChevronSVG from '../../shared/icons/DownChevronSVG'
import CheckmarkSVG from '../../shared/icons/CheckmarkSVG'
import ErrorSVG from '../../shared/icons/ErrorSVG'
import ShoppingBagSVG from '../../shared/icons/ShoppingBagSVG'
import PrescriptionSVG from '../../shared/icons/PrescriptionSVG'
import DealmedDialog from '../../shared/components/DealmedDialog'
import AddToItemListDropdown from '../ItemList/AddToItemListDropdown'
import CloseSVG from '../../shared/icons/CloseSVG'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faPlus, faMinus, faTrash } from '@fortawesome/free-solid-svg-icons'
import { editCartDetail, removeCartDetail } from '../../actions'
import CartHOC from '../Reusable/CartHOC'
import { formatReadableDate } from '../../utils/formatReadableDate'
import '../QuickOrder/QuickOrder.scss'
import { mixpanelLogEvent } from '../../utils/mixpanelLogEvent'
import AddToListIcon from '../../shared/icons/playlist-plus'
import aa from 'search-insights'
import { Tooltip } from 'reactstrap'
import zIndex from '@mui/material/styles/zIndex'

const { REACT_APP_STORAGE_URL, REACT_APP_CONT_PRODUCT_IMG, PUBLIC_URL } = process.env
const IMAGE_URL = REACT_APP_STORAGE_URL + REACT_APP_CONT_PRODUCT_IMG

const formatter = new Intl.NumberFormat('en-US', {
  style: 'currency',
  currency: 'USD',
})

const QuickOrderItemCard = props => {
  const {
    children: itemChildren,
    displayName,
    id,
    itemId,
    isInStock,
    isAddableToCart,
    itemImage,
    itemName,
    manufacturer,
    mpn,
    quantityAvailable,
    rxLevel,
    price,
    siteDisplayName,
    thumbnailImage,
    unit,
    urlComponent,
    allocationPeriod,
  } = props.product

  const [modal, setModal] = useState(false)
  const toggle = () => setModal(!modal)

  const {
    validateRx,
    validateRxAndAddToCart,
    isPriceVisible,
    isAuthenticated,
    enhancedOrdering,
    showQuantityCounter,
    sectionTitle,
    isCheckoutInterPage,
    validateRxAndAddToCartFromParentComponent,
    isModal = false,
  } = props

  const cartDetailForItem = props.cartDetail.filter(item => item.itemId === Number(id || itemId))

  let units = map(unit => {
    return {
      value: unit.id,
      label: unit.unitName,
      conversionRate: unit.conversionRate,
      baseUnit: unit.baseUnit,
    }
  }, pathOr([], ['unit', 'details'], props.product))

  const search = window.location.search
  const params = new URLSearchParams(search)
  const uom = params.get('uom')
  const defaultUnit =
    (uom ? units.find(unit => unit.value == uom) : find(unit => unit.baseUnit, units)) || {}
  const unitDetails = enhancedOrdering
    ? units
    : findUnitDetails(props?.product?.latestPurchase?.unit)
  const previouslyOrderedDate = formatReadableDate(props?.product?.latestPurchase?.date)

  const [showDialog, setShowDialog] = useState(false)
  const [productImage, setProductImage] = useState(
    thumbnailImage ||
      itemImage?.find(image => image.index == 1)?.largeUrl ||
      `${PUBLIC_URL}/images/imageComingSoon.png`,
  )
  const [productQuantity, setProductQuantity] = useState(1)
  const [productUnits, setProductUnits] = useState(
    enhancedOrdering
      ? { name: 'Each', conversionRate: 1, id: unit ? unit.details[0].id : null }
      : {
          name: unitDetails.unitName,
          conversionRate: unitDetails.conversionRate,
          id: unitDetails.id,
        },
  )
  const [isAddingToCart, setIsAddingToCart] = useState(false)
  const [matrixItemDetails, setMatrixItemDetails] = useState(
    itemChildren?.length > 0
      ? {
          id: itemChildren[0].id,
          itemName: itemChildren[0].itemName,
          matrixDetails: itemChildren[0].matrixDetails,
          mpn: itemChildren[0].mpn,
          price: itemChildren[0].price,
          prices: itemChildren[0].prices,
          quantityAvailable: itemChildren[0].quantityAvailable,
          rxLevel: itemChildren[0].rxLevel,
          urlComponent: itemChildren[0].urlComponent,
        }
      : '',
  )
  const [number, setNumber] = useState(cartDetailForItem[0]?.quantity || 0)
  const [tooltipOpen, setTooltipOpen] = useState(false)

  const productPath = getUrlComponent({ id, urlComponent })
  const { isValidRxLevel, isSellableCSL } = validateRx({ rxLevel })

  const productUOM = [{ value: 'Each', text: 'Each', disabled: false }]
  const productChildren = []
  let productPrice = formatter.format(
    (matrixItemDetails?.price || price) * productUnits.conversionRate * productQuantity,
  )

  if (unit) {
    for (const unit of unit.details) {
      if (unit?.unitName === 'Each') continue
      productUOM.push({ value: unit.unitName, text: unit.unitName, disabled: false })
    }
  }
  if (itemChildren?.length > 0) {
    for (const child of itemChildren) {
      productChildren.push({
        value: child.matrixDetails && Object.values(child.matrixDetails).join('/'),
        text: child.matrixDetails && Object.values(child.matrixDetails).join('/'),
        disabled: false,
      })
    }
    productChildren.sort((a, b) => a?.text?.localeCompare(b?.text))
  }
  const isMatrixItemAvailable = matrixItemDetails?.quantityAvailable > 0
  const isAvailable = props?.product?.quantityAvailable > 0

  function findUnitDetails(unitId) {
    const foundUnit = props?.product?.latestPurchase?.unitDetails?.find(unit => unit.id === unitId)
    return foundUnit
      ? {
          unitName: foundUnit.unitName || 'Each',
          conversionRate: foundUnit.conversionRate,
          id: foundUnit.id,
        }
      : {}
  }

  const getListPrice = (item, matrixItemDetails) => {
    if (matrixItemDetails?.prices) {
      for (let priceId in matrixItemDetails.prices) {
        if (matrixItemDetails.prices[priceId].level === 'List Price') {
          return matrixItemDetails.prices[priceId].price
        }
      }
    }

    for (let priceId in item.prices) {
      if (item.prices[priceId].level === 'List Price') {
        return item.prices[priceId].price
      }
    }
    return null
  }

  const listPrice = formatter.format(
    getListPrice(props.product, matrixItemDetails) * productQuantity,
  )

  const isPriceARange = price && price.toString().includes('-')
  const updateProductQuantity = quantity => {
    setProductQuantity(quantity)
  }

  const updateProductUnits = unitName => {
    const newUnits = unit?.details?.find(unit => unit.unitName === unitName)
    setProductUnits({
      id: newUnits.id,
      name: newUnits.unitName,
      conversionRate: newUnits.conversionRate,
    })
  }

  const updateProductByMatrixItem = newValue => {
    itemChildren.forEach(item => {
      if (Object.values(item.matrixDetails).join('/') == newValue) {
        setMatrixItemDetails({
          id: item.id,
          itemName: item.itemName,
          matrixDetails: item.matrixDetails,
          mpn: item.mpn,
          price: item.price,
          prices: item.prices,
          quantityAvailable: item.quantityAvailable,
          rxLevel: item.rxLevel,
          urlComponent: item.urlComponent,
        })
      }
    })
  }

  const logClickEvent = ({ eventType }) => {
    mixpanelLogEvent({
      eventType,
      userDetails: props?.auth?.user,
      itemDetails: props?.product,
      metaData: {
        searchTerm: props?.searchTerm,
        productQuantity,
        productUnits,
      },
    })
  }

  const onItemClick = () => {
    logClickEvent({ eventType: `${sectionTitle}_Item_Click` })

    if (process.env.NODE_ENV !== 'production') {
      aa('clickedObjectIDs', {
        eventName: `${sectionTitle} Item Click`,
        index: 'itemsIndex',
        objectIDs: [itemId],
      })
    }
  }

  const addProductToCart = async () => {
    setIsAddingToCart(true)
    await validateRxAndAddToCart(
      matrixItemDetails.id ? { id: matrixItemDetails.id } : { id: id || itemId },
      productQuantity,
      productUnits.id || unitDetails.id,
    )
    logClickEvent({ eventType: `${sectionTitle}_Add_Item_To_Cart` })
    if (process.env.NODE_ENV !== 'production') {
      aa('addedToCartObjectIDs', {
        eventName: `${sectionTitle} Added To Cart`,
        index: 'itemsIndex',
        objectIDs: [itemId],
      })
    }
    setIsAddingToCart(false)
  }

  const openDialog = () => {
    setShowDialog(true)
  }

  const closeDialog = () => {
    setShowDialog(false)
  }

  const getQuantityOfItemInCart = itemId => {
    const itemInCart = props.cartDetail.find(cartItem => cartItem.itemId === itemId)
    return itemInCart ? itemInCart.quantity : 0
  }

  const handleBrokenImageLink = () => {
    setProductImage(`${PUBLIC_URL}/images/imageComingSoon.png`)
  }

  const toggleTooltip = () => setTooltipOpen(!tooltipOpen)

  useEffect(() => {
    itemChildren?.length > 0 &&
      setMatrixItemDetails({
        id: itemChildren[0].id,
        itemName: itemChildren[0].itemName,
        matrixDetails: itemChildren[0].matrixDetails,
        mpn: itemChildren[0].mpn,
        price: itemChildren[0].price,
        prices: itemChildren[0].prices,
        quantityAvailable: itemChildren[0].quantityAvailable,
        rxLevel: itemChildren[0].rxLevel,
        urlComponent: itemChildren[0].urlComponent,
      })
  }, [itemChildren])

  const isUnavailable = (!matrixItemDetails && price == 'N/A') || matrixItemDetails?.price == 'N/A'
  const hiddenPriceBool = isUnavailable || (!isAddableToCart && !isNil(isAddableToCart))

  return (
    <div
      className={`quickorder__products-box ${isModal ? '-is-modal' : ''}`}
      key={props.key}
      style={{ maxWidth: (isCheckoutInterPage || isModal) && '255px', zIndex: isModal && '1050' }}
    >
      <div
        className="quickorder__products-box-wrapper"
        style={{ height: enhancedOrdering ? '100%' : null }}
      >
        <div>
          {!enhancedOrdering && (
            <div className="quickorder__products-box-wrapper-previously">
              <p className="previously-ordered">Ordered on {previouslyOrderedDate}</p>
            </div>
          )}
        </div>
        <div style={{ display: 'flex', justifyContent: 'center' }}>
          <Link to={`/product/${productPath}`}>
            <div className="quickorder__products-box-image-container">
              <div className="quickorder__products-box-image" onClick={onItemClick}>
                <img
                  src={productImage}
                  onError={handleBrokenImageLink}
                  alt={siteDisplayName || displayName}
                  loading="lazy"
                />
              </div>
              {!hiddenPriceBool && (
                <div
                  className={classnames('dealmed-product__availability', {
                    'dealmed-product__availability--available':
                      productChildren.length > 0 ? isMatrixItemAvailable : isAvailable,
                    'dealmed-product__availability--unavailable':
                      productChildren.length > 0 ? !isMatrixItemAvailable : !isAvailable,
                  })}
                >
                  {quantityAvailable > 0 || matrixItemDetails?.quantityAvailable > 0 ? (
                    <>
                      <div className="item-card-in-stock">
                        <CheckmarkSVG className="dealmed-quickorder__is-icon" />
                        <p className="dealmed-quickorder__is-text">In Stock</p>
                      </div>
                    </>
                  ) : (
                    <>
                      <div className="item-card-out-of-stock">
                        <ErrorSVG className="dealmed-quickorder__oos-icon" />
                        <p className="dealmed-quickorder__oos-text">Out of Stock</p>
                      </div>
                    </>
                  )}
                </div>
              )}
            </div>
          </Link>
        </div>
        <div className="quickorder__products-box-titles">
          <p className="quickorder__products-box-manufacturer">{manufacturer}</p>
          <Link to={`/product/${productPath}`}>
            <h5
              style={{
                display: '-webkit-box',
                WebkitBoxOrient: 'vertical',
                WebkitLineClamp: 2,
                overflow: 'hidden',
              }}
              className="quickorder__products-box-title"
              onClick={onItemClick}
              title={siteDisplayName || displayName}
            >
              {siteDisplayName || displayName}
            </h5>
          </Link>
          <div
            style={{
              display: 'flex',
              gap: '10px',
            }}
          >
            {!enhancedOrdering && (
              <p className="quickorder__products-box-inputs-block-matrixDetail">
                UOM: {unitDetails.unitName}{' '}
              </p>
            )}
            <div>
              {/* Style update needed to show multiple matrix details */}
              {props?.product?.matrixDetails && (
                <p className="quickorder__products-box-inputs-block-matrixDetail">
                  {Object.keys(props?.product?.matrixDetails)[0]}:{' '}
                  {Object.values(props?.product?.matrixDetails)[0]}
                </p>
              )}
            </div>
          </div>
        </div>

        <div className="quickorder__products-box-inputs">
          {productChildren.length > 0 && enhancedOrdering && (
            <div className="quickorder__products-box-inputs-row">
              <div className="quickorder__products-box-inputs-block">
                <div className="quickorder__products-box-inputs-color">
                  <DealmedListbox
                    options={productChildren}
                    label={
                      matrixItemDetails.matrixDetails &&
                      Object.keys(matrixItemDetails.matrixDetails).join('/')
                    }
                    arrow={<DownChevronSVG />}
                    value={
                      matrixItemDetails.matrixDetails &&
                      Object.values(matrixItemDetails.matrixDetails).join('/')
                    }
                    onChange={updateProductByMatrixItem}
                  />
                </div>
              </div>
            </div>
          )}
          <div className="quickorder__products-box-inputs-row">
            {enhancedOrdering && (
              <div className="quickorder__products-box-inputs-block">
                <NumberInput
                  label="Product Quantity"
                  name={'Hello'}
                  value={productQuantity.toString()}
                  updateValue={updateProductQuantity}
                  style={{ backgroundColor: 'white' }}
                />
                <div className="quickorder__products-box-inputs-byBox">
                  {productUOM.length > 0 ? (
                    <DealmedListbox
                      options={productUOM}
                      label="Units of Measurement"
                      arrow={<DownChevronSVG />}
                      value={productUnits.name.toString()}
                      onChange={updateProductUnits}
                    />
                  ) : null}
                </div>
              </div>
            )}
          </div>
          {!enhancedOrdering && (
            <div style={{ display: 'flex', justifyContent: 'center' }}>
              <NumberInput
                label="Product Quantity"
                name={'Hello'}
                value={productQuantity.toString()}
                updateValue={updateProductQuantity}
                style={{ backgroundColor: 'white' }}
              />
            </div>
          )}
        </div>

        <div className="quickorder__products-box-stock">
          {isPriceVisible ? (
            <div style={{ display: 'flex', gap: '5px', alignItems: 'center' }}>
              <h4
                className="quickorder__products-box-stock-price"
                style={hiddenPriceBool && isAuthenticated ? { visibility: 'hidden' } : null}
              >
                {productPrice}
              </h4>
              {Number(listPrice.replace(/[^0-9.-]+/g, '')) >
                Number(productPrice.replace(/[^0-9.-]+/g, '')) &&
                !hiddenPriceBool &&
                enhancedOrdering && (
                  <h4 className="quickorder__products-box-stock-price-before">{listPrice}</h4>
                )}
            </div>
          ) : (
            <Link
              to="/login"
              className="dealmed-product__login"
              style={isAuthenticated ? { visibility: 'hidden' } : null}
            >
              <p className="dealmed-product__login-text">Login to see price</p>
            </Link>
          )}

          <div className="quickorder__products-box-stock-block">
            {allocationPeriod && (
              <>
                <span id={`TooltipTarget${id}`}>
                  <ErrorSVG className="quickorder__products-box-allocation" />
                </span>
                <Tooltip
                  placement="right"
                  isOpen={tooltipOpen}
                  target={`TooltipTarget${id}`}
                  toggle={toggleTooltip}
                >
                  <p style={{ textAlign: 'left' }}>
                    This item is on allocation and quantity may be limited
                  </p>
                </Tooltip>
              </>
            )}
            {rxLevel > 0 ? (
              <span>
                <PrescriptionSVG className="dealmed-product__license-icon" />
              </span>
            ) : null}
          </div>
        </div>
      </div>

      <div className="quickorder__products-box-buttons">
        {/* {!enhancedOrdering && <AddToCartButton />} NEW ADD TO CART BUTTON*/}
        {/* {enhancedOrdering && ( */}
        <>
          {isValidRxLevel && isSellableCSL ? (
            <button
              type="button"
              className="dealmed-product__add-to-cart"
              disabled={(hiddenPriceBool && isAuthenticated) || (isAddingToCart && true)}
              onClick={addProductToCart}
              style={hiddenPriceBool && isAuthenticated ? { backgroundColor: '#384349' } : {}}
            >
              {isAddingToCart ? (
                <Spinner />
              ) : (
                <>
                  <span className="quickorder__products-box-buttons-addtocart-title">
                    {hiddenPriceBool && isAuthenticated ? 'Currently Unavailable' : 'Add to cart'}
                  </span>
                  {/* <span className="dealmed-product__add-to-cart-icon-wrapper">
                      {hiddenPriceBool && isAuthenticated ? null : (
                        <ShoppingBagSVG className="dealmed-product__add-to-cart-icon" />
                      )}
                    </span> */}
                </>
              )}
            </button>
          ) : (
            <p className="dealmed-product__unable">
              This item requires a medical license to purchase
            </p>
          )}

          {enhancedOrdering && (
            <div
              className="quickorder__products-box-buttons-addtolist"
              onClick={() => {
                toggle()
              }}
            >
              <AddToListIcon
                className="quickorder__products-box-buttons-addtolist-select"
                style={{ cursor: 'pointer' }}
                onClick={openDialog}
              />
            </div>
          )}
        </>
      </div>
      <div
        style={{
          display: 'flex',
          justifyContent: 'center',
          gap: '8px',
          marginTop: '4px',
        }}
      >
        <p className="quickorder__products-box-inputs-block-text">
          MPN: {matrixItemDetails?.mpn || mpn}
        </p>
        <p className="quickorder__products-box-inputs-block-text input-number">
          Item #: {matrixItemDetails?.itemName || itemName}
        </p>
      </div>
      {isCheckoutInterPage && (
        <p style={{ minHeight: '24px', textAlign: 'center' }}>
          {showQuantityCounter &&
            getQuantityOfItemInCart(itemId) > 0 &&
            `Added: ${getQuantityOfItemInCart(itemId)}`}
        </p>
      )}
      <DealmedDialog
        // isOpen={showDialog}
        // onDismiss={closeDialog}
        isOpen={modal}
        onDismiss={() => setModal(false)}
        className="product-details__action-dialog"
        label="Add to List Dialog"
        style={{ backgroundColor: 'transparent' }}
      >
        <div className="product-details__action-content">
          <button
            className="product-details__action-close"
            // onClick={closeDialog}
            onClick={() => setModal(false)}
            aria-label="Close Add To List Dialog"
          >
            <CloseSVG className="product-details__action-icon product-details__action-icon--close" />
          </button>
          <h2 className="product-details__action-header">Add To List</h2>
          {props.product && (
            <AddToItemListDropdown
              quantity={productQuantity}
              itemId={props.product.id || itemId}
              unit={pathOr(null, ['value'], {
                ...defaultUnit,
                ...productUnits,
              })}
            />
          )}
        </div>
      </DealmedDialog>
    </div>
  )
}

export default TransactionHOC(CartHOC(QuickOrderItemCard))
