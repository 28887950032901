import React from 'react'
import classnames from 'classnames'

const ReorderSVG = ({ className }) => {
  return (
    <svg
      viewBox="0 0 36 32"
      className={classnames('dealmed-icon', className)}
      aria-hidden={true}
      focusable={false}
    >
      <path
        fill="#808080"
        d="M7.767 13.722l1.81-3.033h15.56l1.957 3.033v14.214c-6.239 4.746-14.018 4.232-19.327-0.171v-14.043z"
      ></path>
      <path fill="#fff" d="M8.354 13.796h18.153v-0.148h-18.153z"></path>
      <path fill="#fff" d="M15.473 13.722h0.148v-3.083h-0.148z"></path>
      <path fill="#fff" d="M19.24 13.722h0.148v-3.083h-0.148z"></path>
      <path
        fill="#fff"
        d="M22.437 21.883h-9.916c-0.328 0-0.593-0.265-0.593-0.593s0.265-0.593 0.593-0.593h9.916c0.328 0 0.593 0.265 0.593 0.593s-0.265 0.593-0.593 0.593z"
      ></path>
      <path
        fill="#fff"
        d="M22.437 23.97h-9.916c-0.328 0-0.593-0.265-0.593-0.593s0.265-0.593 0.593-0.593h9.916c0.328 0 0.593 0.265 0.593 0.593s-0.265 0.593-0.593 0.593z"
      ></path>
      <path
        fill="#fff"
        d="M19.363 26.058h-3.767c-0.328 0-0.593-0.265-0.593-0.593s0.265-0.593 0.593-0.593h3.767c0.328 0 0.593 0.265 0.593 0.593s-0.265 0.593-0.593 0.593z"
      ></path>
      <path
        fill="#b92a47"
        d="M34.975 17.321c-0.053 0.047-0.124 0.077-0.196 0.077-0.083 0-0.16-0.036-0.219-0.095l-1.292-1.404c-0.059 8.658-7.117 15.686-15.787 15.686-7.496 0-13.79-5.25-15.39-12.267l0.593-0.071c1.564 6.72 7.603 11.745 14.797 11.745 8.367 0 15.176-6.803 15.194-15.164l-1.345 1.221c-0.124 0.113-0.308 0.101-0.421-0.018-0.107-0.124-0.101-0.314 0.024-0.421l1.849-1.677c0.119-0.107 0.308-0.101 0.415 0.018l1.796 1.95c0.113 0.119 0.107 0.308-0.018 0.421z"
      ></path>
      <path
        fill="#b92a47"
        d="M32.634 11.384l-0.593 0.071c-1.867-6.276-7.686-10.862-14.56-10.862-8.030 0-14.619 6.258-15.159 14.145l1.446-1.31c0.119-0.107 0.308-0.101 0.415 0.024 0.113 0.119 0.101 0.308-0.018 0.415l-2.127 1.926c-0.053 0.047-0.124 0.071-0.196 0.071h-0.030c-0.089-0.006-0.166-0.053-0.213-0.124l-1.547-2.169c-0.095-0.136-0.059-0.32 0.071-0.415 0.136-0.095 0.32-0.065 0.415 0.071l1.179 1.659c0.468-8.284 7.354-14.886 15.763-14.886 7.176 0 13.244 4.812 15.153 11.384z"
      ></path>
      <path
        fill="#fff"
        d="M14.525 17.246c-0.014 0-0.028-0.004-0.040-0.012-0.035-0.022-0.045-0.068-0.023-0.102l0.44-0.685c0.014-0.021 0.037-0.034 0.062-0.034h4.917c0.020 0 0.038 0.007 0.052 0.021 0.084 0.083 0.162 0.223 0.239 0.359 0.071 0.127 0.145 0.258 0.214 0.325 0.028 0.029 0.028 0.076 0 0.105-0.029 0.029-0.076 0.029-0.105 0-0.082-0.083-0.158-0.216-0.238-0.358-0.065-0.115-0.132-0.233-0.195-0.305h-4.844l-0.419 0.65c-0.014 0.022-0.038 0.034-0.062 0.034z"
      ></path>
    </svg>
  )
}

export default ReorderSVG
