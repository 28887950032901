import DOMPurify from 'dompurify'
import { mixpanelLogEvent } from './mixpanelLogEvent'

export function sanitizeInput(input) {
  const sanitizedInput = DOMPurify.sanitize(input, { ALLOWED_TAGS: [], ALLOWED_ATTR: [] })
  if (!sanitizedInput || input !== sanitizedInput) {
    mixpanelLogEvent({ eventType: 'INPUT SANTIZATION USED' })
  }

  return sanitizedInput
}
