import React, { useState, useContext } from 'react'
import { ProductDetailsContext } from './ProductDetailsContext'
import { TwitterShareButton, FacebookShareButton, LinkedinShareButton } from 'react-share'
import DealmedDialog from '../../shared/components/DealmedDialog'
import ShareSVG from '../../shared/icons/ShareSVG'
import CloseSVG from '../../shared/icons/CloseSVG'

const ProductDetailsShareDialog = React.memo(({ logClickEvent }) => {
  const [showDialog, setShowDialog] = useState(false)

  const productDetailsProps = useContext(ProductDetailsContext)

  const history = productDetailsProps.history

  const openDialog = () => {
    logClickEvent({ eventType: 'PDP_Open_Share_Dialog' })
    setShowDialog(true)
  }

  const closeDialog = () => {
    setShowDialog(false)
  }

  const handleCopyLink = () => {
    logClickEvent({ eventType: 'PDP_ShareDialog_CopyLink_Button_Click' })
    let copyText = document.getElementById('share-copy-link')
    copyText.select()
    copyText.setSelectionRange(0, 99999)
    document.execCommand('copy')
  }

  return (
    <>
      <button
        className="product-details__action-open"
        onClick={openDialog}
        aria-label="Open Share Dialog"
      >
        <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24">
          <title>share-variant</title>
          <path d="M18,16.08C17.24,16.08 16.56,16.38 16.04,16.85L8.91,12.7C8.96,12.47 9,12.24 9,12C9,11.76 8.96,11.53 8.91,11.3L15.96,7.19C16.5,7.69 17.21,8 18,8A3,3 0 0,0 21,5A3,3 0 0,0 18,2A3,3 0 0,0 15,5C15,5.24 15.04,5.47 15.09,5.7L8.04,9.81C7.5,9.31 6.79,9 6,9A3,3 0 0,0 3,12A3,3 0 0,0 6,15C6.79,15 7.5,14.69 8.04,14.19L15.16,18.34C15.11,18.55 15.08,18.77 15.08,19C15.08,20.61 16.39,21.91 18,21.91C19.61,21.91 20.92,20.61 20.92,19A2.92,2.92 0 0,0 18,16.08Z" />
        </svg>
        {/* <ShareSVG className="product-details__action-icon product-details__action-icon--red" /> */}
        {/* <img src={`${process.env.PUBLIC_URL}/images/share-alt.png`} alt="" loading="lazy"/> */}
        {/* <div className="product-details__action-tooltip">
					<span className="product-details__action-tooltip-text">Share</span>
				</div> */}
      </button>
      <DealmedDialog
        isOpen={showDialog}
        onDismiss={closeDialog}
        className="product-details__action-dialog"
        label="Share Dialog"
      >
        <div className="product-details__action-content">
          <button
            className="product-details__action-close"
            onClick={closeDialog}
            aria-label="Close Share Dialog"
          >
            <CloseSVG className="product-details__action-icon product-details__action-icon--close" />
          </button>
          <h2 className="product-details__action-header">Share</h2>
          <ul className="product-details__action-share">
            <li
              className="product-details__action-share-item"
              onClick={() => logClickEvent({ eventType: 'PDP_ShareDialog_Twitter_Button_Click' })}
            >
              <TwitterShareButton
                url={`https://dealmed.com${history.location.pathname}`}
                children={<i className="mdi mdi-twitter smediaIcon" />}
              />
            </li>
            <li
              className="product-details__action-share-item"
              onClick={() => logClickEvent({ eventType: 'PDP_ShareDialog_Facebook_Button_Click' })}
            >
              <FacebookShareButton
                url={`https://dealmed.com${history.location.pathname}`}
                children={<i className="mdi mdi-facebook smediaIcon" />}
                quote="Check out this product from @dealmed"
              />
            </li>
            <li
              className="product-details__action-share-item"
              onClick={() => logClickEvent({ eventType: 'PDP_ShareDialog_Linkedin_Button_Click' })}
            >
              <LinkedinShareButton
                url={`https://dealmed.com${history.location.pathname}`}
                children={<i className="mdi mdi-linkedin smediaIcon" />}
              />
            </li>
          </ul>
          <div className="product-details__action-copy">
            <label htmlFor="share-copy-link" className="product-details__action-copy-label">
              Share this link
              <input
                id="share-copy-link"
                className="product-details__action-copy-input"
                value={window.location.href}
              />
            </label>
            <button className="product-details__action-copy-button" onClick={handleCopyLink}>
              Copy Link
            </button>
          </div>
        </div>
      </DealmedDialog>
    </>
  )
})

export default ProductDetailsShareDialog
