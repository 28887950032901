import React, { useContext, useEffect, useRef } from 'react'
import { Link } from 'react-router-dom'
import DealmedPopover from '../../shared/components/DealmedPopover'
import Spinner from '../../shared/components/Spinner'
import DownChevronSVG from '../../shared/icons/DownChevronSVG'
import PhoneSVG from '../../shared/icons/PhoneSVG'
import SpeechBubbleSVG from '../../shared/icons/SpeechBubbleSVG'
import EnvelopeSVG from '../../shared/icons/EnvelopeSVG'
import ClockSVG from '../../shared/icons/ClockSVG'
import { ContentfulContext } from '../../shared/ContentfulContext'
import { ShoppingContext } from '../../shared/ShoppingContext'
import { mixpanelLogEvent } from '../../utils/mixpanelLogEvent'

const SupportPopover = ({ isOpen, openPopover, closePopover, className }) => {
  const triggerRef = useRef(null)
  const popoverWrapperRef = useRef(null)
  const popoverMatchWidthRef = useRef(null)

  const { contactEmail, contactNumber, hoursTextShort } = useContext(ContentfulContext)
    .filter(x => x.sys.contentType.sys.id == 'navBar')[0]
    .fields.navBarSections.find(x => x.fields.path == '/contactus').fields.pageSections[0].fields

  const { userInfo } = useContext(ShoppingContext)

  const logClickEvent = ({ eventType }) => {
    mixpanelLogEvent({
      eventType,
      userDetails: userInfo,
    })
  }

  const handleClick = () => {
    if (isOpen) {
      closePopover()
    } else {
      openPopover()
    }
  }

  useEffect(() => {
    import('@reach/popover').then(module => {
      popoverMatchWidthRef.current = module.positionMatchWidth
    })
  }, [])

  return (
    <React.Suspense fallback={<Spinner />}>
      <button
        type="button"
        ref={triggerRef}
        className="dealmed-header__support-toggle-btn"
        onClick={() => {
          logClickEvent({ eventType: `Support_Popover_Clicked` })
          handleClick()
        }}
      >
        Support
        <span className="dealmed-header__icon-wrapper">
          <DownChevronSVG />
        </span>
      </button>

      {isOpen && popoverMatchWidthRef.current && (
        <DealmedPopover
          innerRef={popoverWrapperRef}
          targetRef={triggerRef}
          closePopover={closePopover}
          position={popoverMatchWidthRef.current}
          className={className}
        >
          <ul className="dealmed-header__support-list" onClick={handleClick}>
            <li className="dealmed-header__support-item">
              <a href={`tel:1-${contactNumber}`} className="dealmed-header__support-phone">
                <span className="dealmed-header__icon-wrapper">
                  <PhoneSVG className="dealmed-phone-icon dealmed-header__support-icon" />
                </span>
                1-{contactNumber}
              </a>
            </li>
            <li className="dealmed-header__support-item">
              <Link to="/contactus" className="dealmed-header__support-contact">
                <span className="dealmed-header__icon-wrapper">
                  <SpeechBubbleSVG className="dealmed-header__support-icon" />
                </span>
                Contact
              </Link>
            </li>
            <li className="dealmed-header__support-item">
              <a href={`mailto:${contactEmail}`} className="dealmed-header__support-email">
                <span className="dealmed-header__icon-wrapper">
                  <EnvelopeSVG className="dealmed-header__support-icon" />
                </span>
                Email
              </a>
            </li>
            <li className="dealmed-header__support-item">
              <span className="dealmed-header__icon-wrapper">
                <ClockSVG className="dealmed-header__support-icon" />
              </span>
              {hoursTextShort}
            </li>
          </ul>
        </DealmedPopover>
      )}
    </React.Suspense>
  )
}

export default SupportPopover
