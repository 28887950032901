/* eslint-disable import/no-anonymous-default-export */
import React, { Component } from 'react'
import { withRouter } from 'react-router-dom'
import { connect } from 'react-redux'
import * as R from 'ramda'
import {
  getPaged,
  getPagedPost,
  getMorePagedProducts,
  getRecord,
  createRecord,
  editRecord,
  deleteRecord,
  setSearch,
  setSearchTerm,
  selectSubCategory,
  setPaginationFields,
  getShoppingFilters,
  setShoppingFilter,
  resetSelectedCategory,
  setCategoriesBySubCategoryUrl,
} from '../../actions'
import {
  SET_CONTACT_PROPS,
  SET_PRODUCT_PROPS,
  SET_SALES_ORDER,
  SET_REORDER_PROPS,
  SET_INVOICE_PROPS,
  SET_PAYMENT_HISTORY_PROPS,
  SET_ESTIMATE,
} from '../../actions/types'

const pagedObjects = {
  CONTACT_OBJ: {
    route: 'myaccount/contact',
    type: SET_CONTACT_PROPS,
    objectName: 'contact',
    reducerProp: 'contacts',
  },
  PRODUCT_OBJ: {
    route: 'shopping/item',
    type: SET_PRODUCT_PROPS,
    objectName: 'product',
    reducerProp: 'products',
    loader: 'ekgLoading',
  },
  REORDER_OBJ: {
    route: 'myaccount/transactions/reorderItems',
    type: SET_REORDER_PROPS,
    objectName: 'reorders',
    reducerProp: 'reorders',
  },
  INVOICE_OBJ: {
    route: 'myaccount/transactions/invoices',
    type: SET_INVOICE_PROPS,
    objectName: 'invoice',
    reducerProp: 'invoices',
  },
  PAYMENT_HISTORY_OBJ: {
    route: 'myaccount/transactions/payments',
    type: SET_PAYMENT_HISTORY_PROPS,
    objectName: 'payment',
    reducerProp: 'payments',
  },
  SO_OBJ: {
    route: 'myaccount/transactions/salesorders',
    type: SET_SALES_ORDER,
    objectName: 'salesorder',
    reducerProp: 'salesorders',
  },
  ESTIMATE_OBJ: {
    route: 'myaccount/transactions/estimates',
    type: SET_ESTIMATE,
    objectName: 'estimate',
    reducerProp: 'estimates',
  },
}

export default ComposedComponent => {
  class Pagination extends Component {
    onSubCategoryClick = ({ subCategory }) => {
      const { PRODUCT_OBJ } = pagedObjects
      /// Setting Subcategory in Redux:
      this.props.selectSubCategory(subCategory)
      ////////////////////////////////

      this.props.setPaginationFields({
        reducerProp: PRODUCT_OBJ.reducerProp,
        data: { numPaginateSection: 1, page: 1 },
      })
    }

    getSearchObject = () => {
      const { searchTerm, categoryId, categoryUrlComponent, location } = this.props
      return R.filter(v => v, {
        search:
          categoryId || categoryUrlComponent
            ? ''
            : searchTerm ||
              decodeURIComponent(location?.search.replace('?search=', '').replace('&page=1', '')),
        categoryId: searchTerm ? '' : categoryId,
        category: categoryUrlComponent || categoryId,
      })
    }

    executeSearchAndRedirect = () => {
      const { searchTerm, categoryId, categoryUrlComponent, getPagedPost, getShoppingFilters } =
        this.props
      const searchObj = this.getSearchObject()
      const category = categoryUrlComponent || categoryId
      const queryParam = category ? `category=${category}` : `search=${searchTerm}`

      //const queryParam = category ? `${category}` : ``;
      getPagedPost(searchObj, pagedObjects.PRODUCT_OBJ)
      // getShoppingFilters(searchObj);

      const pathname = category ? '/products/' + category : '/products/1'
      if (category) {
        this.props.history.push({ pathname: pathname })
      } else {
        this.props.history.push({
          pathname: '/products/1',
          search: queryParam,
        })
      }
    }

    render() {
      const props = {
        onSubCategoryClick: this.onSubCategoryClick,
        executeSearchAndRedirect: this.executeSearchAndRedirect,
        getSearchObject: this.getSearchObject,
      }
      return <ComposedComponent {...{ ...this.props, ...props }} {...pagedObjects} />
    }
  }

  const mapStateToProps = ({
    paginate,
    shopping: { searchTerm, filters, selectedSubCategory },
  }) => {
    return {
      ...paginate,
      searchTerm,
      filters,
      categoryId: R.pathOr('', ['id'], selectedSubCategory),
      categoryUrlComponent: R.pathOr('', ['urlComponent'], selectedSubCategory),
    }
  }

  return withRouter(
    connect(mapStateToProps, {
      setPaginationFields,
      getPaged,
      getPagedPost,
      getMorePagedProducts,
      getRecord,
      createRecord,
      editRecord,
      deleteRecord,
      setSearchTerm,
      setSearch,
      getShoppingFilters,
      setShoppingFilter,
      selectSubCategory,
      resetSelectedCategory,
      setCategoriesBySubCategoryUrl,
    })(Pagination),
  )
}
