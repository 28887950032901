//import { mergeRight } from 'ramda'
import { resolveReq, axios } from '../utils/api'
import {
  SET_INVOICES,
  SET_CREDIT_MEMO,
  SET_UNAPPLIED_PAYMENTS,
  // SET_CREDIT_TOTALS,
  SET_INVOICE_TOTAL,
  RESET_PAYMENT_PROPS,
  RESET_PAYMENT_PAGE,
  SET_PAYMENT_CREDIT_CARD,
  SET_CVC_CODE,
  START_LOADER,
  STOP_LOADER,
  SET_PAYMENT_SUBMISSION_DETAILS,
} from './types'

// eslint-disable-next-line import/prefer-default-export
export const setInvoices = () => {
  return async dispatch => {
    dispatch({ type: START_LOADER, payload: 'retrievingInvoices' })
    const { data, error } = await resolveReq(
      axios.get(
        `/api/myaccount/transactions/invoices/paginate?limit=10000&status.value=open&orderBy=asc`,
      ),
    )
    if (error)
      // eslint-disable-next-line no-console
      return console.error(error)
    const { results } = data

    dispatch({ type: SET_INVOICES, payload: results })
    dispatch({ type: STOP_LOADER, payload: 'retrievingInvoices' })
    return results?.length
  }
}

export const setCreditInfo = () => {
  return async dispatch => {
    const { data, error } = await resolveReq(axios.get(`/api/payments/payments/getCreditInfo`))
    if (error)
      // eslint-disable-next-line no-console
      return console.error(error)
    const { /*totalCredits,*/ creditMemos, unappliedPayments } = data
    dispatch({ type: SET_CREDIT_MEMO, payload: creditMemos })
    dispatch({ type: SET_UNAPPLIED_PAYMENTS, payload: unappliedPayments })
    //dispatch({ type: SET_CREDIT_TOTALS, payload: totalCredits })
  }
}

export const resetReducerProp = ({ reducerProp, data }) => {
  return { type: RESET_PAYMENT_PROPS, payload: { data, reducerProp } }
}

export const calculateTotalAmount = reducerProp => {
  return { type: SET_INVOICE_TOTAL, payload: reducerProp }
}

export const resetPaymentPage = () => {
  return { type: RESET_PAYMENT_PAGE }
}

export const setPaymentCreditCard = creditCard => {
  return { type: SET_PAYMENT_CREDIT_CARD, payload: creditCard }
}

export const setCvcCode = cvcCode => {
  return { type: SET_CVC_CODE, payload: cvcCode }
}

export const submitPayment = body => {
  return async dispatch => {
    dispatch({ type: START_LOADER, payload: 'processingPaymentLoading' })
    const { data, error } = await resolveReq(
      axios.post(`/api/payments/payments/createPayment`, body),
    )
    if (error) {
      dispatch({ type: SET_PAYMENT_SUBMISSION_DETAILS, payload: {} })
      // eslint-disable-next-line no-console
      console.error(error)
    } else {
      dispatch({ type: SET_PAYMENT_SUBMISSION_DETAILS, payload: data })
    }
    dispatch({ type: STOP_LOADER, payload: 'processingPaymentLoading' })
  }
}
