import { dissoc } from 'ramda'
import {
  TOGGLE_ITEM_LIST_MODAL,
  TOGGLE_ITEM_LIST_DETAIL_MODAL,
  GET_LIST_DETAILS,
  GET_LIST_DETAIL,
  GET_ITEM_LISTS,
  SET_SELECTED_ITEM_LIST,
  SET_OPENED_ITEM_LIST,
  SHOW_ITEM_LIST_DETAILS,
  CHANGE_ITEM_LIST_DETAIL_PROP,
  DELETE_ITEM_LIST_DETAIL,
  TOGGLE_SHOW_SHARED_ITEM_LISTS,
  SET_ITEM_LIST_ADD_TO_LIST_CHECKBOX,
  DEC_INC_ITEM_LIST_COUNT,
  CHANGE_ITEM_LIST_PROPS,
} from '../actions/types'

const INITIAL_STATE = {
  itemListModal: false,
  viewListDetails: false,
  showSharedItemLists: false,
  openedItemList: {},
  itemLists: [],
  itemList: {},
  itemListDetails: [],
  itemListDetail: {},
  message: { message: '', type: '' },
}

export default (state = INITIAL_STATE, { type, payload }) => {
  switch (type) {
    case TOGGLE_ITEM_LIST_MODAL:
      return { ...state, itemListModal: payload }

    case TOGGLE_ITEM_LIST_DETAIL_MODAL:
      return { ...state, itemListDetailModal: payload }

    case GET_ITEM_LISTS:
      return { ...state, itemLists: payload }

    case GET_LIST_DETAILS:
      return { ...state, itemListDetails: payload }

    case SET_SELECTED_ITEM_LIST:
      return { ...state, itemList: payload }

    case GET_LIST_DETAIL:
      return { ...state, listDetail: payload }

    case SET_OPENED_ITEM_LIST:
      return { ...state, openedItemList: payload }

    case SHOW_ITEM_LIST_DETAILS:
      return { ...state, viewListDetails: payload }

    case TOGGLE_SHOW_SHARED_ITEM_LISTS:
      return { ...state, showSharedItemLists: payload }

    case CHANGE_ITEM_LIST_PROPS:
      return {
        ...state,
        itemLists: state.itemLists.map(il => {
          if (il.id === payload.id) return { ...il, ...payload.data }
          return il
        }),
      }

    case CHANGE_ITEM_LIST_DETAIL_PROP:
      return {
        ...state,
        itemListDetails: state.itemListDetails.map(ild => {
          if (ild.id === payload.id) return { ...ild, ...payload.data }
          return ild
        }),
      }
    case DELETE_ITEM_LIST_DETAIL:
      return {
        ...state,
        itemListDetails: state.itemListDetails.filter(ild => {
          return ild.id !== payload
        }),
      }
    case SET_ITEM_LIST_ADD_TO_LIST_CHECKBOX:
      return {
        ...state,
        itemLists: state.itemLists.map(l => {
          if (payload.itemListIds.includes(l.id)) return { ...l, ...payload.data }
          return dissoc(payload.itemId, l)
        }),
      }
    case DEC_INC_ITEM_LIST_COUNT:
      return {
        ...state,
        itemLists: state.itemLists.map(l => {
          if (payload.itemListId === l.id)
            return { ...l, itemListDetailCount: payload.func(l.itemListDetailCount) }
          return l
        }),
      }

    default:
      return state
  }
}
