import React, { useContext } from 'react'
import { ProductDetailsContext } from './ProductDetailsContext'
import { pathOr } from 'ramda'
import { Listbox, ListboxOption } from '@reach/listbox'
import DealmedListbox from '../../shared/components/DealmedListbox'
import '@reach/listbox/styles.css'

const ProductDetailsMatrixDropdown = ({ id, matrixItems, product, uom }) => {
  const productDetailsProps = useContext(ProductDetailsContext)

  const defaultItem = matrixItems[0].id

  const matrixProducts = pathOr(false, ['product', 'data', 'children'], productDetailsProps)

  const selectedProduct = matrixProducts.find(item => item.id === product.id)
  const selectedProductValue = selectedProduct.id

  const handleChange = newValue => {
    matrixItems.forEach(item => {
      if (item.id === newValue) {
        productDetailsProps.history.push(
          item.urlComponent + `?uom=${Object.values(item.matrixDetails)[0]}`,
        )
      }
    })
  }

  const listboxOptions = matrixItems
    .filter(item => item.matrixDetails)
    .map(item => {
      //Error handling: filters out items with no matrixDetails
      return {
        text: Object.values(item.matrixDetails)[0],
        value: item.id,
        disabled: false,
      }
    })

  return (
    <div className="product-details__qty-type">
      <span id={id} className="visually-hidden">
        Select Your Unit Type
      </span>
      <DealmedListbox
        arrow={false}
        options={listboxOptions}
        value={selectedProductValue && selectedProductValue ? selectedProductValue : defaultItem}
        aria-labelledby={id}
        className="product-details__qty-listbox"
        onChange={handleChange}
      />
    </div>
  )
}

export default ProductDetailsMatrixDropdown
