import React, { useContext, useReducer, useEffect, useRef, useState } from 'react'
import { connect } from 'react-redux'
import { Link } from 'react-router-dom'

import { useMediaQuery } from 'react-responsive'
import { selectCategory } from '../../actions'
import classnames from 'classnames'
import MainNavigationHeader from './MainNavigationHeader'
import MainNavigationMenu from './MainNavigationMenu'
import MainNavigationLink from './MainNavigationLink'
import Paginate from '../Reusable/Paginate'
import HamburgerMenuSVG from '../../shared/icons/HamburgerMenuSVG'
import { ContentfulContext } from '../../shared/ContentfulContext'
import { Statsig } from 'statsig-react'
import { ShoppingContext } from '../../shared/ShoppingContext'
import { mixpanelLogEvent } from '../../utils/mixpanelLogEvent'

const initialState = {
  mainNavigationIsOpen: false,
  activeMenus: [],
}

const mainNavigationReducer = (state, action) => {
  switch (action.type) {
    case 'OPEN_MAIN_NAVIGATION':
      return {
        ...state,
        mainNavigationIsOpen: true,
      }

    case 'CLOSE_MAIN_NAVIGATION':
      return {
        mainNavigationIsOpen: false,
        activeMenus: [],
      }

    case 'UPDATE_ACTIVE_MENUS':
      // If the menuId of the menu we just clicked on is already in the state,
      // we want to remove it and any menus at a higher depth than it
      if (state.activeMenus.some(menu => menu.menuId === action.payload.menuId)) {
        return {
          ...state,
          activeMenus: state.activeMenus.filter(menu => {
            if (menu.menuId !== action.payload.menuId && menu.depth < action.payload.depth) {
              return true
            }
          }),
        }
      }

      // If there exists a menu with the same depth as an active menu, we want
      // to remove the menu with the same depth and add the new one in
      if (state.activeMenus.some(menu => menu.depth === action.payload.depth)) {
        const filteredActiveMenus = state.activeMenus.filter(menu => {
          if (menu.depth < action.payload.depth) {
            return true
          }
        })

        return {
          ...state,
          activeMenus: [
            ...filteredActiveMenus,
            {
              menuId: action.payload.menuId,
              depth: action.payload.depth,
              menuElement: action.payload.menuElement,
            },
          ],
        }
      }

      // Otherwise, just simply add the menu to the state array
      return {
        ...state,
        activeMenus: [
          ...state.activeMenus,
          {
            menuId: action.payload.menuId,
            depth: action.payload.depth,
            menuElement: action.payload.menuElement,
          },
        ],
      }

    default:
      return state
  }
}

const MainNavigation = props => {
  const {
    categories = [],
    selectCategory,
    onSubCategoryClick,
    executeSearchAndRedirect,
    history,
  } = props

  const { setFacetsForFiltering } = useContext(ShoppingContext)

  const mainNavData = useContext(ContentfulContext)
    .filter(x => x.sys.contentType.sys.id == 'navBar')[0]
    .fields.navBarSections.filter(x => x.sys.contentType.sys.id == 'mainNavigation')[0]
    .fields.navigationSections

  const [{ mainNavigationIsOpen, activeMenus }, dispatch] = useReducer(
    mainNavigationReducer,
    initialState,
  )
  const mainNavigationRef = useRef(null)
  const mainNavigationToggleBtn = useRef(null)

  const currentlyActiveMenu =
    activeMenus.length > 0 ? activeMenus[activeMenus.length - 1].menuId : undefined

  const logClickEvent = ({ eventType, metaData }) => {
    mixpanelLogEvent({
      eventType,
      userDetails: props.auth.user,
      metaData,
    })
  }

  const MainNavigationItems = ({ data, index }) => {
    return (
      <>
        {data.sys.contentType.sys.id === 'mainNavigationDropdown' ? (
          <li className="dealmed-main-navigation__item" key={data.sys.id}>
            <MainNavigationMenu
              menuId={data.sys.id}
              isExpanded={activeMenus.some(menu => menu.menuId == data.sys.id)}
              isCurrentlyActive={
                currentlyActiveMenu !== undefined ? currentlyActiveMenu === data.sys.id : false
              }
              text={data.fields.title}
              depth={0}
              parentMenu="Main Menu"
              activeMenus={activeMenus}
              dispatch={dispatch}
            >
              {data.fields.pages.map((page, index) => (
                <li className="dealmed-main-navigation__item" key={index}>
                  <MainNavigationLink
                    path={page.fields.path}
                    text={page.fields.title}
                    onClick={() => dispatch({ type: 'CLOSE_MAIN_NAVIGATION' })}
                  />
                </li>
              ))}
            </MainNavigationMenu>
          </li>
        ) : (
          <li className="dealmed-main-navigation__item" key={index}>
            <MainNavigationLink
              path={data.fields.path}
              text={data.fields.title}
              onClick={() => dispatch({ type: 'CLOSE_MAIN_NAVIGATION' })}
            />
          </li>
        )}
      </>
    )
  }

  const MainNavLinks = mainNavData.map((item, index) => (
    <MainNavigationItems key={index} data={item} index={index + 1} />
  ))

  const handleOutsideClick = e => {
    if (!mainNavigationRef?.current?.contains(e.target)) {
      dispatch({ type: 'CLOSE_MAIN_NAVIGATION' })
    }
  }

  const handleBlur = e => {
    if (e.relatedTarget === null) {
      return
    }

    if (!e.currentTarget.contains(e.relatedTarget)) {
      dispatch({ type: 'CLOSE_MAIN_NAVIGATION' })

      if (!resize.matches) {
        mainNavigationToggleBtn.current?.focus()
      }
    }
  }

  const handleChange = () => {
    dispatch({ type: 'CLOSE_MAIN_NAVIGATION' })
  }

  const resize = useMediaQuery({ minWidth: 767 }, undefined, handleChange)

  const mainNavigationWrapperClasses = classnames('dealmed-main-navigation__wrapper', {
    'dealmed-main-navigation__wrapper--expanded': mainNavigationIsOpen,
  })

  // useEffect(() => {
  //   document.addEventListener("click", handleOutsideClick, {capture:true});
  //   return () => document.removeEventListener("click", handleOutsideClick);
  // }, []);

  useEffect(() => {
    //this way prevents memory leaks than ^^
    let isMounted = true

    document.addEventListener('click', handleOutsideClick, { capture: true })

    return () => {
      isMounted = false
      document.removeEventListener('click', handleOutsideClick)
    }
  }, [])

  const isMobileOrTablet = useMediaQuery({ maxWidth: 991 })

  return (
    <>
      <nav ref={mainNavigationRef} className="dealmed-main-navigation" id="main-navigation">
        <button
          ref={mainNavigationToggleBtn}
          type="button"
          className="dealmed-main-navigation__toggle-btn"
          aria-expanded={mainNavigationIsOpen}
          onClick={() => dispatch({ type: 'OPEN_MAIN_NAVIGATION' })}
        >
          <span className="visually-hidden">Open Main Navigation</span>
          <HamburgerMenuSVG className="dealmed-main-navigation__toggle-btn-icon" />
        </button>
        <div className={mainNavigationWrapperClasses} onBlur={handleBlur}>
          <MainNavigationHeader dispatch={dispatch} />
          <div className="dealmed-main-navigation__body">
            <ul className="dealmed-main-navigation__list">
              {/* Map through grandparent categories */}
              {categories.map(grandParent => {
                return (
                  <li className="dealmed-main-navigation__item" key={grandParent.id}>
                    <MainNavigationMenu
                      menuId={grandParent.id}
                      isExpanded={activeMenus.some(menu => menu.menuId === grandParent.id)}
                      isCurrentlyActive={
                        currentlyActiveMenu !== undefined
                          ? currentlyActiveMenu === grandParent.id
                          : false
                      }
                      text={grandParent.name}
                      depth={0}
                      parentMenu="Main Menu"
                      activeMenus={activeMenus}
                      dispatch={dispatch}
                    >
                      {/* Map through parent categories - matching original structure */}
                      {grandParent.children.map(parent => {
                        // const isCurrentlyActive = currentlyActiveMenu !== undefined ? currentlyActiveMenu === parent.id : false

                        return (
                          <li className={`dealmed-main-navigation__item`} key={parent.id}>
                            <MainNavigationMenu
                              menuId={parent.id}
                              categoryPath={parent.urlComponent}
                              // isExpanded={activeMenus.some((menu) => menu.menuId === parent.id)}
                              // isCurrentlyActive={isCurrentlyActive}
                              text={parent.name}
                              depth={1}
                              parentMenu={grandParent.name}
                              activeMenus={activeMenus}
                              dispatch={dispatch}
                              currentlyActiveMenu={currentlyActiveMenu}
                            >
                              {/* Map through children categories */}
                              {parent.children.map(child => {
                                return (
                                  <li className="dealmed-main-navigation__item" key={child.id}>
                                    <MainNavigationLink
                                      text={child.name}
                                      path={() => {
                                        const queryParam = child.urlComponent
                                        return (
                                          '/products/' +
                                          (queryParam && queryParam !== '' ? queryParam : '')
                                        )
                                      }}
                                      onClick={async () => {
                                        dispatch({ type: 'CLOSE_MAIN_NAVIGATION' })
                                        logClickEvent({
                                          eventType: `NavBar_Subcategory_Click`,
                                          metaData: { subcategory: child.name },
                                        })
                                        selectCategory(parent)
                                        setFacetsForFiltering({})
                                        await onSubCategoryClick({ subCategory: child })
                                        executeSearchAndRedirect()
                                      }}
                                    />
                                  </li>
                                )
                              })}
                            </MainNavigationMenu>
                          </li>
                        )
                      })}
                    </MainNavigationMenu>
                  </li>
                )
              })}
              {isMobileOrTablet && MainNavLinks}
            </ul>
          </div>
        </div>
      </nav>
    </>
  )
}

const mapStateToProps = state => {
  return {
    auth: state.auth,
    categories: state.shopping.categories,
  }
}

export default connect(mapStateToProps, { selectCategory })(Paginate(MainNavigation))
