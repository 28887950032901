import { includes } from 'ramda'

const getCreditCardType = (token, creditcardtype) => {
  if (includes('visa', token)) return creditcardtype['visa'].name
  else if (includes('amex', token)) return creditcardtype['amex'].name
  else if (includes('discover', token)) return creditcardtype['discover'].name
  else if (includes('mastercard', token)) return creditcardtype['mastercard'].name
  else if (includes('ach', token)) return creditcardtype['ach'].name
  else return 'unknown'
}

export { getCreditCardType }
