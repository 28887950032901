import React from 'react'
import classnames from 'classnames'

const SpeechBubbleSVG = ({ className }) => {
  return (
    <svg
      viewBox="0 0 32 32"
      className={classnames('dealmed-icon dealmed-speech-icon', className)}
      aria-hidden={true}
      focusable={false}
    >
      <path d="M16 6.4c-5.892 0-10.667 3.99-10.667 8.914 0 2.126 0.892 4.071 2.375 5.601-0.521 2.16-2.263 4.084-2.283 4.106-0.092 0.099-0.117 0.244-0.063 0.373s0.171 0.206 0.304 0.206c2.763 0 4.833-1.363 5.858-2.203 1.363 0.527 2.875 0.831 4.475 0.831 5.892 0 10.667-3.99 10.667-8.914s-4.775-8.914-10.667-8.914zM17.333 17.714c0 0.189-0.15 0.343-0.333 0.343h-6c-0.183 0-0.333-0.154-0.333-0.343v-0.686c0-0.189 0.15-0.343 0.333-0.343h6c0.183 0 0.333 0.154 0.333 0.343v0.686zM21.333 13.6c0 0.189-0.15 0.343-0.333 0.343h-10c-0.183 0-0.333-0.154-0.333-0.343v-0.686c0-0.189 0.15-0.343 0.333-0.343h10c0.183 0 0.333 0.154 0.333 0.343v0.686z"></path>
    </svg>
  )
}

export default SpeechBubbleSVG
