import React, { useContext } from 'react'
import { ProductDetailsContext } from './ProductDetailsContext'
import { Link } from 'react-router-dom'
import { pathOr } from 'ramda'
import { addDefaultSrc } from '../../utils'

const ProductDetailsAlternativeProduct = React.memo(
  ({ path, productImageSrc, productManufacturer, productName, productPrice }) => {
    const productDetailsProps = useContext(ProductDetailsContext)

    const isPriceVisible = pathOr(
      false,
      ['auth', 'user', 'permissions', 'isPriceVisible'],
      productDetailsProps,
    )

    return (
      <div className="product-details__alternative-product">
        <div className="product-details__alternative-product-slide">
          <Link to={path} className="product-details__alternative-product-link">
            <div className="product-details__alternative-product-image-wrapper">
              <img
                src={productImageSrc}
                className="product-details__alternative-product-image"
                onError={addDefaultSrc}
                alt="Related Product"
                loading="lazy"
              />
            </div>
            <div className="product-details__alternative-product-content">
              <p className="product-details__alternative-product-manufacturer">
                {productManufacturer}
              </p>
              <p className="product-details__alternative-product-name">{productName}</p>

              {isPriceVisible ? (
                <p className="product-details__alternative-product-price">{'$' + productPrice}</p>
              ) : (
                <p className="product-details__alternative-product-login">Log in to see price</p>
              )}
            </div>
          </Link>
        </div>
      </div>
    )
  },
)

export default ProductDetailsAlternativeProduct
