import React, { useState, useContext } from 'react'
import { ProductDetailsContext } from './ProductDetailsContext'
import { pathOr } from 'ramda'
import DealmedDialog from '../../shared/components/DealmedDialog'
import AddToItemListDropdown from '../ItemList/AddToItemListDropdown'
import ListSVG from '../../shared/icons/ListSVG'
import CloseSVG from '../../shared/icons/CloseSVG'

const ProductDetailsAddToListDialog = React.memo(({ product, defaultUnit }) => {
  const [showDialog, setShowDialog] = useState(false)

  const productDetailsProps = useContext(ProductDetailsContext)

  const productQuantity = productDetailsProps.quantitySelected
  const productUnits = productDetailsProps.uom

  const openDialog = () => {
    setShowDialog(true)
  }

  const closeDialog = () => {
    setShowDialog(false)
  }

  return (
    <>
      <button
        className="product-details__action-open"
        onClick={openDialog}
        aria-label="Open Add To List Dialog"
      >
        <ListSVG className="product-details__action-icon product-details__action-icon--red" />
        <div className="product-details__action-tooltip">
          <span className="product-details__action-tooltip-text">Add to List</span>
        </div>
      </button>
      <DealmedDialog
        isOpen={showDialog}
        onDismiss={closeDialog}
        className="product-details__action-dialog"
        label="Add to List Dialog"
      >
        <div className="product-details__action-content">
          <button
            className="product-details__action-close"
            onClick={closeDialog}
            aria-label="Close Add To List Dialog"
          >
            <CloseSVG className="product-details__action-icon product-details__action-icon--close" />
          </button>
          <h2 className="product-details__action-header">Add To List</h2>
          {product && (
            <AddToItemListDropdown
              quantity={productQuantity}
              itemId={product.id}
              unit={pathOr(null, ['value'], {
                ...defaultUnit,
                ...productUnits,
              })}
            />
          )}
        </div>
      </DealmedDialog>
    </>
  )
})

export default ProductDetailsAddToListDialog
