import React from 'react'

const ProductDetailsDescription = React.memo(({ productDescription }) => {
  return (
    <div
      dangerouslySetInnerHTML={{ __html: productDescription.replace(/((<ul>)|(<\/ul>))/g, '') }}
    />
  )
})

export default ProductDetailsDescription
