import { HIDE_NAVBAR, SHOW_NAVBAR, HIDE_FOOTER, SHOW_FOOTER } from '../actions/types'

const INITIAL_STATE = {
  hideNavbar: false,
  hideFooterTop: false,
}

export default (state = INITIAL_STATE, { type, payload }) => {
  switch (type) {
    case HIDE_NAVBAR:
      return { ...state, hideNavbar: true }
    case SHOW_NAVBAR:
      return { ...state, hideNavbar: false }
    case HIDE_FOOTER:
      return { ...state, hideFooterTop: true }
    case SHOW_FOOTER:
      return { ...state, hideFooterTop: false }
    default:
      return state
  }
}
