import React, { useContext } from 'react'
import { ProductDetailsContext } from './ProductDetailsContext'
import { pathOr } from 'ramda'
import ProductDetailsThumb from './ProductDetailsThumb'
import { addDefaultSrc } from '../../utils'

const { REACT_APP_STORAGE_URL, REACT_APP_CONT_PRODUCT_IMG } = process.env

const IMAGE_URL = REACT_APP_STORAGE_URL + REACT_APP_CONT_PRODUCT_IMG

const ProductDetailsImages = React.memo(({ productImageSrc, setProductImage }) => {
  const productDetailsProps = useContext(ProductDetailsContext)

  const productImageThumbs = pathOr([], ['product', 'data', 'itemImage'], productDetailsProps)

  return (
    <div className="product-details__image">
      <div className="product-details__image-wrapper">
        <img
          src={productImageSrc}
          className="product-details__product-image"
          onError={addDefaultSrc}
          alt="Product"
          loading="lazy"
        />
      </div>
      {productImageThumbs.length > 1 ? (
        <ul className="product-details__thumbs">
          {productImageThumbs.map(thumb => {
            return (
              <ProductDetailsThumb
                key={thumb.url}
                thumbImageSrc={
                  thumb.url && thumb.url.includes('http') ? thumb.largeUrl : IMAGE_URL + thumb.url
                }
                isSelected={productImageSrc === IMAGE_URL + thumb.url}
                setProductImage={setProductImage}
              />
            )
          })}
        </ul>
      ) : null}
    </div>
  )
})

export default ProductDetailsImages
