import React, { Component } from 'react'
import { pathOr } from 'ramda'
import { connect } from 'react-redux'
import {
  stopLoader,
  startLoader,
  toggleItemListModal,
  toggleItemListDetailModal,
  addNewItemList,
  getItemLists,
  deleteItemList,
  getListDetails,
  getListDetail,
  getItemListsByItemId,
  setItemList,
  editItemList,
  upsertItemListDetail,
  setOpenedItemList,
  showItemListDetails,
  deleteItemListDetail,
  addItemListDetail,
  changeItemListDetailProps,
  toggleShowSharedItemLists,
  deleteItemListDetailByItemListId,
  changeItemListProps,
} from '../../actions'

export default ComposedComponent => {
  // eslint-disable-next-line react/prefer-stateless-function
  class ItemListHOC extends Component {
    render() {
      return <ComposedComponent {...this.props} />
    }
  }

  const mapStateToProps = ({ itemList, loader, auth }) => {
    return { ...itemList, loader, userId: pathOr(null, ['user', 'id'], auth) }
  }

  return connect(mapStateToProps, {
    toggleItemListModal,
    toggleItemListDetailModal,
    deleteItemList,
    addNewItemList,
    editItemList,
    upsertItemListDetail,
    getItemLists,
    getListDetails,
    getListDetail,
    getItemListsByItemId,
    addItemListDetail,
    setItemList,
    setOpenedItemList,
    showItemListDetails,
    deleteItemListDetail,
    changeItemListDetailProps,
    toggleShowSharedItemLists,
    deleteItemListDetailByItemListId,
    stopLoader,
    changeItemListProps,
    startLoader,
  })(ItemListHOC)
}
