import React, { useState, useEffect, useRef, Fragment } from 'react'
import classnames from 'classnames'
import LeftChevronSVG from '../../shared/icons/LeftChevronSVG'
import RightChevronSVG from '../../shared/icons/RightChevronSVG'
import { Statsig } from 'statsig-react'

import { useMediaQuery } from 'react-responsive'

const mql = window.matchMedia('(min-width: 62em)')

const MainNavigationMenu = ({
  menuId,
  categoryPath,
  isExpanded,
  isCurrentlyActive,
  text,
  depth,
  parentMenu,
  activeMenus,
  dispatch,
  children,
  currentlyActiveMenu,
  opensOnHover,
  onCategoryClick,
}) => {
  const [isVisible, setIsVisible] = useState(false)
  const mainNavigationMenuRef = useRef(null)
  const isMobileOrTablet = useMediaQuery({ maxWidth: 991 })

  let mainNavigationMenuClasses

  // Update visibility when menu expands
  useEffect(() => {
    if (isExpanded) {
      setIsVisible(true)
    }
  }, [isExpanded])

  // Close menu on mobile/tablet view
  useEffect(() => {
    if (isMobileOrTablet) {
      setIsVisible(false)
      dispatch({ type: 'CLOSE_MAIN_NAVIGATION' })
    }
  }, [isMobileOrTablet])

  /*
		As of right now, this component is only used for desktop.
		So we'll keep visibility as long as we are on depth 0 and we explicitly close the menu/set isVisible to false.
		We do this to avoid flickering when switching between menus, and when a grandparent menu is clicked and the menu is opened again.
	 */
  // Stay visible if we are switching between grandparent menus
  const stayVisible = (depth === 0 && isVisible) || isExpanded

  if (depth === 0) {
    mainNavigationMenuClasses = classnames('dealmed-main-navigation__menu', {
      'dealmed-main-navigation__menu--expanded': isExpanded,
      'dealmed-main-navigation__menu--visible': stayVisible,
      'dealmed-main-navigation__menu--overflow': isCurrentlyActive,
    })
  } else {
    mainNavigationMenuClasses = classnames('dealmed-main-navigation__submenu', {
      'dealmed-main-navigation__submenu--expanded': isExpanded,
      'dealmed-main-navigation__submenu--visible': stayVisible,
      'dealmed-main-navigation__submenu--overflow': isCurrentlyActive,
    })
  }

  const handleBlur = e => {
    if (e.relatedTarget === null) {
      return
    }

    // Close the menu if we click outside of it
    if (!e.currentTarget.contains(e.relatedTarget)) {
      setIsVisible(false)
      // This is causing multiple calls to dispatch and messing things up!
      // dispatch({ type: 'UPDATE_ACTIVE_MENUS', payload: { menuId: menuId, depth: depth, menuElement: mainNavigationMenuRef.current } });
    }
  }

  // const handleTransitionEnd = (e) => {
  // 	if (e.currentTarget === e.target && e.propertyName === 'transform') {
  // 		if (!isExpanded) {
  // 			setIsVisible(false);
  // 		}
  // 	}
  // };

  const logStatsigEvent = linkTitle => {
    Statsig.logEvent('Nav_Button_Click', linkTitle)
  }

  // Handle close button click
  const handleClose = () => {
    setIsVisible(false)
    dispatch({ type: 'CLOSE_MAIN_NAVIGATION' })
  }

  // useEffect(() => {
  // 	if (mql.matches) {
  // 		setIsVisible(isExpanded);
  // 		return;
  // 	}

  // 	if (isExpanded) {
  // 		setIsVisible(true);
  // 	}
  // }, [isExpanded]);

  return (
    <>
      <button
        type="button"
        // This adds a style to make the grandparent active, I've added this class in the desktop portion of the styles.
        // Feel free to refactor this in however you see fit
        // Ex Surgical (grandparent) depth 0 -> Clinical Laboratory Supplies (parent) depth 1 -> Brushed (grandchild)
        className={`dealmed-main-navigation__btn ${
          depth === 0 && isCurrentlyActive ? 'dealmed-main-navigation__btn--grandparent-active' : ''
        }`}
        // If we are a parent (depth 1), we don't want to change the cursor to pointer ** TEMP SOLUTION **
        style={{
          cursor: depth === 1 ? 'default' : 'pointer',
        }}
        onMouseEnter={() => {
          if (opensOnHover && !isCurrentlyActive) {
            dispatch({
              type: 'UPDATE_ACTIVE_MENUS',
              payload: { menuId, depth, menuElement: mainNavigationMenuRef.current },
            })
            for (const menu of activeMenus) {
              menu.menuElement.scrollTo(0, 0)
            }
            // logStatsigEvent(text)
          }
        }}
        onClick={() => {
          /*
						This component is just used for desktop. So, if you're a parent you can't be clicked
						Only grandchildren can be clicked.
						Grandparent (depth 0) can be clicked -> Parent (depth 1) cannot be clicked -> Grandchild can be clicked
					*/

          if (!isMobileOrTablet && depth === 1) return // this prevents a parent click making the grandparent not 'currently active'

          /* 
						If the menu opensOnHover, then an onClick event navigates you 
						to a category page.  We use the onCategoryClick (which should exist)
						passed from the parent.
					*/
          if (opensOnHover && onCategoryClick) {
            onCategoryClick(categoryPath || menuId)
            logStatsigEvent(text)
          } else {
            dispatch({
              type: 'UPDATE_ACTIVE_MENUS',
              payload: {
                menuId: menuId,
                depth: depth,
                menuElement: mainNavigationMenuRef.current,
              },
            })

            for (const menu of activeMenus) {
              menu.menuElement.scrollTo(0, 0)
            }

            logStatsigEvent(text)
          }
        }}
      >
        <span
          // We don't want a text-decoration on hover for parents
          // TEMP SOLUTION is removing the text-decoration on hover in & .dealmed-main-navigation__btn-text
          className={`dealmed-main-navigation__btn-text`}
        >
          {text}
        </span>
      </button>
      <div
        ref={mainNavigationMenuRef}
        className={mainNavigationMenuClasses}
        onBlur={handleBlur}
        // onTransitionEnd={handleTransitionEnd}
      >
        <ul className="dealmed-main-navigation__list">
          {/* <li className="dealmed-main-navigation__item">
						<button
							type="button"
							className="dealmed-main-navigation__back-btn"
							onClick={() =>
								dispatch({ type: 'UPDATE_ACTIVE_MENUS', payload: { menuId: menuId, depth: depth, menuElement: mainNavigationMenuRef.current } })
							}
						>
							<span className="dealmed-main-navigation__icon-wrapper" >
								<LeftChevronSVG className="dealmed-main-navigation__back-btn-icon" />
							</span>
							<span className="dealmed-main-navigation__back-btn-text" >Back to {parentMenu}</span>
						</button>
					</li> */}
          {children}
        </ul>
        <button className="dealmed-main-navigation__btn-close" onClick={handleClose}>
          <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24">
            <title>window-close</title>
            <path d="M13.46,12L19,17.54V19H17.54L12,13.46L6.46,19H5V17.54L10.54,12L5,6.46V5H6.46L12,10.54L17.54,5H19V6.46L13.46,12Z" />
          </svg>
        </button>
      </div>
    </>
  )
}

export default MainNavigationMenu
