import React, { Component, Fragment } from 'react'
import { includes, pathOr } from 'ramda'
import { Row, Col, Label, Container } from 'reactstrap'
import { connect } from 'react-redux'
import { Link, NavLink, withRouter } from 'react-router-dom'
import { getItemList, selectCategory } from '../../actions'
import TaSearchBar from '../SearchBar/SearchBar'
import Paginate from '../Reusable/Paginate'
import NavCart from './NavCart'
import NavDropDown from './NavDropDown'
import Sidebar from '../Sidebar/Sidebar'
import LoginButton from './LoginButton'
import ReorderButton from './ReorderButton'
import './Navbar.scss'
import '../SearchBar/SearchBar.scss'
import { navbar } from 'reactstrap'
import SupportDropdown from './SupportDropdown'
import Dropdown from './Dropdown'
import ResourcesDropdown from './ResourcesDropdown'
import { current } from 'immer'
import { toggleView } from '../../actions'

const showSandboxLogo =
  process.env.REACT_APP_ENVTYPE == 'staging' || process.env.REACT_APP_ENVTYPE == 'development'

const { PUBLIC_URL } = process.env

class Navbar extends Component {
  componentDidUpdate(previousProps) {
    const currentPath = this.props.location.pathname
    const previousPath = previousProps.location.pathname
    const isNewRoute = currentPath != previousPath

    if (isNewRoute && !includes('products', currentPath)) this.props.setSearchTerm('')
  }

  onSearchBarChange = value => {
    this.props.setSearchTerm(value)
    this.onTypeAheadSearch(value)
  }

  onSearchBarClick = async () => {
    const { searchTerm, PRODUCT_OBJ, setPaginationFields, executeSearchAndRedirect } = this.props
    const trimmedSearchTerm = searchTerm.trim()

    if (!trimmedSearchTerm) return
    await this.props.resetSelectedCategory()
    setPaginationFields({
      reducerProp: PRODUCT_OBJ.reducerProp,
      data: { numPaginateSection: 1, page: 1 },
    })
    this.props.setSearch(trimmedSearchTerm)
    executeSearchAndRedirect()
  }

  onTypeAheadSearch = val => {
    const trimmedVal = val.trim()
    if (trimmedVal.length < 3 || !trimmedVal) return
    this.props.getItemList({ search: trimmedVal })
  }

  render() {
    const {
      searchTerm,
      itemListLoading,
      itemList,
      categories,
      selectCategory,
      isAuthenticated,
      accountLoading,
      firstName,
    } = this.props
    const solutionContent = [
      { route: '', text: 'Medical Supply Distribution' },
      { route: '', text: 'Dealmed 3PL Services' },
      { route: '', text: 'Medical Equipment & Installation' },
      { route: '', text: 'Contracting' },
      { route: '', text: 'Private Labeling & Manufacturing' },
    ]
    const whoWeServeContent = [
      { route: '', text: 'Hospitals & IDNs' },
      { route: '', text: 'Physician Offices' },
      { route: '', text: 'Urgent Care & Community Health Centers' },
      { route: '', text: 'Ambulatory Surgery Centers' },
      { route: '', text: 'Dialysis Centers' },
      { route: '', text: 'EMS & First Responders' },
      { route: '', text: 'Government Agencies' },
      { route: '', text: 'Consumers' },
    ]
    return (
      <div className="header-navbar">
        <div className="header-navbar d-none d-lg-flex flex-column">
          <div style={{ justifyContent: 'flex-end' }} className="d-flex nav-top-row">
            <a className="nav-phone" href="tel:1-800-569-0570">
              <i className="mdi mdi-phone nav-phone-icon p-2" />
              1-800-569-0570
            </a>
            <div style={{ padding: '10px' }}>
              {!accountLoading && !isAuthenticated && (
                <Link className="create-account" to="/register">
                  Register for a Dealmed Runtime&#8482; Account
                </Link>
              )}
            </div>
            {/* <div className="nav-purchasing-audit">FREE PURCHASING AUDIT</div> */}
          </div>
          <hr style={{ margin: 0 }} />
          <div className="d-flex align-items-center justify-content-between nav-main-row">
            <Link to="/">
              <img src={`${PUBLIC_URL}/images/dealmed_logo_mobile.png`} alt="" loading="lazy" />
            </Link>
            <Col xs="4" s="5" className="searchbar-wrapper">
              <TaSearchBar
                default={searchTerm}
                value={searchTerm}
                isLoading={itemListLoading}
                placeholder="Search for products"
                onClick={this.onSearchBarClick}
                onChange={this.onSearchBarChange}
                options={itemList}
              />
            </Col>
            <div className="d-flex justify-content-around align-items-center col">
              {/* <ResourcesDropdown className="main-nav-dropdown" navItemName='Resources' /> */}
              <NavLink to="/resources" className="main-nav-dropdown">
                Resources
              </NavLink>
              <SupportDropdown className="main-nav-dropdown" navItemName="Support" />
              <div>
                {firstName && <Label style={{ fontSize: '13px' }}>Hello, {firstName}!</Label>}
                <LoginButton />
                {firstName && <div style={{ paddingBottom: '30px' }}></div>}
              </div>
            </div>
            <NavCart targetId="NavCartPopupTrigger" />
          </div>

          <hr style={{ margin: 0 }} />
          <Row>
            <div className="d-flex nav-bottom-row col" style={{ padding: 0 }}>
              <NavDropDown
                className="flex-fill tab"
                navItemName="PRODUCTS"
                categories={categories}
                selectCategory={selectCategory}
              />
              {/* <Dropdown className="flex-fill tab" navItemName='SOLUTIONS' content={solutionContent} /> */}
              {/* <Dropdown className="flex-fill tab" navItemName='WHO WE SERVE' content={whoWeServeContent} /> */}
              <NavLink to="/marketsserved" className="border-right border-left flex-fill tab">
                WHO WE SERVE
              </NavLink>
              <NavLink to="/about" className="border-right border-left flex-fill tab">
                COMPANY
              </NavLink>
            </div>
          </Row>
        </div>
        {/* MOBILE NAV */}
        <Row className="d-lg-none nav-mobile">
          <Col>
            <Sidebar />
          </Col>
          <Col>
            <Link to="/">
              <img
                className="nav-logo-mobile"
                src={`${PUBLIC_URL}/images/dealmed_logo_mobile.png`}
                alt="Dealmed Logo"
                loading="lazy"
              />
            </Link>
          </Col>
          <Col xs="2" sm="1">
            <NavCart noPopover />
          </Col>
          <Col xs="2" className="text-center">
            <ReorderButton />
          </Col>
        </Row>
        <Row className="d-lg-none mb-2 mt-2">
          <Col className="searchbar-wrapper">
            <TaSearchBar
              value={searchTerm}
              placeholder="Search for products"
              isLoading={itemListLoading}
              typeAheadSearch={this.onTypeAheadSearch}
              onClick={this.onSearchBarClick}
              onChange={this.onSearchBarChange}
              className="header-searchbar mr-3 ml-3"
              options={itemList}
            />
          </Col>
        </Row>
      </div>
    )
  }
}

const ms2p = ({
  loader: { itemListLoading, accountLoading },
  shopping: { itemList, categories },
  auth,
}) => {
  return {
    itemListLoading,
    itemList,
    categories,
    isAuthenticated: pathOr(false, ['user', 'id'], auth),
    accountLoading,
    firstName: pathOr('', ['user', 'firstName'], auth),
  }
}

export default withRouter(connect(ms2p, { getItemList, selectCategory })(Paginate(Navbar)))
// Until we update to react-router-dom 5, withRouter is outermost to get around a navlink bug that prevents it from rerendering and being active
