import React, { useEffect, useRef } from 'react'
import { Link } from 'react-router-dom'
import ContentPopover from './ContentPopover'

const MainNavigationItems = ({ data, index, activePopoverId, setActivePopoverId }) => {
  const handlePopoverClick = id => {
    setActivePopoverId(prevId => (prevId === id ? null : id))
  }

  return (
    <li className="dealmed-header__info-item" key={data.sys.id}>
      {data.sys.contentType.sys.id === 'mainNavigationDropdown' ? (
        <ContentPopover
          data={data}
          isOpen={activePopoverId === data.sys.id}
          togglePopover={() => handlePopoverClick(data.sys.id)}
          className="dealmed-header__support-popover"
        />
      ) : (
        <Link to={data.fields.path} className="dealmed-header__resources-link">
          {data.fields.title}
        </Link>
      )}
    </li>
  )
}

export default MainNavigationItems
