import React from 'react'
import classnames from 'classnames'

const ShoppingBagSVG = ({ className }) => {
  return (
    <svg
      viewBox="0 0 32 32"
      className={classnames('dealmed-icon', className)}
      aria-hidden={true}
      focusable={false}
    >
      <path d="M21.487 10.667c0-2.941-2.461-5.333-5.486-5.333s-5.486 2.393-5.486 5.333h-4.114v12.667c0 1.841 1.535 3.333 3.429 3.333h12.343c1.894 0 3.429-1.492 3.429-3.333v-12.667h-4.114zM16.001 7.333c1.891 0 3.429 1.495 3.429 3.333h-6.857c0-1.838 1.538-3.333 3.429-3.333zM23.544 23.333c0 0.735-0.615 1.333-1.371 1.333h-12.343c-0.756 0-1.371-0.598-1.371-1.333v-10.667h2.057v1.667c0 0.552 0.46 1 1.029 1s1.029-0.448 1.029-1v-1.667h6.857v1.667c0 0.552 0.46 1 1.029 1s1.029-0.448 1.029-1v-1.667h2.057v10.667z"></path>
    </svg>
  )
}

export default ShoppingBagSVG
