import React, { useState, useEffect, useRef, Fragment } from 'react'
import classnames from 'classnames'
import LeftChevronSVG from '../../shared/icons/LeftChevronSVG'
import RightChevronSVG from '../../shared/icons/RightChevronSVG'
import { Statsig } from 'statsig-react'
import SumIconSVG from '../../shared/icons/SumIconSVG'
import RestIconSVG from '../../shared/icons/RestIconSVG'

const mql = window.matchMedia('(min-width: 62em)')

const MainNavigationMenuMobile = ({
  menuId,
  categoryPath,
  isExpanded,
  isCurrentlyActive,
  text,
  depth,
  parentMenu,
  activeMenus,
  dispatch,
  children,
  currentlyActiveMenu,
  opensOnHover,
  onCategoryClick,
}) => {
  const [isVisible, setIsVisible] = useState(false)
  const mainNavigationMenuRef = useRef(null)
  let mainNavigationMenuClasses

  if (depth === 0) {
    mainNavigationMenuClasses = classnames('mobile-dealmed-main-navigation__menu', {
      'mobile-dealmed-main-navigation__menu--expanded': isExpanded,
      'mobile-dealmed-main-navigation__menu--visible': isVisible,
    })
  } else if (depth === 1 && isExpanded && isVisible) {
    mainNavigationMenuClasses = classnames('mobile-dealmed-main-navigation__submenu', {
      'mobile-dealmed-main-navigation__submenu--expanded': isExpanded,
      'mobile-dealmed-main-navigation__submenu--visible': isVisible,
    })
  } else {
    mainNavigationMenuClasses = 'mobile-dealmed-main-navigation__submenu'
  }

  const handleBlur = e => {
    if (e.relatedTarget === null) {
      return
    }

    if (!e.currentTarget.contains(e.relatedTarget)) {
      dispatch({
        type: 'UPDATE_ACTIVE_MENUS',
        payload: { menuId: menuId, depth: depth, menuElement: mainNavigationMenuRef.current },
      })
    }
  }

  const handleTransitionEnd = e => {
    if (e.currentTarget === e.target && e.propertyName === 'transform') {
      if (!isExpanded) {
        setIsVisible(false)
      }
    }
  }

  const logStatsigEvent = linkTitle => {
    Statsig.logEvent('Nav_Button_Click', linkTitle)
  }

  useEffect(() => {
    if (mql.matches) {
      setIsVisible(isExpanded)
      return
    }

    if (isExpanded) {
      setIsVisible(true)
    }
  }, [isExpanded])

  return (
    <>
      <button
        type="button"
        className={`mobile-dealmed-main-navigation__btn`}
        onMouseEnter={() => {
          if (opensOnHover && !isCurrentlyActive) {
            dispatch({
              type: 'UPDATE_ACTIVE_MENUS',
              payload: { menuId, depth, menuElement: mainNavigationMenuRef.current },
            })
            for (const menu of activeMenus) {
              menu.menuElement.scrollTo(0, 0)
            }
          }
        }}
        onClick={() => {
          if (opensOnHover && onCategoryClick) {
            onCategoryClick(categoryPath || menuId)
            logStatsigEvent(text)
          } else {
            dispatch({
              type: 'UPDATE_ACTIVE_MENUS',
              payload: {
                menuId: menuId,
                depth: depth,
                menuElement: mainNavigationMenuRef.current,
              },
            })

            for (const menu of activeMenus) {
              menu.menuElement.scrollTo(0, 0)
            }

            logStatsigEvent(text)
          }
        }}
      >
        <span className={`mobile-dealmed-main-navigation__btn-text`}>{text}</span>
        <span className="mobile-dealmed-main-navigation__icon-wrapper">
          <RightChevronSVG className="mobile-dealmed-main-navigation__btn-icon" />
        </span>
      </button>
      <div
        ref={mainNavigationMenuRef}
        className={mainNavigationMenuClasses}
        onBlur={handleBlur}
        onTransitionEnd={handleTransitionEnd}
      >
        <ul className="mobile-dealmed-main-navigation__list">
          <li className="mobile-dealmed-main-navigation__item">
            <button
              type="button"
              className="mobile-dealmed-main-navigation__back-btn"
              onClick={() =>
                dispatch({
                  type: 'UPDATE_ACTIVE_MENUS',
                  payload: {
                    menuId: menuId,
                    depth: depth,
                    menuElement: mainNavigationMenuRef.current,
                  },
                })
              }
            >
              <span className="mobile-dealmed-main-navigation__icon-wrapper">
                <LeftChevronSVG className="mobile-dealmed-main-navigation__back-btn-icon" />
              </span>
              <span className="mobile-dealmed-main-navigation__back-btn-text">Back</span>
            </button>
            <span className="main-navigation-title-mobile__btn-text--submenu-title">{text}</span>
          </li>
          {children}
        </ul>
      </div>
    </>
  )
}

export default MainNavigationMenuMobile
