import React, { Component } from 'react'
import { Link, NavLink } from 'react-router-dom'
import './Navbar.scss'

class SupportDropdown extends Component {
  state = { show: false }
  render() {
    let showList = () => {
      this.setState({ show: true })
    }

    let hideList = () => {
      this.setState({ show: false })
    }

    return (
      <div
        className="main-nav-dropdown"
        onMouseEnter={() => showList()}
        onMouseLeave={() => hideList()}
      >
        <span>
          {this.props.navItemName} <i className="mdi mdi-chevron-down" />
        </span>
        <div
          style={{ paddingTop: '15px' }}
          className={`drop-down ${this.state.show ? 'show' : ''}`}
          id="header-drop-down-box"
        >
          <a className="support-phone s-hover" href="tel:1-800-569-0570">
            <i className="mdi mdi-phone nav-phone-icon p-2 s-icon" />
            1-800-569-0570
          </a>
          <div className="support-icon s-hover">
            <NavLink to="/contactus" className="s-hover">
              <i className="mdi mdi-chat nav-phone-icon p-2 s-icon" />
              Contact
              {/* Live Chat */}
            </NavLink>
          </div>
          <a href="mailto:support@dealmed.com">
            <div className="support-icon s-hover">
              <i className="mdi mdi-email nav-phone-icon p-2 s-icon" />
              support@dealmed.com
            </div>
          </a>
          <div style={{ paddingBottom: '15px' }} className="support-icon">
            <i className="mdi mdi-clock nav-phone-icon p-2 s-icon" />
            Mon-Fri 9 am - 5 pm EST
          </div>
        </div>
      </div>
    )
  }
}

export default SupportDropdown
