import React from 'react'
import classnames from 'classnames'
const RestIconSVG = ({ className }) => {
  return (
    <svg
      viewBox="0 0 25 25"
      className={classnames('dealmed-icon', className)}
      aria-hidden={true}
      focusable={false}
    >
      <path
        fill-rule="evenodd"
        clip-rule="evenodd"
        d="M19.1733 12.8345C19.1733 13.3715 18.7381 13.8068 18.2011 13.8068L6.14556 13.8067C5.60862 13.8067 5.17334 13.3715 5.17334 12.8345C5.17334 12.2976 5.60862 11.8623 6.14556 11.8623L18.2011 11.8623C18.7381 11.8623 19.1733 12.2976 19.1733 12.8345Z"
        fill="#3B4245"
      />
    </svg>
  )
}
export default RestIconSVG
