////MY ACCOUNT
export const TOGGLE_MENU_DROPDOWN = 'toggle_menu_dropdown'
export const SET_ACTIVE_MENU = 'set_active_menu'
export const TOGGLE_HAMBURGER_MENU = 'toggle_hamburger_menu'
export const SET_USER_NAME_AVAILABILITY = 'set_username_availability'
export const SET_CONTACT_PROPS = 'set_contact_props'
export const SET_REORDER_PROPS = 'set_reorder_props'
export const SET_CREDIT_CARD_PROP = 'set_credit_card_prop'
export const UPDATE_CREDIT_CARD = 'update_credit_card'
export const UPDATE_ADDRESS_BOOK = 'update_address_book'
export const SET_ADDRESS_BOOK_PROP = 'set_address_book_prop'
export const SET_SALESREP = 'set_salesrep'
export const SET_IS_SIDEBAR_COLLAPSED = 'set_is_sidebar_collapsed'
export const SET_OVERVIEW_DATA = 'set_overview_data'
export const SET_ALLOCATION_MAX = 'set_allocation_max'

////LOGIN
export const TOGGLE_LOGIN_VIEW = 'toggle_login_view'
export const SET_USER_AUTH = 'set_user_auth'
export const SET_IS_PASSWORD_RESET_VALID = 'set_is_password_reset_valid'
export const SET_AUTH_ALERT = 'set_auth_alert'
export const SET_IS_VALID_TOKEN = 'set_is_valid_token'
export const GET_BUSINESS_TYPES = 'get_business_types'
export const GET_CONTACT_ROLES = 'get_contact_roles'
export const START_PUNCHOUT = 'start_punchout'
export const END_PUNCHOUT = 'end_punchout'

////LOADER
export const START_LOADER = 'start_loader'
export const STOP_LOADER = 'stop_loader'

////PAGINATE
export const RESET_PAGINATE = 'clear_paginate'
export const SET_PAGINATE_FIELD = 'set_paginate_field'
export const CONCAT_TO_PRODUCTS = 'concat_to_products'

////CRUD PROPS
export const SET_USER_PROPS = 'set_user_props'
export const SET_COMPANY_PROFILE_PROPS = 'set_company_profile_props'

////LIST
export const SET_LIST = 'set_list'

//ITEM LIST
export const SET_SELECTED_ITEM_LIST = 'set_selected_item_list'
export const GET_ITEM_LISTS = 'get_item_lists'
export const GET_LIST_DETAILS = 'get_list_details'
export const GET_LIST_DETAIL = 'get_list_detail'
export const GET_LIST_GENERAL_INFO = 'get_list_general_info'
export const SET_OPENED_ITEM_LIST = 'set_opened_item_list'
export const SHOW_ITEM_LIST_DETAILS = 'show_item_list_details'
export const CHANGE_ITEM_LIST_DETAIL_PROP = 'change_item_list_detail_prop'
export const DELETE_ITEM_LIST_DETAIL = 'delete_item_list_detail'
export const TOGGLE_SHOW_SHARED_ITEM_LISTS = 'toggle_show_shared_item_lists'
export const SET_ITEM_LIST_ADD_TO_LIST_CHECKBOX = 'set_item_list_add_to_list_checkbox'
export const DEC_INC_ITEM_LIST_COUNT = 'dec_inc_item_list_count'
export const CHANGE_ITEM_LIST_PROPS = 'change_item_list_props'

////SHOPPING
export const SET_SHOPPING_FILTERS = 'set_shopping_filters'
export const SET_SHOPPING_FILTERS2 = 'set_shopping_filters2'
export const SET_SELECTED_SHOPPING_FILTER = 'set_selected_shopping_filter'
export const SET_PRODUCT_PROPS = 'set_product_props'
export const SET_SEARCH_TERM = 'set_search_term'
export const SET_PRODUCT = 'set_product'
export const SET_ALTERNATIVE_ITEMS = 'set_alternative_items'
export const SET_CORRELATED_ITEMS = 'set_correlated_items'
export const RESET_PRODUCT = 'set_product_null'
export const ADD_TO_CART = 'add_to_cart'
export const SET_ITEM_LIST = 'set_item_list'
export const SET_CATEGORIES = 'set_categories'
export const SELECT_CATEGORY = 'select_category'
export const SELECT_SUBCATEGORY = 'select_subcategory'
export const RESET_SELECTED_CATEGORY = 'reset_selected_category'
export const SET_SEARCH = 'set_search'
export const TOGGLE_ITEM_LIST_MODAL = 'toggle_item_list_modal'
export const TOGGLE_ITEM_LIST_DETAIL_MODAL = 'toggle_item_list_detail_modal'
export const SET_UOM = 'set_uom'
export const SET_SELLABLE_CSLS = 'set_sellable_csls'
export const CHANGE_QUANTITY = 'change_quantity'
// AE
export const RESET_PRODUCT_MANUFACTURERS = 'reset_product_manufacturers'
export const ADD_PRODUCT_MANUFACTURER = 'add_product_manufacturer'
export const REMOVE_PRODUCT_MANUFACTURER = 'remove_product_manufacturer'
export const RESET_PRODUCT_RX_LEVEL = 'reset_product_rx_level'
export const SET_PRODUCT_RX_LEVEL = 'set_product_rx_level'
export const SET_PRODUCT_AVAILABILITY = 'set_product_availability'
export const RESET_PRODUCT_AVAILABILITY = 'reset_product_availability'
export const SET_PRODUCT_SORT_BY = 'set_product_sort_by'
export const SET_PRODUCT_SORT_BY_FILTER = 'set_product_sort_by_filter'
export const RESET_PRODUCT_SORT_BY_FILTER = 'reset_product_sort_by_filter'
// End AE

////CART
export const SET_QUANTITY_INPUT = 'set_quantity_input'
export const EDIT_QUANTITY_INPUT = 'edit_quantity_input'
export const REMOVE_QUANTITY_INPUT = 'remove_quantity_input'
export const SET_CART_DETAILS = 'set_cart_details'
export const EDIT_CART_DETAIL = 'edit_cart_detail'
export const REMOVE_CART_DETAIL = 'remove_cart_detail'
export const REMOVE_SAVE_DETAIL = 'remove_save_detail'
export const SET_ORDER_SUMMARY = 'set_order_summary'
export const SET_CART_LOADING = 'set_cart_loading'
export const TOGGLE_CART_POPOVER = 'toggle_cart_popover'
export const MERGE_DUPLICATES = 'merge_duplicates'
export const MOVE_ITEM_TO_SAVE = 'move_item_to_save'
export const MOVE_ITEM_TO_CART = 'move_item_to_cart'
export const SET_SAVE_FOR_LATER = 'set_save_for_later'
export const CLEAR_CART_DETAILS = 'clear_cart_details'
export const CLEAR_CART_NOTIFICATIONS = 'clear_cart_notifications'

////CHECKOUT
export const SET_TERM_DETAILS = 'set_term_details'
export const SET_CHECKOUT_ERROR_MESSAGES = 'set_checkout_error_messages'
export const SET_CHECKOUT_CREDIT_CARD = 'set_checkout_credit_card'
export const SET_SUBMISSION_DETAILS = 'set_confirmation_number'
export const SET_STATUSES = 'set_statuses'
export const HIDE_NAVBAR = 'hide_navbar'
export const SHOW_NAVBAR = 'show_navbar'
export const RESET_TAX = 'reset_tax'
export const RESET_CONVENIENCE_FEE = 'reset_convenience_fee'
export const HIDE_FOOTER = 'hide_footer'
export const SHOW_FOOTER = 'show_footer'

/////TRANSACTIONS
export const SET_SALES_ORDER = 'set_sales_order'
export const SET_SALES_ORDERS = 'set_sales_orders'
export const SET_SALES_ORDERS_FILTERS = 'set_sales_orders_filters'
export const SET_STATUS_LIST = 'set_status_list'

////ESTIMATES
export const SET_ESTIMATE = 'set_estimate'
export const SET_ESTIMATE_FILTERS = 'set_estimates_filters'

/////PAYMENTS
export const SET_INVOICES = 'set_invoices'
export const SET_CREDIT_INFO = 'set_credit_info'
export const SET_INVOICE_TOTAL = 'set_invoice_total'
export const RESET_PAYMENT_PAGE = 'reset_payment_page'
export const RESET_PAYMENT_PROPS = 'reset_payment_props'
export const SET_PAYMENT_CREDIT_CARD = 'set_credit_card'
export const SET_CREDIT_TOTALS = 'set_credit_totals'
export const SET_CREDIT_MEMO = 'set_credit_memo'
export const SET_UNAPPLIED_PAYMENTS = 'set_unapplied_payments'
export const SET_CVC_CODE = 'set_cvc_code'
export const SET_PAYMENT_SUBMISSION_DETAILS = 'set_payment_submission_details'

export const SET_SALES_ORDERS_ADDRESS_LIST = 'set_sales_orders_address_list'
export const SET_INVOICE = 'set_invoice'
export const SET_SHIPMENT = 'set_shipment'
export const SET_REORDER_DATE_OPTION = 'set_reorder_date_option'
export const SET_REORDER_FROM_OPTION = 'set_reorder_from_option'
export const CLEAR_ALL = 'clear_all'

////TRANSACTION INVOICE HISTORY
export const SET_INVOICE_PROPS = 'set_invoice_props'
export const SET_INVOICE_FILTERS = 'set_invoice_filters'
export const SET_SELECTED_HISTORY_INVOICE = 'set_selected_history_invoice'

////TRANSACTION PAYMENT HISTORY
export const SET_PAYMENT_HISTORY_PROPS = 'set_payment_history_props'
export const SET_SELECTED_HISTORY_PAYMENT = 'set_selected_history_payment'
export const SET_PAYMENT_FILTERS = 'set_payment_filters'

////ORDER APPROVAL
export const SET_PENDING_APPROVAL_ORDERS = 'set_pending_approval_orders'
export const SET_REJECTED_ORDERS = 'set_rejected_orders'
export const REMOVE_APPROVAL_ORDER = 'remove_approval_order'
export const ADD_TO_REJECTED_ORDERS = 'add_to_rejected_orders'
export const SET_SELECTED_APPROVAL_ORDER_DETAILS = 'set_selected_approval_order_details'
export const CHANGE_SELECTED_APPROVAL_ORDER_FIELD = 'change_selected_approval_order_field'
export const SET_SELECTED_CREDIT_CARD = 'set_selected_credit_card'
export const SET_REJECT_ORDER_MESSAGE = 'set_reject_order_message'
export const RESET_ORDER_APPROVAL_FORM = 'reset_order_approval_form'

////ACCOUNT SETTINGS
export const SET_OPTIONS = 'set_options'
export const SET_ALL_SETTINGS_OPTIONS = 'set_all_settings_options'
export const SET_INITIAL_VALUES = 'set_initial_values'
export const SET_RELATED_ACCOUNTS = 'set_related_accounts'
export const SET_SELECTED_RELATED_ACCOUNT = 'set_selected_related_account'
