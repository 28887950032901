import qs from 'qs'
import { dec } from 'ramda'
import { resolveReq, axios } from '../utils'
import {
  START_LOADER,
  STOP_LOADER,
  TOGGLE_ITEM_LIST_MODAL,
  TOGGLE_ITEM_LIST_DETAIL_MODAL,
  GET_ITEM_LISTS,
  GET_LIST_DETAILS,
  GET_LIST_DETAIL,
  SET_SELECTED_ITEM_LIST, //Item list selected for editing
  SET_OPENED_ITEM_LIST,
  SHOW_ITEM_LIST_DETAILS,
  CHANGE_ITEM_LIST_DETAIL_PROP,
  DELETE_ITEM_LIST_DETAIL,
  TOGGLE_SHOW_SHARED_ITEM_LISTS,
  SET_ITEM_LIST_ADD_TO_LIST_CHECKBOX,
  CHANGE_ITEM_LIST_PROPS,
  DEC_INC_ITEM_LIST_COUNT,
} from './types'

export const toggleItemListModal = modalType => {
  return { type: TOGGLE_ITEM_LIST_MODAL, payload: modalType }
}

export const toggleItemListDetailModal = modalType => {
  return { type: TOGGLE_ITEM_LIST_DETAIL_MODAL, payload: modalType }
}

export const addNewItemList = itemList => {
  return async dispatch => {
    dispatch({ type: START_LOADER, payload: 'itemListUpdating' })
    const { error } = await resolveReq(axios.post('/api/cart/itemList/createList', itemList))
    if (error) console.log(error)

    dispatch({ type: STOP_LOADER, payload: 'itemListUpdating' })
  }
}

export const deleteItemList = listId => {
  return async dispatch => {
    dispatch({ type: START_LOADER, payload: 'itemListUpdating' })
    const { error } = await resolveReq(axios.post('/api/cart/itemList/deleteList', { id: listId }))
    if (error) console.log(error)
    dispatch({ type: STOP_LOADER, payload: 'itemListUpdating' })
  }
}

export const editItemList = itemList => {
  return async dispatch => {
    dispatch({ type: START_LOADER, payload: 'itemListUpdating' })
    const { error } = await resolveReq(axios.post('/api/cart/itemList/editList', itemList))

    if (error) console.log(error)
    dispatch({ type: STOP_LOADER, payload: 'itemListUpdating' })
  }
}

export const upsertItemListDetail = ild => {
  return async dispatch => {
    dispatch(changeItemListDetailProps({ id: ild.id, data: { isUpdating: true } }))
    const { error } = await resolveReq(axios.post('/api/cart/itemList/upsertListDetail', ild))
    if (error) {
      console.log(error)
      return dispatch(
        changeItemListDetailProps({
          id: ild.id,
          data: { isUpdating: false, quantity: ild.origQuantity, unit: ild.origUnit },
        }),
      )
    }
    dispatch(
      changeItemListDetailProps({
        id: ild.id,
        data: {
          isChanged: false,
          isUpdating: false,
          origQuantity: ild.quantity,
          origUnit: ild.unit,
        },
      }),
    )
  }
}

export const deleteItemListDetail = ({ id, itemListId }) => {
  return async dispatch => {
    dispatch(changeItemListDetailProps({ id, data: { isUpdating: true } }))
    const { error, data } = await resolveReq(
      axios.post('/api/cart/itemList/deleteListDetail', { id }),
    )
    if (error || !data.updated) {
      console.log(error)
      return dispatch(changeItemListDetailProps({ id, data: { isUpdating: false } }))
    }
    dispatch({ type: DELETE_ITEM_LIST_DETAIL, payload: id })
    dispatch({ type: DEC_INC_ITEM_LIST_COUNT, payload: { func: dec, itemListId } })
  }
}

//used for itemlist dropdown component
export const deleteItemListDetailByItemListId = ({ itemListId, itemId, unit }) => {
  return async dispatch => {
    dispatch({
      type: SET_ITEM_LIST_ADD_TO_LIST_CHECKBOX,
      payload: {
        itemListIds: [itemListId],
        data: { [itemId]: 'loading' },
      },
    })
    const { error, data } = await resolveReq(
      axios.post('/api/cart/itemList/deleteListDetailByItemListId', { itemListId, itemId, unit }),
    )

    if (error || !data.updated) {
      console.log(error)
      return dispatch({
        type: SET_ITEM_LIST_ADD_TO_LIST_CHECKBOX,
        payload: {
          itemListIds: [itemListId],
          data: { [itemId]: true },
        },
      })
    }
    dispatch({
      type: SET_ITEM_LIST_ADD_TO_LIST_CHECKBOX,
      payload: {
        itemListIds: [itemListId],
        data: { [itemId]: null },
      },
    })
  }
}

export const getItemLists = showShared => {
  return async dispatch => {
    const query = qs.stringify({ showShared: showShared || '' })
    const { data, error } = await resolveReq(axios.get('/api/cart/itemList/getAllLists?' + query))
    if (error) return console.log(error)
    dispatch({ type: GET_ITEM_LISTS, payload: data })
  }
}

export const getListDetails = id => {
  return async dispatch => {
    dispatch({ type: START_LOADER, payload: 'fetchingItemListDetails' })
    const { data, error } = await resolveReq(
      axios.get('/api/cart/itemList/getListDetails?itemListId=' + id),
    )
    if (error) return console.log(error)

    dispatch({
      type: GET_LIST_DETAILS,
      payload: data.map(m => ({ ...m, origQuantity: m.quantity, origUnit: m.unit })),
    })
    dispatch({ type: STOP_LOADER, payload: 'fetchingItemListDetails' })
    return data
  }
}

export const getItemListsByItemId = ({ itemId, unit }) => {
  return async dispatch => {
    const query = qs.stringify({ itemId, unit })
    dispatch({ type: START_LOADER, payload: 'addToItemListButtonLoading' })
    const { data, error } = await resolveReq(
      axios.get(`/api/cart/itemList/getListsByItemId?${query}`),
    )
    if (error) return console.log(error)

    dispatch({
      type: SET_ITEM_LIST_ADD_TO_LIST_CHECKBOX,
      payload: { itemId, itemListIds: data, data: { [itemId]: true } },
    })
    dispatch({ type: STOP_LOADER, payload: 'addToItemListButtonLoading' })
  }
}

export const addItemListDetail = ({ itemListId, itemId, unit, quantity }) => {
  return async dispatch => {
    dispatch({
      type: SET_ITEM_LIST_ADD_TO_LIST_CHECKBOX,
      payload: {
        itemListIds: [itemListId],
        data: { [itemId]: 'loading' },
      },
    })
    const { error } = await resolveReq(
      axios.post('/api/cart/itemList/upsertListDetail', {
        quantity: quantity || 1,
        itemListId,
        itemId,
        unit,
      }),
    )

    if (error) {
      dispatch({
        type: SET_ITEM_LIST_ADD_TO_LIST_CHECKBOX,
        payload: {
          itemListIds: [itemListId],
          data: { [itemId]: null },
        },
      })
      return console.log(error)
    }

    dispatch({
      type: SET_ITEM_LIST_ADD_TO_LIST_CHECKBOX,
      payload: {
        itemListIds: [itemListId],
        data: { [itemId]: true },
      },
    })
  }
}

export const setItemList = itemList => ({ type: SET_SELECTED_ITEM_LIST, payload: itemList })

export const getListDetail = item => ({ type: GET_LIST_DETAIL, payload: { ...item } })

export const setOpenedItemList = itemList => ({ type: SET_OPENED_ITEM_LIST, payload: itemList })

export const showItemListDetails = show => ({
  type: SHOW_ITEM_LIST_DETAILS,
  payload: Boolean(show),
})

export const changeItemListDetailProps = ({ id, data }) => ({
  type: CHANGE_ITEM_LIST_DETAIL_PROP,
  payload: { id, data },
})

export const changeItemListProps = ({ id, data }) => ({
  type: CHANGE_ITEM_LIST_PROPS,
  payload: { id, data },
})

export const toggleShowSharedItemLists = show => {
  return async dispatch => {
    dispatch({ type: TOGGLE_SHOW_SHARED_ITEM_LISTS, payload: Boolean(show) })
    await getItemLists(show)(dispatch)
  }
}
