import React, { useState, useEffect, useRef } from 'react'
import classnames from 'classnames'
import SumIconSVG from '../../shared/icons/SumIconSVG'
import RestIconSVG from '../../shared/icons/RestIconSVG'
import { Statsig } from 'statsig-react'
import Collapsible from 'react-collapsible'

const mql = window.matchMedia('(min-width: 62em)')

const MainNavigationTitleMobile = ({
  menuId,
  categoryPath,
  isExpanded,
  isCurrentlyActive,
  text,
  depth,
  parentMenu,
  activeMenus,
  dispatch,
  children,
  currentlyActiveMenu,
  opensOnHover,
  onCategoryClick,
  openMenuId,
  setOpenMenuId,
}) => {
  const mainNavigationMenuRef = useRef(null)
  const isOpen = openMenuId === menuId
  const [isVisible, setIsVisible] = useState(isOpen)
  let mainNavigationMenuClasses

  if (depth === 0) {
    mainNavigationMenuClasses = classnames('main-navigation-title-mobile__menu', {
      'main-navigation-title-mobile__menu--expanded': isOpen,
      'main-navigation-title-mobile__menu--visible': isVisible,
      'main-navigation-title-mobile__menu--overflow': isCurrentlyActive,
    })
  } else {
    mainNavigationMenuClasses = classnames('main-navigation-title-mobile__submenu', {
      'main-navigation-title-mobile__submenu--expanded': isOpen,
      'main-navigation-title-mobile__submenu--visible': isVisible,
      'main-navigation-title-mobile__submenu--overflow': isCurrentlyActive,
    })
  }

  const handleBlur = e => {
    if (e.relatedTarget === null) {
      return
    }

    if (!e.currentTarget.contains(e.relatedTarget)) {
      dispatch({
        type: 'UPDATE_ACTIVE_MENUS',
        payload: { menuId: menuId, depth: depth, menuElement: mainNavigationMenuRef.current },
      })
    }
  }

  const handleTransitionEnd = e => {
    if (e.currentTarget === e.target && e.propertyName === 'transform') {
      if (!isOpen) {
        setIsVisible(false)
      }
    }
  }

  const logStatsigEvent = linkTitle => {
    Statsig.logEvent('Nav_Button_Click', linkTitle)
  }

  useEffect(() => {
    if (mql.matches) {
      setIsVisible(isOpen)
      return
    }

    if (isOpen) {
      setIsVisible(true)
    }
  }, [isOpen])

  return (
    <Collapsible
      trigger={
        <button
          type="button"
          className={`main-navigation-title-mobile__btn`}
          onMouseEnter={() => {
            if (opensOnHover && !isCurrentlyActive) {
              dispatch({
                type: 'UPDATE_ACTIVE_MENUS',
                payload: { menuId, depth, menuElement: mainNavigationMenuRef.current },
              })
              for (const menu of activeMenus) {
                menu.menuElement.scrollTo(0, 0)
              }
            }
          }}
          onClick={() => {
            setOpenMenuId(isOpen ? null : menuId)
            if (opensOnHover && onCategoryClick) {
              onCategoryClick(categoryPath || menuId)
              logStatsigEvent(text)
            } else {
              dispatch({
                type: 'UPDATE_ACTIVE_MENUS',
                payload: {
                  menuId: menuId,
                  depth: depth,
                  menuElement: mainNavigationMenuRef.current,
                },
              })
              for (const menu of activeMenus) {
                menu.menuElement.scrollTo(0, 0)
              }
              logStatsigEvent(text)
            }
          }}
        >
          <span className={`main-navigation-title-mobile__btn-text`}>{text}</span>
          <span className="main-navigation-title-mobile__icon-wrapper">
            {isOpen ? (
              <RestIconSVG className="main-navigation-title-mobile__btn-icon" />
            ) : (
              <SumIconSVG className="main-navigation-title-mobile__btn-icon" />
            )}
          </span>
        </button>
      }
      open={isOpen}
      onOpening={() => setIsVisible(true)}
      onClosing={() => setIsVisible(false)}
    >
      <div
        ref={mainNavigationMenuRef}
        className={mainNavigationMenuClasses}
        onBlur={handleBlur}
        onTransitionEnd={handleTransitionEnd}
      >
        <ul className="mobile-dealmed-main-navigation__list">{children}</ul>
      </div>
    </Collapsible>
  )
}

export default MainNavigationTitleMobile
