import ax from 'axios'
import { mergeRight, map, toPairs, join } from 'ramda'
import moment from 'moment'
import { jwtDecode } from 'jwt-decode'

export const makeQueryStr = params => {
  const paramStrings = map(pair => {
    const [key, val] = pair
    return `${key}=${val}`
  }, toPairs(params))
  return join('&', paramStrings)
}

export const axios = ax.create({
  baseURL: process.env.REACT_APP_API_URL,
  headers: {
    Authorization: 'Bearer ' + localStorage.getItem('token'),
    'X-Robots-Tag': 'noindex',
    Pragma: 'no-cache',
  },
})

axios.interceptors.request.use(
  config => {
    const token = localStorage.getItem('token')
    if (token) {
      config.headers['Authorization'] = `Bearer ${token}`
    }
    return config
  },
  error => Promise.reject(error),
)

export const refreshTokenMiddleware = axios => {
  axios.interceptors.response.use(
    response => response,
    async error => {
      const originalRequest = error.config

      if (error.response?.status === 401 && !originalRequest._retry) {
        originalRequest._retry = true // Prevent infinite loops

        try {
          const { data } = await ax.get('/api/auth/refreshToken', {
            headers: { Authorization: `Bearer ${localStorage.getItem('token')}` },
          })

          console.log('Refreshed token:', data.token)
          localStorage.setItem('token', data.token)

          // Retry the original request with the new token
          originalRequest.headers['Authorization'] = `Bearer ${data.token}`
          return axios(originalRequest)
        } catch (refreshError) {
          console.error('Token refresh failed', refreshError)
          localStorage.removeItem('token')
          window.location.href = '/login'
          return Promise.reject(refreshError)
        }
      }

      return Promise.reject(error)
    },
  )
}

export const scheduleExpiredTokenCheck = () => {
  try {
    const { exp } = getTokenDecoded()

    const checkExpiration = time => {
      setTimeout(() => {
        if (exp < moment().unix()) {
          localStorage.removeItem('token')
          window.location.reload()
        }
        checkExpiration(time)
      }, time) //300000)
    }

    if (exp) checkExpiration(600000)
  } catch (e) {
    console.log(e)
  }
}

export const getTokenDecoded = () => {
  try {
    const token = localStorage.getItem('token')
    if (!token) return null
    return jwtDecode(token)
  } catch (e) {
    console.error('Invalid token:', e)
    return null
  }
}

// eslint-disable-next-line import/prefer-default-export
export const resolveReq = async (promise, noRedirect) => {
  try {
    const { data } = await promise
    return { data }
  } catch (error) {
    const { data, statusText, status } = error.response
    if (status === 401 && !noRedirect) window.location.href = '/login'
    const message = data.message || data
    return { error: mergeRight(error, { message: message }) }
  }
}

export const getAPIStatus = async () => {
  try {
    await ax.get('/')
    return true
  } catch (error) {
    console.log('API IS DOWN', 'RUNNNN forest RUNNNNNNNNNNNNNNNNNNNNNNNN')
    console.log(error)
    return false
  }
}

export const addChatWidget = () => {
  const script = document.createElement('script')

  script.src = '//code.tidio.co/1xirn8bxnzbgyzcwsydinzhodf3xzxjt.js'
  script.id = 'chatwidget'

  document.body.appendChild(script)
}

export const addInspectlet = () => {
  const inner = `(function () {
                    window.__insp = window.__insp || [];
                    __insp.push(['wid', 1795631574]);
                    var ldinsp = function () {
                        if (typeof window.__inspld != "undefined") return; window.__inspld = 1; var insp = document.createElement('script'); insp.type = 'text/javascript'; insp.async = true; insp.id = "inspsync"; insp.src = ('https:' == document.location.protocol ? 'https' : 'http') + '://cdn.inspectlet.com/inspectlet.js?wid=1795631574&r=' + Math.floor(new Date().getTime() / 3600000); var x = document.getElementsByTagName('script')[0]; x.parentNode.insertBefore(insp, x);
                    };
                    setTimeout(ldinsp, 0);
                })();`

  const script = document.createElement('script')

  script.type = 'text/javascript'
  script.id = 'inspectlet'
  script.innerHTML = inner

  document.body.appendChild(script)
}
