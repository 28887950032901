import React from 'react'
import ProductDetailsAlternativeProductsSlider from './ProductDetailsAlternativeProductsSlider'

const ProductDetailsAlternativeProducts = React.memo(({ productDetailsProps }) => {
  return (
    <div className="product-details__alternative-products">
      <h2 className="product-details__tab-title" id="alternative-products">
        Alternative Products
      </h2>

      <ProductDetailsAlternativeProductsSlider
        productDetailsProps={productDetailsProps}
        sliderInstanceId="alternative-products-slider"
      />

      <div className="product-details__alternative-products-controls">
        <button className="product-details__alternative-products-control product-details__alternative-products-control--prev">
          <span className="visually-hidden">Previous Slide</span>
        </button>
        <button className="product-details__alternative-products-control product-details__alternative-products-control--next">
          <span className="visually-hidden">Next Slide</span>
        </button>
      </div>
    </div>
  )
})

export default ProductDetailsAlternativeProducts
